var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "page-card enrollmentEdit-card"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-tabs', {
    attrs: {
      "card": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Information Details",
      "active": ""
    }
  }, [_c('b-card-text', [_c('info-details')], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Contact Details"
    }
  }, [_c('b-card-text', [_c('contact-details')], 1)], 1), _vm._l(_vm.tabs, function (tab, index) {
    return _c('b-tab', {
      key: index,
      attrs: {
        "id": tab.tab_name,
        "title": _vm.getTabName(tab.tab_name)
      }
    }, [tab.tab_name === 'employer_info' ? _c('b-card-text', [_c('employer-info')], 1) : _vm._e(), tab.tab_name === 'beneficiary_info' ? _c('b-card-text', [_c('beneficiary-info')], 1) : _vm._e(), tab.tab_name === 'primary_health_info' ? _c('b-card-text', [_c('personal-questionaire')], 1) : _vm._e(), tab.tab_name === 'dependent_info' ? _c('b-card-text', [_c('dependent-info')], 1) : _vm._e()], 1);
  }), _c('b-tab', {
    attrs: {
      "title": "Billing Information"
    }
  }, [_c('b-card-text', [_c('billing-info')], 1)], 1)], 2)], 1)], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Edit Enrollments")])])]);
}]

export { render, staticRenderFns }