<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="page-title">
                        <h1>Edit Enrollments</h1>
                    </div>
                </div>
                <div class="col-12">
                    <div class="page-card enrollmentEdit-card">
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="Information Details" active>
                                    <b-card-text>
                                        <info-details />
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Contact Details">
                                    <b-card-text>
                                        <contact-details />
                                    </b-card-text>
                                </b-tab>
                                <b-tab v-for="(tab, index) in tabs" :id="tab.tab_name" :key="index" :title="getTabName(tab.tab_name)">
                                    <b-card-text v-if="tab.tab_name === 'employer_info'">
                                        <employer-info />
                                    </b-card-text>
                                    <b-card-text v-if="tab.tab_name === 'beneficiary_info'">
                                        <beneficiary-info />
                                    </b-card-text>
                                    <b-card-text v-if="tab.tab_name === 'primary_health_info'">
                                        <personal-questionaire />
                                    </b-card-text>
                                    <b-card-text v-if="tab.tab_name === 'dependent_info'">
                                        <dependent-info />
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Billing Information">
                                    <b-card-text>
                                        <billing-info />
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InfoDetails from '../../components/EnrollmentEdit/InfoDetails.vue';
import ContactDetails from '../../components/EnrollmentEdit/ContactInfo.vue';
import EmployerInfo from '../../components/EnrollmentEdit/EmployerInfo.vue';
import BeneficiaryInfo from '../../components/EnrollmentEdit/BeneficiaryInfo.vue';
import DependentInfo from '../../components/EnrollmentEdit/DependentInfo.vue';
import PersonalQuestionaire from '../../components/EnrollmentEdit/PersonalQuestionaire.vue';
import BillingInfo from '../../components/EnrollmentEdit/BillingInfo.vue';
import Api from '../../resource/Api';
export default {
    name:'EnrollmentsEdit',
    components:{
        InfoDetails,
        ContactDetails,
        EmployerInfo,
        BeneficiaryInfo,
        DependentInfo,
        PersonalQuestionaire,
        BillingInfo,
    },
    data: () => ({
        tabs: []
    }),
    methods: {
        async getTabs(){
            let url ="v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/tabs";
            Api.get(url)
                .then((res) => {
                    this.tabs = res.data.data;
                })
                .catch((err) => {
                    Api.notification(err.status, err.message)
                })
        },
        getTabName(tab){
            return tab.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        }
    },
    mounted(){
        this.getTabs();
    }
}
</script>
<style src="@/assets/css/pending-details.css"></style>