<template>
    <div class="info-wrap">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(updateEmployerInfo)">
                <h2>Employer Information</h2>
                <p>Enter and Update all your Employer Information here</p>
                <div class="info-form mb-4">            
                    <div class="form-group row">  
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.name">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['name'])" :custom-messages="getErrorMessages('name')">
                                <label for="name">
                                    Employer Information
                                    <span v-if="formFields['name']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['name']"  id="name">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.phone">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['phone'])" :custom-messages="getErrorMessages('phone')">
                                <label for="phone">
                                    Contact Number
                                    <span v-if="formFields['phone']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['phone']"  id="phone">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.address1">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['address1'])" :custom-messages="getErrorMessages('address1')">
                                <label for="address1">
                                    Street Address 1
                                    <span v-if="formFields['address1']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['address1']"  id="address1">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.address2">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['address2'])" :custom-messages="getErrorMessages('address2')">
                                <label for="address2">
                                    Street Address 2
                                    <span v-if="formFields['address2']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['address2']"  id="address2">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.city">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['city'])" :custom-messages="getErrorMessages('city')">
                                <label for="city">
                                    City-Country
                                    <span v-if="formFields['city']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['city']"  id="city">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.state">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['state'])" :custom-messages="getErrorMessages('state')">
                                <label for="state">
                                    State
                                    <span v-if="formFields['state']['required']" class="required">*</span>
                                </label>
                                <select class="info-field" id="state" v-model="postData['state']">
                                    <option v-for="(option, index1) in formFields['state']['options']" :key="index1" :id="option.index1" :value="option.value">
                                        {{ option.name }}
                                    </option>
                                </select>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.zip">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['zip'])" :custom-messages="getErrorMessages('zip')">
                                <label for="zip">
                                    Zip/Pin Code
                                    <span v-if="formFields['zip']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['zip']"  id="zip">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-check col-xxl-6 col-lg-6 col-md-6 mb-3" v-if="formFields.with_validation">
                            <input class="form-check-input" type="checkbox" v-model="postData['with_validation']"  id="with_validation">
                            <label class="form-check-label" for="flexCheckDefault">
                                USPS Address Validation
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.average_work_week">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['average_work_week'])" :custom-messages="getErrorMessages('average_work_week')">
                                <label for="average_work_week">
                                    Average Work Week
                                    <span v-if="formFields['average_work_week']['required']" class="required">*</span>
                                </label>
                                <select class="info-field" id="average_work_week" v-model="postData['average_work_week']">
                                    <option v-for="(option, index1) in formFields['average_work_week']['options']" :key="index1" :id="option.index1" :value="option.value">
                                        {{ option.name }}
                                    </option>
                                </select>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.start_date">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['start_date'])" :custom-messages="getErrorMessages('start_date')">
                                <label for="start_date">
                                    Employement Start Date
                                    <span v-if="formFields['start_date']['required']" class="required">*</span>
                                </label>
                                 <date-picker input-class="info-field" 
                                placeholder="" 
                                type="date"  
                                id="start_date"
                                v-model="postData['start_date']"
                                value-type="format"
                                format="MM/DD/YYYY"></date-picker>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.occupation">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['occupation'])" :custom-messages="getErrorMessages('occupation')">
                                <label for="occupation">
                                    Occupation
                                    <span v-if="formFields['occupation']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['occupation']"  id="occupation">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.compensation_type">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['compensation_type'])" :custom-messages="getErrorMessages('compensation_type')">
                                <label for="compensation_type">
                                    Compensation type
                                    <span v-if="formFields['compensation_type']['required']" class="required">*</span>
                                </label>
                                <select class="info-field" id="compensation_type" v-model="postData['compensation_type']">
                                    <option v-for="(option, index1) in formFields['compensation_type']['options']" :key="index1" :id="option.index1" :value="option.value">
                                        {{ option.name }}
                                    </option>
                                </select>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.status">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['status'])" :custom-messages="getErrorMessages('status')">
                                <label for="status">
                                    Employement Status
                                    <span v-if="formFields['status']['required']" class="required">*</span>
                                </label>
                                <select class="info-field" id="status" v-model="postData['status']">
                                    <option v-for="(option, index1) in formFields['status']['options']" :key="index1" :id="option.index1" :value="option.value" :disabled="getDisabled(option.value)">
                                        {{ option.name }}
                                    </option>
                                </select>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.emp_industry">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['emp_industry'])" :custom-messages="getErrorMessages('emp_industry')">
                                <label for="emp_industry">
                                    Employement industry
                                    <span v-if="formFields['emp_industry']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['emp_industry']"  id="emp_industry">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.emp_num_employed">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['emp_num_employed'])" :custom-messages="getErrorMessages('emp_num_employed')">
                                <label for="emp_num_employed">
                                    Employement Num Employed
                                    <span v-if="formFields['emp_num_employed']['required']" class="required">*</span>
                                </label>
                                <input class="info-field" v-model="postData['emp_num_employed']"  id="emp_num_employed">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.emp_vac_policy">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['emp_vac_policy'])" :custom-messages="getErrorMessages('emp_vac_policy')">
                                <label for="emp_vac_policy">
                                    Employement Vac Policy
                                    <span v-if="formFields['emp_vac_policy']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['emp_vac_policy']"  id="emp_vac_policy">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.emp_barg_unit">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['emp_barg_unit'])" :custom-messages="getErrorMessages('emp_barg_unit')">
                                <label for="emp_barg_unit">
                                    Compensation type
                                    <span v-if="formFields['emp_barg_unit']['required']" class="required">*</span>
                                </label>
                                <input class="info-field" v-model="postData['emp_barg_unit']"  id="emp_barg_unit">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row justify-content-end mt-3">
                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                            <button type="submit" class="univ-btn" style=" max-width: 100% !important;">{{ isProcessing ? 'Processing' : 'Update' }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
import Api from '../../resource/Api';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import moment from "moment";
/*eslint-disable*/

export default {
    name: 'EmployerInfo',
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    data: () => ({
        version: process.env.VUE_APP_API_VERSION,
        isProcessing: false,
        postData: [],
        formFields: []
    }),
    mounted(){
        this.getEmployerInfo();
    },
    methods: {
        getLabelName(fieldName){
            if(fieldName === 'weight')
                return fieldName.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + ' (lbs)'; 
            return fieldName.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        },
        getRules(item){
            if(item == null){
                return {};
            }
            if(item.field_name === 'email_address'){
                return {
                    required: item.required,
                    email: true
                }
            }
            if(item.field_name === 'phone'){
                return {
                    required: item.required,
                    digits: 10
                }
            }
            if(item.field_name === 'zip'){
                return {
                    required: item.required,
                    digits: 5
                }
            }
            return {required: item.required}
        },
        getErrorMessages(field_name){
            if(field_name === 'zip'){
                return {
                    'required': this.getLabelName(field_name) + " is required",
                    'digits': this.getLabelName(field_name) + " must be 5 digits"    
                }
            }
            if(field_name === 'phone'){
                return {
                    'required': "Contact number is required",
                    'alpha': "Contact number may only contain alphabetic characters",
                    'email': "Contact number should be a valid email",
                    'digits': "Contact number must be 10 digits"
                }    
            }
            if(field_name === 'name'){
                return {
                    'required': "Employer information is required",
                }
            }
            if(field_name === 'start_date'){
                return {
                    'required': "Employement start date is required",
                }
            }
            return {
                'required': this.getLabelName(field_name) + " is required",
                'alpha': this.getLabelName(field_name) + " may only contain alphabetic characters",
                'email': this.getLabelName(field_name) + " should be a valid email",
                'digits': this.getLabelName(field_name) + " must be 10 digits"
            }
        },

        async getEmployerInfo(){
            let url = "v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/employer-info";
            Api.get(url)
                .then((res) => {
                    let employerInfo = res.data.data;
                    employerInfo.map(info => {
                        this.postData = {...this.postData, [info.field_name]: info.value}
                        this.formFields = {...this.formFields, [info.field_name]: info}
                    })
                    this.postData['with_validation'] = 1
                    this.postData['start_date'] = moment(this.postData['start_date']).format('MM/DD/YYYY');
                })
                .catch((err) => {
                    Api.notification(err.response.data.success, err.response.data.message)
                })
        },
        async updateEmployerInfo(){
            this.isProcessing = true;
            this.postData.with_validation = this.postData.with_validation ? 1 : 0
             Api.patch(`${this.$endpoint.REP_24HRS_EDIT}/${atob(this.$route.params.enrollmentId)}/employer-info`, this.postData)
                .then((response) => {
                    Api.notification(response.data.status, response.data.message);
                })
                .catch((err) => {
                    Api.notification(err.response.data.status, err.response.data.message);
                    Api.notification(err.response.data.success, err.response.data.message);
                })
                .finally(()=>{
                    this.isProcessing = false;
                })
        },
        getDisabled(value){
            return value !== 'A'
        },
    },
}
</script>