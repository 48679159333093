var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrap"
  }, [_c('h2', [_vm._v("Personal Health Questionaire")]), _c('p', [_vm._v("Enter and Update all your Personal Health Questionaire here")]), _c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              handleSubmit(_vm.updateHealthInfo());
            }
          }
        }, [_c('div', {
          staticClass: "info-form"
        }, [_vm._l(_vm.healthInfo.condition_questions, function (question, index1) {
          return _c('div', {
            key: index1 + 'B',
            staticClass: "mx-2"
          }, [_c('div', {
            staticClass: "split-list"
          }, [_c('p', [_vm._v(_vm._s(question.question_text))]), _c('div', {
            staticClass: "split-yn-div"
          }, [_c('div', {
            staticClass: "form-check form-check-inline"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.healthInfo.condition_questions[index1].selected_answer,
              expression: "healthInfo.condition_questions[index1].selected_answer"
            }],
            staticClass: "form-check-input",
            attrs: {
              "type": "radio",
              "name": 'condition-question' + question.question_id,
              "id": index1,
              "value": "1"
            },
            domProps: {
              "checked": _vm._q(_vm.healthInfo.condition_questions[index1].selected_answer, "1")
            },
            on: {
              "click": function click($event) {
                return _vm.addCondition(question);
              },
              "change": function change($event) {
                return _vm.$set(_vm.healthInfo.condition_questions[index1], "selected_answer", "1");
              }
            }
          }), _c('label', {
            staticClass: "form-check-label",
            attrs: {
              "for": "inlineRadio1"
            }
          }, [_vm._v("Yes")])]), _c('div', {
            staticClass: "form-check form-check-inline"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.healthInfo.condition_questions[index1].selected_answer,
              expression: "healthInfo.condition_questions[index1].selected_answer"
            }],
            staticClass: "form-check-input",
            attrs: {
              "type": "radio",
              "name": 'condition-question' + question.question_id,
              "id": index1,
              "value": "0"
            },
            domProps: {
              "checked": _vm._q(_vm.healthInfo.condition_questions[index1].selected_answer, "0")
            },
            on: {
              "click": function click($event) {
                return _vm.removeAllConditions(question);
              },
              "change": function change($event) {
                return _vm.$set(_vm.healthInfo.condition_questions[index1], "selected_answer", "0");
              }
            }
          }), _c('label', {
            staticClass: "form-check-label",
            attrs: {
              "for": "inlineRadio2"
            }
          }, [_vm._v("No")])])])]), question.selected_answer == 1 ? _c('div', [_c('h6', [_vm._v("Conditions")]), _vm._l(question.conditions, function (condition, index2) {
            return _c('div', {
              key: index2 + 'DASd',
              staticClass: "row"
            }, [_c('div', {
              staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-12 mb-3"
            }, [_c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('health_condition')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref2) {
                  var errors = _ref2.errors;
                  return [_c('label', {
                    attrs: {
                      "for": "health_condition"
                    }
                  }, [_vm._v(" Health Condition "), _c('span', {
                    staticClass: "required"
                  }, [_vm._v("*")])]), _c('input', {
                    directives: [{
                      name: "model",
                      rawName: "v-model",
                      value: _vm.healthInfo.condition_questions[index1].conditions[index2].health_condition,
                      expression: "healthInfo.condition_questions[index1].conditions[index2].health_condition"
                    }],
                    staticClass: "info-field",
                    attrs: {
                      "id": "health_condition"
                    },
                    domProps: {
                      "value": _vm.healthInfo.condition_questions[index1].conditions[index2].health_condition
                    },
                    on: {
                      "input": function input($event) {
                        if ($event.target.composing) { return; }

                        _vm.$set(_vm.healthInfo.condition_questions[index1].conditions[index2], "health_condition", $event.target.value);
                      }
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1), _c('div', {
              staticClass: "col-xxl-2 col-xl-2 col-lg-6 col-md-6 mb-3"
            }, [_c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('doctor_last_seen')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref3) {
                  var errors = _ref3.errors;
                  return [_c('label', {
                    attrs: {
                      "for": "d_last_seen"
                    }
                  }, [_vm._v(" Last seen by Doctor "), _c('span', {
                    staticClass: "required"
                  }, [_vm._v("*")])]), _c('date-picker', {
                    attrs: {
                      "input-class": "info-field",
                      "placeholder": "",
                      "type": "date",
                      "id": "d_last_seen",
                      "value-type": "format",
                      "format": "MM/DD/YYYY"
                    },
                    model: {
                      value: _vm.healthInfo.condition_questions[index1].conditions[index2].d_last_seen,
                      callback: function callback($$v) {
                        _vm.$set(_vm.healthInfo.condition_questions[index1].conditions[index2], "d_last_seen", $$v);
                      },
                      expression: "healthInfo.condition_questions[index1].conditions[index2].d_last_seen"
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1), _c('div', {
              staticClass: "col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3"
            }, [_c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('date_of_onset')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref4) {
                  var errors = _ref4.errors;
                  return [_c('label', {
                    attrs: {
                      "for": "date_of_onset"
                    }
                  }, [_vm._v(" Date of Onset "), _c('span', {
                    staticClass: "required"
                  }, [_vm._v("*")])]), _c('date-picker', {
                    attrs: {
                      "input-class": "info-field",
                      "placeholder": "",
                      "type": "date",
                      "id": "date_of_onset",
                      "value-type": "format",
                      "format": "MM/DD/YYYY"
                    },
                    model: {
                      value: _vm.healthInfo.condition_questions[index1].conditions[index2].date_of_onset,
                      callback: function callback($$v) {
                        _vm.$set(_vm.healthInfo.condition_questions[index1].conditions[index2], "date_of_onset", $$v);
                      },
                      expression: "healthInfo.condition_questions[index1].conditions[index2].date_of_onset"
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1), _c('div', {
              staticClass: "col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3"
            }, [_c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('date_of_recovery')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref5) {
                  var errors = _ref5.errors;
                  return [_c('label', {
                    attrs: {
                      "for": "date_of_recovery"
                    }
                  }, [_vm._v(" Date of recovery "), _c('span', {
                    staticClass: "required"
                  }, [_vm._v("*")])]), _c('date-picker', {
                    attrs: {
                      "input-class": "info-field",
                      "placeholder": "",
                      "type": "date",
                      "id": "date_of_recovery",
                      "value-type": "format",
                      "format": "MM/DD/YYYY"
                    },
                    model: {
                      value: _vm.healthInfo.condition_questions[index1].conditions[index2].date_of_recovery,
                      callback: function callback($$v) {
                        _vm.$set(_vm.healthInfo.condition_questions[index1].conditions[index2], "date_of_recovery", $$v);
                      },
                      expression: "healthInfo.condition_questions[index1].conditions[index2].date_of_recovery"
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1), _c('div', {
              staticClass: "col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3"
            }, [_c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('symptoms')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref6) {
                  var errors = _ref6.errors;
                  return [_c('label', {
                    attrs: {
                      "for": "symptoms"
                    }
                  }, [_vm._v(" Symptoms "), _c('span', {
                    staticClass: "required"
                  }, [_vm._v("*")])]), _c('input', {
                    directives: [{
                      name: "model",
                      rawName: "v-model",
                      value: _vm.healthInfo.condition_questions[index1].conditions[index2].symptoms,
                      expression: "healthInfo.condition_questions[index1].conditions[index2].symptoms"
                    }],
                    staticClass: "info-field",
                    attrs: {
                      "id": "symptoms"
                    },
                    domProps: {
                      "value": _vm.healthInfo.condition_questions[index1].conditions[index2].symptoms
                    },
                    on: {
                      "input": function input($event) {
                        if ($event.target.composing) { return; }

                        _vm.$set(_vm.healthInfo.condition_questions[index1].conditions[index2], "symptoms", $event.target.value);
                      }
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1), _c('div', {
              staticClass: "form-check ques-check col-xxl-2 col-xl-2 col-lg-3 col-md-6 mb-3"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.healthInfo.condition_questions[index1].conditions[index2].is_medicate,
                expression: "healthInfo.condition_questions[index1].conditions[index2].is_medicate"
              }],
              staticClass: "form-check-input",
              attrs: {
                "type": "checkbox",
                "id": "is_medicate",
                "true-value": "1",
                "false-value": "0"
              },
              domProps: {
                "checked": Array.isArray(_vm.healthInfo.condition_questions[index1].conditions[index2].is_medicate) ? _vm._i(_vm.healthInfo.condition_questions[index1].conditions[index2].is_medicate, null) > -1 : _vm._q(_vm.healthInfo.condition_questions[index1].conditions[index2].is_medicate, "1")
              },
              on: {
                "change": function change($event) {
                  var $$a = _vm.healthInfo.condition_questions[index1].conditions[index2].is_medicate,
                      $$el = $event.target,
                      $$c = $$el.checked ? "1" : "0";

                  if (Array.isArray($$a)) {
                    var $$v = null,
                        $$i = _vm._i($$a, $$v);

                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.healthInfo.condition_questions[index1].conditions[index2], "is_medicate", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(_vm.healthInfo.condition_questions[index1].conditions[index2], "is_medicate", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(_vm.healthInfo.condition_questions[index1].conditions[index2], "is_medicate", $$c);
                  }
                }
              }
            }), _c('label', {
              staticClass: "form-check-label",
              attrs: {
                "for": "is_medicate"
              }
            }, [_vm._v(" Taking Medication ")])]), _c('div', {
              staticClass: "form-check ques-check col-xxl-2 col-xl-2 col-lg-3 col-md-6 mb-3"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.healthInfo.condition_questions[index1].conditions[index2].is_treatment,
                expression: "healthInfo.condition_questions[index1].conditions[index2].is_treatment"
              }],
              staticClass: "form-check-input",
              attrs: {
                "type": "checkbox",
                "id": "is_treatment",
                "true-value": "1",
                "false-value": "0"
              },
              domProps: {
                "checked": Array.isArray(_vm.healthInfo.condition_questions[index1].conditions[index2].is_treatment) ? _vm._i(_vm.healthInfo.condition_questions[index1].conditions[index2].is_treatment, null) > -1 : _vm._q(_vm.healthInfo.condition_questions[index1].conditions[index2].is_treatment, "1")
              },
              on: {
                "change": function change($event) {
                  var $$a = _vm.healthInfo.condition_questions[index1].conditions[index2].is_treatment,
                      $$el = $event.target,
                      $$c = $$el.checked ? "1" : "0";

                  if (Array.isArray($$a)) {
                    var $$v = null,
                        $$i = _vm._i($$a, $$v);

                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.healthInfo.condition_questions[index1].conditions[index2], "is_treatment", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(_vm.healthInfo.condition_questions[index1].conditions[index2], "is_treatment", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(_vm.healthInfo.condition_questions[index1].conditions[index2], "is_treatment", $$c);
                  }
                }
              }
            }), _c('label', {
              staticClass: "form-check-label",
              attrs: {
                "for": "is_treatment"
              }
            }, [_vm._v(" Current Treatment ")])])]);
          })], 2) : _vm._e()]);
        }), _vm.healthInfo.medication_question ? _c('div', {
          staticClass: "split-list"
        }, [_c('p', [_vm._v(_vm._s(_vm.healthInfo.medication_question.question_text))]), _c('div', {
          staticClass: "split-yn-div"
        }, [_c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.healthInfo.medication_question.selected_answer,
            expression: "healthInfo.medication_question.selected_answer"
          }],
          staticClass: "form-check-input",
          attrs: {
            "type": "radio",
            "name": 'medication-question' + _vm.healthInfo.medication_question.question_id,
            "id": 'medication-question' + _vm.healthInfo.medication_question.question_id,
            "value": "1"
          },
          domProps: {
            "checked": _vm._q(_vm.healthInfo.medication_question.selected_answer, "1")
          },
          on: {
            "click": function click($event) {
              return _vm.addMedications(_vm.healthInfo.medication_question);
            },
            "change": function change($event) {
              return _vm.$set(_vm.healthInfo.medication_question, "selected_answer", "1");
            }
          }
        }), _c('label', {
          staticClass: "form-check-label",
          attrs: {
            "for": "inlineRadio1"
          }
        }, [_vm._v("Yes")])]), _c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.healthInfo.medication_question.selected_answer,
            expression: "healthInfo.medication_question.selected_answer"
          }],
          staticClass: "form-check-input",
          attrs: {
            "type": "radio",
            "name": 'medication-question' + _vm.healthInfo.medication_question.question_id,
            "id": 'medication-question' + _vm.healthInfo.medication_question.question_id,
            "value": "0"
          },
          domProps: {
            "checked": _vm._q(_vm.healthInfo.medication_question.selected_answer, "0")
          },
          on: {
            "click": function click($event) {
              return _vm.removeAllMedications(_vm.healthInfo.medication_question);
            },
            "change": function change($event) {
              return _vm.$set(_vm.healthInfo.medication_question, "selected_answer", "0");
            }
          }
        }), _c('label', {
          staticClass: "form-check-label",
          attrs: {
            "for": "inlineRadio2"
          }
        }, [_vm._v("No")])])]), _vm.healthInfo.medication_question.selected_answer == 1 ? _c('div', [_c('h6', [_vm._v("Medications")]), _vm._l(_vm.healthInfo.medication_question.medications, function (medication, index4) {
          return _c('div', {
            key: index4 + 'sdcasc',
            staticClass: "row mb-3"
          }, [_c('div', {
            staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12"
          }, [_c('label', [_vm._v("Medication/Rx injection"), _c('span', {
            staticClass: "required"
          }, [_vm._v("*")])]), _c('ValidationProvider', {
            attrs: {
              "rules": "required",
              "custom-messages": _vm.getErrorMessages('medication')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref7) {
                var errors = _ref7.errors;
                return [_c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.healthInfo.medication_question.medications[index4].medication,
                    expression: "healthInfo.medication_question.medications[index4].medication"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": 'medication' + index4
                  },
                  domProps: {
                    "value": _vm.healthInfo.medication_question.medications[index4].medication
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.healthInfo.medication_question.medications[index4], "medication", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1), _c('div', {
            staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12"
          }, [_c('label', [_vm._v("Dosage"), _c('span', {
            staticClass: "required"
          }, [_vm._v("*")])]), _c('ValidationProvider', {
            attrs: {
              "rules": "required",
              "custom-messages": _vm.getErrorMessages('dosage')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref8) {
                var errors = _ref8.errors;
                return [_c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.healthInfo.medication_question.medications[index4].dosage,
                    expression: "healthInfo.medication_question.medications[index4].dosage"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": 'dosage' + index4
                  },
                  domProps: {
                    "value": _vm.healthInfo.medication_question.medications[index4].dosage
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.healthInfo.medication_question.medications[index4], "dosage", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1), _c('div', {
            staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12"
          }, [_c('label', [_vm._v("Medical Condition"), _c('span', {
            staticClass: "required"
          }, [_vm._v("*")])]), _c('ValidationProvider', {
            attrs: {
              "rules": "required",
              "custom-messages": _vm.getErrorMessages('medical_condition')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref9) {
                var errors = _ref9.errors;
                return [_c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.healthInfo.medication_question.medications[index4].medical_condition,
                    expression: "healthInfo.medication_question.medications[index4].medical_condition"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": 'medical_condition' + index4
                  },
                  domProps: {
                    "value": _vm.healthInfo.medication_question.medications[index4].medical_condition
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.healthInfo.medication_question.medications[index4], "medical_condition", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1), _c('div', {
            staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 align-center"
          }, [_vm.healthInfo.medication_question.medications.length > 1 ? _c('span', {
            staticClass: "remove-medication",
            on: {
              "click": function click($event) {
                return _vm.removeMedication(_vm.healthInfo.medication_question, index4);
              }
            }
          }, [_c('i', {
            staticClass: "far fa-times-circle"
          }), _vm._v(" Remove ")]) : _vm._e()])]);
        }), _vm.healthInfo.medication_question.medications.length < 3 ? _c('div', {
          staticClass: "row mt-2"
        }, [_c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('b-button', {
          staticClass: "univ-btn",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.addMedications(_vm.healthInfo.medication_question);
            }
          }
        }, [_vm._v("+ Add Medications")])], 1)]) : _vm._e()], 2) : _vm._e()]) : _vm._e(), _c('div', {
          staticClass: "form-group mx-2"
        }, [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Additional Notes")]), _c('textarea', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.healthInfo.notes,
            expression: "healthInfo.notes"
          }],
          staticClass: "info-field",
          attrs: {
            "name": "",
            "id": "",
            "rows": "4",
            "placeholder": "Enter additional notes"
          },
          domProps: {
            "value": _vm.healthInfo.notes
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(_vm.healthInfo, "notes", $event.target.value);
            }
          }
        }), _c('span', {
          staticClass: "note-text"
        }, [_vm._v("*Please note, any false information, or information not given may result in termination of eligibility for coverage under the Plan or denial of benefits under the Plan.*")])])], 2), _c('div', {
          staticClass: "row justify-content-end mt-3"
        }, [_c('div', {
          staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6"
        }, [_c('button', {
          staticClass: "univ-btn",
          staticStyle: {
            "max-width": "100% !important"
          },
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              handleSubmit(_vm.updateHealthInfo());
            }
          }
        }, [_vm._v(_vm._s(_vm.isProcessing ? 'Processing' : 'Update'))])])])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }