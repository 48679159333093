<template>
    <div class="info-wrap">
         
            
        <h2>Beneficiary Information</h2>
        <p>Enter and Update all your Beneficiary Information here</p>

        <div v-for="(ben, index) in postData" :key="index" :id="index">
               <h3>{{ ben.is_contigent ? 'Contingent Beneficiary Details' : 'Beneficiary Details' }}</h3>   
             
            <div class="row info-form mb-3">
                <b-input-group :id="'beneficiary' + index">
                    <b-input-group-append class="split-group">
                        <b-form-input class="info-field" :value="getFullName(ben)" disabled></b-form-input>
                        <b-button variant="primary edit" @click="editBeneficiary(ben, index)" v-b-modal="'edit-beneficiary' + index">Edit</b-button>
                        <b-button variant="danger delete" @click="deleteBeneficiary(index)">Delete</b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>

            <!-- edit beneficiary -->
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(saveBenefeciary)">
                    <b-modal :id="'edit-beneficiary' + index" :ref="'edit-beneficiary' + index" size="lg" :title="data.is_contigent ? 'Contingent Beneficiary' : 'Beneficiary'" @hidden="resetModal" centered >
                        <div class="info-form mb-4">
                            <div class="form-group row">
                                <div class="col-xxl-4 col-lg-3 col-md-6 mb-3" v-if="formFields[index].first_name">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields[index].first_name)" :custom-messages="getErrorMessages('first_name')">
                                        <label for="first_name">
                                            First Name
                                            <span v-if="formFields[index].first_name.required" class="required">*</span>
                                        </label>
                                    <input class="info-field" v-model="data.first_name"  id="first_name">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-4 col-lg-3 col-md-6 mb-3" v-if="formFields[index].middle_name">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields[index].middle_name)" :custom-messages="getErrorMessages('middle_name')">
                                        <label for="middle_name">
                                            Middle Name
                                            <span v-if="formFields[index].middle_name.required" class="required">*</span>
                                        </label>
                                    <input class="info-field" v-model="data.middle_name"  id="middle_name">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-4 col-lg-3 col-md-6 mb-3" v-if="formFields[index].last_name">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields[index].last_name)" :custom-messages="getErrorMessages('last_name')">
                                        <label for="last_name">
                                            Last Name
                                            <span v-if="formFields[index].last_name.required" class="required">*</span>
                                        </label>
                                    <input class="info-field" v-model="data.last_name"  id="last_name">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-4 col-lg-3 col-md-6 mb-3" v-if="formFields[index].birthdate">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields[index].birthdate)" :custom-messages="getErrorMessages('birthdate')">
                                        <label for="birthdate">
                                            Birthdate
                                            <span v-if="formFields[index].birthdate.required" class="required">*</span>
                                        </label>
                                        <date-picker input-class="info-field" 
                                        placeholder="" 
                                        type="date"  
                                        id="birthdate"
                                        v-model="data.birthdate"
                                        value-type="format"
                                        format="MM/DD/YYYY"></date-picker>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-4 col-lg-3 col-md-6 mb-3" v-if="formFields[index].ssn">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields[index].ssn)" :custom-messages="getErrorMessages('ssn')">
                                        <label for="ssn">
                                            SSN
                                            <span v-if="formFields[index].ssn.required" class="required">*</span>
                                        </label>
                                    <input class="info-field" v-model="data.ssn" id="ssn" maxlength="9">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-4 col-lg-3 col-md-6 mb-3" v-if="formFields[index].relation">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields[index].relation)" :custom-messages="getErrorMessages('relation')">
                                        <label for="relation">
                                            Relationship
                                            <span v-if="formFields[index].relation.required" class="required">*</span>
                                        </label>
                                        <select class="info-field" id="relation" v-model="data.relation">
                                            <option v-for="(option, index1) in formFields[index].relation.options" :key="index1" :id="option.index1" :value="option.value">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-6 col-lg-3 col-md-6 mb-3" v-if="formFields[index].beneficiary_percentage && !data.is_contigent">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields[index].beneficiary_percentage)" :custom-messages="getErrorMessages('beneficiary_percentage')">
                                        <label for="beneficiary_percentage">
                                             Percentage (upto 100%)
                                            <span v-if="formFields[index].beneficiary_percentage.required" class="required">*</span>
                                        </label>
                                    <input class="info-field" v-model="data.beneficiary_percentage"  id="beneficiary_percentage">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <div class="form-group text-center">
                                <b-button class="mt-3 univ-btn" variant="info" type="button" @click="handleSubmit(saveBeneficiary(index))">submit</b-button>
                            </div>
                        </div>

                        <!-- <template v-slot:modal-footer>
                            <b-button class="mt-3 univ-btn" variant="info" type="button" @click="handleSubmit(saveBeneficiary(index))">submit</b-button>
                        </template> -->
                    </b-modal>
                </form>
            </ValidationObserver>
        </div>

        <div class="row" v-if="totalBenPercentage != 100">
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                <span class="text-danger">Total beneficiary percentage should be 100%</span>
            </div>
        </div>
        
                
        <div class="row" v-if="totalBenPercentage < 100">
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="postData.filter(item => item.is_contigent != 1).length < 3" >
                <b-button variant="" v-b-modal="'add-beneficiary'">Add Beneficiary</b-button>
            </div>           
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="!checkContigentFlag()">
                <b-button variant="" v-b-modal="'add-contingent-beneficiary'">Add Contingent Beneficiary</b-button>
        </div>
    
        <!-- add beneficiary modal -->
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(addBeneficiary)">
                <b-modal :id="'add-beneficiary'" :ref="'add-beneficiary'" size="lg" title="Beneficiary " :ok-disabled="true" @hidden="resetModal">
                    
                    <div class="info-form mb-4">
                        <div class="form-group row">
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.first_name)" :custom-messages="getErrorMessages('first_name')">
                                    <label for="first_name">
                                        First Name
                                        <span class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.first_name"  id="first_name">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.middle_name)" :custom-messages="getErrorMessages('middle_name')">
                                    <label for="middle_name">
                                        Middle Name
                                        <span v-if="form.middle_name.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.middle_name"  id="middle_name">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.last_name">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.last_name)" :custom-messages="getErrorMessages('last_name')">
                                    <label for="last_name">
                                        Last Name
                                        <span v-if="form.last_name.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.last_name"  id="last_name">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.birthdate">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.birthdate)" :custom-messages="getErrorMessages('birthdate')">
                                    <label for="birthdate">
                                        Birthdate
                                        <span v-if="form.birthdate.required" class="required">*</span>
                                    </label>
                                    <date-picker input-class="info-field" 
                                    placeholder="" 
                                    type="date"  
                                    id="birthdate"
                                    v-model="data.birthdate"
                                    value-type="format"
                                    format="MM/DD/YYYY"></date-picker>
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.ssn">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.ssn)" :custom-messages="getErrorMessages('ssn')">
                                    <label for="ssn">
                                        SSN
                                        <span v-if="form.ssn.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.ssn" id="ssn" maxlength="9">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.relation">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.relation)" :custom-messages="getErrorMessages('relation')">
                                    <label for="relation">
                                        Relationship
                                        <span v-if="form.relation.required" class="required">*</span>
                                    </label>
                                    <select class="info-field" id="relation" v-model="data.relation">
                                        <option v-for="(option, index1) in form.relation.options" :key="index1" :id="option.index1" :value="option.value">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.beneficiary_percentage">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.beneficiary_percentage)" :custom-messages="getErrorMessages('beneficiary_percentage')">
                                    <label for="beneficiary_percentage">
                                         Percentage (upto 100%)
                                        <span v-if="form.beneficiary_percentage.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.beneficiary_percentage"  id="beneficiary_percentage">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>

                    <div class="form-group text-center">
                        <b-button class="mt-3 univ-btn" variant="info" type="button" @click="handleSubmit(addBeneficiary)">submit</b-button>
                    </div>
                </b-modal>
            </form>
        </ValidationObserver>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(addContingentBeneficiary)">
                <b-modal :id="'add-contingent-beneficiary'" :ref="'add-beneficiary'" size="lg" title="Contingent Beneficiary" :ok-disabled="true" @hidden="resetModal">
                    
                    <div class="info-form mb-4">
                        <div class="form-group row">
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.first_name)" :custom-messages="getErrorMessages('first_name')">
                                    <label for="first_name">
                                        First Name
                                        <span class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.first_name"  id="first_name">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.middle_name)" :custom-messages="getErrorMessages('middle_name')">
                                    <label for="middle_name">
                                        Middle Name
                                        <span v-if="form.middle_name.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.middle_name"  id="middle_name">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.last_name">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.last_name)" :custom-messages="getErrorMessages('last_name')">
                                    <label for="last_name">
                                        Last Name
                                        <span v-if="form.last_name.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.last_name"  id="last_name">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.birthdate">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.birthdate)" :custom-messages="getErrorMessages('birthdate')">
                                    <label for="birthdate">
                                        Birthdate
                                        <span v-if="form.birthdate.required" class="required">*</span>
                                    </label>
                                    <date-picker input-class="info-field" 
                                    placeholder="" 
                                    type="date"
                                    id="birthdate"
                                    v-model="data.birthdate"
                                    value-type="format"
                                    format="MM/DD/YYYY"></date-picker>
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.ssn">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.ssn)" :custom-messages="getErrorMessages('ssn')">
                                    <label for="ssn">
                                        SSN
                                        <span v-if="form.ssn.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.ssn" id="ssn" maxlength="9">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.relation">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.relation)" :custom-messages="getErrorMessages('relation')">
                                    <label for="relation">
                                        Relationship
                                        <span v-if="form.relation.required" class="required">*</span>
                                    </label>
                                    <select class="info-field" id="relation" v-model="data.relation">
                                        <option v-for="(option, index1) in form.relation.options" :key="index1" :id="option.index1" :value="option.value">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                           
                        </div>
                    </div>

                    <div class="form-group text-center">
                        <b-button class="mt-3 univ-btn" variant="info" type="button" @click="handleSubmit(addContingentBeneficiary)">submit</b-button>
                    </div>
                </b-modal>
            </form>
        </ValidationObserver>


        <div class="row justify-content-end mt-3">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                <button type="button" class="univ-btn" style=" max-width: 100% !important;" :disabled="totalBenPercentage != 100" @click="updateBeneficiaryInfo">{{isProcessing ? 'Processing' : 'Update'}}</button>
            </div>
        </div>
    </div>
</template>
<script>
import Api from '../../resource/Api';

export default {
    name: 'BeneficiaryInfo',
    data: () => ({
        version: process.env.VUE_APP_API_VERSION,
        isProcessing: false,
        postData: [],
        formFields: [],
        index: 0,
        form:{
                beneficiary_percentage:{
                    field_name:"beneficiary_percentage",
                    options:[],
                    read_only:false,
                    required:true,
                    type:"text",
                    value:""
                },
                birthdate:{
                    field_name:"birthdate",
                    options:[],
                    read_only:false,
                    required:true,
                    type:"date",
                    value:""
                },
                first_name:{
                    field_name:"first_name",
                    options:[],
                    read_only:false,
                    required:true,
                    type:"text",
                    value:""
                },
                last_name:{
                    field_name:"last_name",
                    options:[],
                    read_only:false,
                    required:true,
                    type:"text",
                    value:""
                },
                middle_name:{
                    field_name:"middle_name",
                    options:[],
                    read_only:false,
                    required:false,
                    type:"text",
                    value:""
                },
                relation:{
                    field_name:"relation",
                    options:[
                        {
                            name:"Spouse",
                            value:"Spouse"
                        },
                        {
                            name:"Uncle or Aunt",
                            value:"Uncle or Aunt"
                        },
                        {
                            name:"Nephew or Niece",
                            value:"Nephew or Niece"
                        },
                        {
                            name:"Brother or Sister",
                            value:"Brother or Sister"
                        },
                        {
                            name:"Child",
                            value:"Child"
                        },
                        {
                            name:"Ex Spouse",
                            value:"Ex Spouse"
                        },
                        {
                            name:"Mother",
                            value:"Mother"
                        },
                        {
                            name:"Father",
                            value:"Father"
                        },
                        {
                            name:"Life Partner",
                            value:"Life Partner"
                        },
                        {
                            name:"Trust",
                            value:"Trust"
                        },
                        {
                            name:"Friend",
                            value:"Friend"
                        },
                        {
                            name:"Other",
                            value:"Other"
                        }
                    ],
                    read_only:false,
                    required:true,
                    type:"select",
                    value:""
                },
                ssn:{
                    field_name:"ssn",
                    options:[],
                    read_only:false,
                    required:true,
                    type:"text",
                    value:""
                },
            },
        data:{
            beneficiary_percentage:"",
            birthdate:"",
            first_name:"",
            last_name:"",
            middle_name:"",
            relation:"",
            ssn:"",
        }
    }),
    methods: {
        getBeneficiaryInfo(){
            let url = "v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/beneficiary-info";
            Api.get(url)
                .then((res) => {
                    let benInfo = res.data.data;
                    let tempFormFields, tempPostData;
                    for(let beneficiaryId in benInfo){
                        benInfo[beneficiaryId].map(info => {
                            tempPostData = {...tempPostData, [info.field_name]: info.value}
                            tempFormFields = {...tempFormFields, [info.field_name]: info}
                        })
                        this.formFields.push(tempFormFields)
                        this.postData.push(tempPostData)
                    }
                    this.index = this.postData.length;
                })
                .catch((err) => {
                    Api.notification(err.status, err.message)
                })
        },
        updateBeneficiaryInfo(){
            if(this.totalBenPercentage == 100){
                let url = "v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/beneficiary-info";
                this.isProcessing = true;
                let beneficiaries = {['beneficiaries'] : {...this.postData}};
                Api.patch(url, beneficiaries)
                    .then((response) => {
                        Api.notification(response.data.status, response.data.message);
                    })
                    .catch((err) => {
                        Api.notification(err.response.data.status, err.response.data.message);
                        Api.notification(err.response.data.success, err.response.data.message);
                    })
                    .finally(()=>{
                        this.isProcessing = false;
                    })
            }else{
                Api.notification(false, 'Total beneficiary percentage should be 100%');
            }
        },
        getLabelName(fieldName){
            return fieldName.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        },
        checkContigentFlag () {
            if (this.postData.length > 0) {
                return this.postData.some(data => data.is_contigent === 1)
            } else {
                return false
            }
        },

        getRules(item){
            if(item == null){
                return {};
            }
            if(item.field_name === 'first_name' || item.field_name === 'last_name'){
                return {
                    required: item.required,
                    alpha_spaces: true
                }
            }
            if(item.field_name === 'ssn'){
                return {
                    required: item.required,
                    digits: 9
                }
            }
            if(item.field_name === 'middle_name'){
                return {
                    required: item.required,
                    length: 1, 
                    alpha: true
                }
            }
            if(item.field_name === 'beneficiary_percentage'){
                return {
                    required: item.required,
                    max: 3,
                    numeric: true,
                    max_value: 100,
                    min_value: 1
                }
            }
            return {required: item.required}
        },
        getErrorMessages(field_name){
            if(field_name === 'middle_name'){
                return {
                'required': this.getLabelName(field_name) + " is required",
                'alpha': this.getLabelName(field_name) + " may only contain alphabetic charactersssss",
                'length': this.getLabelName(field_name) + " cannot be more than 1 character"
                }    
            }
            return {
                'required': this.getLabelName(field_name) + " is required",
                'alpha_spaces': this.getLabelName(field_name) + " may only contain alphabetic characters",
                'max': this.getLabelName(field_name) + ' cannot be more than 100%',
                'numeric': this.getLabelName(field_name) + ' may only contain numeric characters',
                'max_value': this.getLabelName(field_name) + ' cannot be more than 100%',
                'min_value': this.getLabelName(field_name) + ' cannot be less than 1%',
            }
        },
        deleteBeneficiary(index){
            this.$delete(this.formFields, index);
            this.$delete(this.postData, index);
            index = index - 1;
        },
        addBeneficiary(){
            let newBen = {...this.data, is_contigent: 0,}
            this.postData.push(newBen);
            this.formFields.push(this.form);
            this.$refs["add-beneficiary"].hide();
        },
        addContingentBeneficiary(){
            let newBen = {...this.data, is_contigent: 1, beneficiary_percentage: 0};
            this.postData.push(newBen);
            this.formFields.push(this.form);
            this.$refs["add-beneficiary"].hide();
        },
       
        saveBeneficiary(index){
            this.$set(this.postData, index, {...this.data});
            this.$refs["edit-beneficiary"+index][0].hide();
        },
        editBeneficiary(ben){
            this.data = {...ben};
            // this.index = index;
        },
        resetModal(){
            this.data.beneficiary_percentage = '';
            this.data.birthdate = "";
            this.data.first_name = "";
            this.data.last_name = "";
            this.data.middle_name = "";
            this.data.relation = "";
            this.data.ssn = "";
        },
        getFullName(ben){
            let middle_name = ben.middle_name ?? '';
            return ben.first_name + ' ' + middle_name + ' ' + ben.last_name
        }
    },
    mounted(){
        this.getBeneficiaryInfo();
    },
    computed: {
        beneficiariesLength: function(){
            return Object.keys(this.postData).length;
        },
        totalBenPercentage: function(){
            let total = 0;
            this.postData.forEach(ben => {
                total += parseInt(ben.beneficiary_percentage);
            })
            return total;
        }
    },
   
}
</script>