<template>
    <div class="info-wrap">
        <h2>Personal Health Questionaire</h2>
        <p>Enter and Update all your Personal Health Questionaire here</p>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(updateHealthInfo())">
                <div class="info-form">
                    <!-- Condition questions -->
                    <div v-for="(question, index1) in healthInfo.condition_questions" :key="index1+'B'" class="mx-2">
                        <div class="split-list">
                            <p>{{ question.question_text }}</p>
                            <div class="split-yn-div">
                                <div class="form-check form-check-inline">
                                    <input
                                    class="form-check-input"
                                    type="radio"
                                    :name="'condition-question'+question.question_id"
                                    :id="index1"
                                    value=1
                                    v-model="healthInfo.condition_questions[index1].selected_answer"
                                    @click="addCondition(question)"
                                    >
                                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                    class="form-check-input"
                                    type="radio"
                                    :name="'condition-question'+question.question_id"
                                    :id="index1"
                                    value=0
                                    v-model="healthInfo.condition_questions[index1].selected_answer"
                                    @click="removeAllConditions(question)">
                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="question.selected_answer == 1">
                            <h6>Conditions</h6>
                            <div v-for="(condition, index2) in question.conditions" :key="index2+'DASd'" class="row">
                                <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-12 mb-3">
                                    <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('health_condition')">
                                        <label for="health_condition">
                                            Health Condition
                                            <span class="required">*</span>
                                        </label>
                                        <input class="info-field" v-model="healthInfo.condition_questions[index1].conditions[index2].health_condition"  id="health_condition">
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-6 mb-3">
                                    <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('doctor_last_seen')">
                                        <label for="d_last_seen">
                                            Last seen by Doctor
                                            <span class="required">*</span>
                                        </label>
                                        <date-picker input-class="info-field"
                                        placeholder=""
                                        type="date"
                                        id="d_last_seen"
                                        v-model="healthInfo.condition_questions[index1].conditions[index2].d_last_seen"
                                        value-type="format"
                                        format="MM/DD/YYYY"></date-picker>
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3">
                                    <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('date_of_onset')">
                                        <label for="date_of_onset">
                                            Date of Onset
                                            <span class="required">*</span>
                                        </label>
                                        <date-picker input-class="info-field"
                                        placeholder=""
                                        type="date"
                                        id="date_of_onset"
                                        v-model="healthInfo.condition_questions[index1].conditions[index2].date_of_onset"
                                        value-type="format"
                                        format="MM/DD/YYYY"></date-picker>
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3">
                                    <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('date_of_recovery')">
                                        <label for="date_of_recovery">
                                            Date of recovery
                                            <span class="required">*</span>
                                        </label>
                                        <date-picker input-class="info-field"
                                        placeholder=""
                                        type="date"
                                        id="date_of_recovery"
                                        v-model="healthInfo.condition_questions[index1].conditions[index2].date_of_recovery"
                                        value-type="format"
                                        format="MM/DD/YYYY"></date-picker>
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                                    <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('symptoms')">
                                        <label for="symptoms">
                                            Symptoms
                                            <span class="required">*</span>
                                        </label>
                                        <input
                                        class="info-field"
                                        v-model="healthInfo.condition_questions[index1].conditions[index2].symptoms"
                                        id="symptoms">
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="form-check ques-check col-xxl-2 col-xl-2 col-lg-3 col-md-6 mb-3">
                                    <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="healthInfo.condition_questions[index1].conditions[index2].is_medicate"
                                    id="is_medicate"
                                    true-value=1
                                    false-value=0
                                    >
                                    <label class="form-check-label" for="is_medicate">
                                        Taking Medication
                                    </label>
                                </div>
                                <div class="form-check ques-check col-xxl-2 col-xl-2 col-lg-3 col-md-6 mb-3">
                                    <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="healthInfo.condition_questions[index1].conditions[index2].is_treatment"
                                    id="is_treatment"
                                    true-value=1
                                    false-value=0
                                    >
                                    <label class="form-check-label" for="is_treatment">
                                        Current Treatment
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- medication question -->
                    <div class="split-list" v-if="healthInfo.medication_question">
                        <p>{{ healthInfo.medication_question.question_text }}</p>
                        <div class="split-yn-div">
                            <div class="form-check form-check-inline">
                                <input
                                class="form-check-input"
                                type="radio"
                                :name="'medication-question'+healthInfo.medication_question.question_id"
                                :id="'medication-question'+healthInfo.medication_question.question_id"
                                value=1
                                v-model="healthInfo.medication_question.selected_answer"
                                @click="addMedications(healthInfo.medication_question)"
                                >
                                <label class="form-check-label" for="inlineRadio1">Yes</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                class="form-check-input"
                                type="radio"
                                :name="'medication-question'+healthInfo.medication_question.question_id"
                                :id="'medication-question'+healthInfo.medication_question.question_id"
                                value=0
                                v-model="healthInfo.medication_question.selected_answer"
                                @click="removeAllMedications(healthInfo.medication_question)"
                                >
                                <label class="form-check-label" for="inlineRadio2">No</label>
                            </div>
                        </div>
                        <!-- medications -->
                        <div v-if="healthInfo.medication_question.selected_answer == 1">
                            <h6>Medications</h6>
                            <div class="row mb-3" v-for="(medication, index4) in healthInfo.medication_question.medications" :key="index4+'sdcasc'">
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
                                    <label>Medication/Rx injection<span class="required">*</span></label>
                                    <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('medication')">
                                        <input
                                        class="info-field"
                                        v-model="healthInfo.medication_question.medications[index4].medication"
                                        :id="'medication'+index4">
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
                                    <label>Dosage<span class="required">*</span></label>
                                    <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('dosage')">
                                        <input
                                        class="info-field"
                                        v-model="healthInfo.medication_question.medications[index4].dosage"
                                        :id="'dosage'+index4">
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
                                    <label>Medical Condition<span class="required">*</span></label>
                                    <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('medical_condition')">
                                        <input
                                        class="info-field"
                                        v-model="healthInfo.medication_question.medications[index4].medical_condition"
                                        :id="'medical_condition'+index4">
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 align-center">
                                    <span class="remove-medication"
                                    @click="removeMedication(healthInfo.medication_question, index4)"
                                    v-if="healthInfo.medication_question.medications.length > 1"
                                    >
                                       <i class="far fa-times-circle"></i> Remove
                                    </span>
                                </div>
                            </div>

                            <div class="row mt-2" v-if="healthInfo.medication_question.medications.length < 3">
                                <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                                    <b-button class="univ-btn" variant="success" @click="addMedications(healthInfo.medication_question)">+ Add Medications</b-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group mx-2">
                        <label for="">Additional Notes</label>
                        <textarea
                        name=""
                        id=""
                        class="info-field"
                        rows="4"
                        placeholder="Enter additional notes"
                        v-model="healthInfo.notes"></textarea>
                        <span class="note-text">*Please note, any false information, or information not given may result in termination of eligibility for coverage under the Plan or denial of benefits under the Plan.*</span>
                    </div>
                </div>
                <div class="row justify-content-end mt-3">
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                        <button
                        type="button"
                        class="univ-btn"
                        style=" max-width: 100% !important;"
                        @click="handleSubmit(updateHealthInfo())">{{ isProcessing ? 'Processing' :'Update'}}</button>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
import Api from '../../resource/Api';
export default {
    name: 'PersonalQuestionaire',
    data() {
      return {
        selected: null,
        isProcessing: false,
        healthInfo: [],
      }
    },
    methods: {
        getHealthQuestions(){
            let url = "v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/primary-health-info";
            Api.get(url)
                .then(res => {
                    this.healthInfo = res.data.data;
                    let conditionQuestionsData = [], conditionsData = {};
                    let medicationQuestionData = [], medicationsAnsList = [];
                    this.healthInfo.condition_questions.forEach((conditionQuestion) => {
                        if(conditionQuestion.selected_answer == 1){
                            conditionQuestion.conditions.forEach((condition) => {
                                condition.forEach(data => {
                                    conditionsData = {...conditionsData, [data.field_name]: data.value};
                                })
                            })
                        }
                        conditionQuestionsData.push({
                            ['question_id']: conditionQuestion.question_id,
                            ['question_text']: conditionQuestion.question_text,
                            ['selected_answer']: conditionQuestion.selected_answer,
                            ['conditions']: conditionQuestion.selected_answer ==1 ? [{...conditionsData}] : []
                        })
                    })

                    let medicationQuestion = this.healthInfo.medication_question;
                    if(medicationQuestion){
                        if(medicationQuestion.selected_answer == 1){
                            medicationQuestion.medications.forEach((medication) => {
                              let finalData = this.mapMedicationsList(medication)
                              medicationsAnsList.push(finalData)
                            })
                        }
                        medicationQuestionData = {
                            ['question_id']: medicationQuestion.question_id,
                            ['question_text']: medicationQuestion.question_text,
                            ['selected_answer']: medicationQuestion.selected_answer,
                            ['medications']: medicationQuestion.selected_answer == 1 ? medicationsAnsList : []
                        }
                    }
                    this.healthInfo.condition_questions = conditionQuestionsData;
                    this.healthInfo.medication_question = medicationQuestionData;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        mapMedicationsList(data) {
          let medicationsData = {}
          data.forEach((data) => {
            medicationsData = {...medicationsData, [data.field_name]: data.value}
          })
          return medicationsData
        },
        updateHealthInfo(){
            let url = "v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/primary-health-info";
            this.isProcessing = true;
            Api.patch(url, this.healthInfo)
                .then(response => {
                    Api.notification(response.data.status, response.data.message);
                })
                .catch((err) => {
                    Api.notification(err.response.data.status, err.response.data.message);
                    Api.notification(err.response.data.success, err.response.data.message);
                })
                .finally(()=>{
                    this.isProcessing = false;
                })
        },
        getLabelName(fieldName){
            return fieldName.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        },
        getErrorMessages(field_name){
            return {
                'required': this.getLabelName(field_name) + " is required",
            }
        },
        addMedications(question){
            question.medications.push({
                'dosage': '',
                'medical_condition': '',
                'medication': ''
            });
        },
        removeMedication(question, index){
            this.$delete(question.medications, index);
        },
        removeAllMedications(question){
            question.medications = [];
        },
        addCondition(question){
            question.conditions.push({
                d_last_seen:"",
                date_of_onset:"",
                date_of_recovery:"",
                health_condition:"",
                is_medicate:"0",
                is_treatment:"0",
                symptoms:""
            });
        },
        removeAllConditions(question){
            question.conditions = [];
        },
    },
    mounted(){
        this.getHealthQuestions();
    }
}
</script>
