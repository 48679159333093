<template>
    <div class="info-wrap">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(updatePrimaryInfo)">
                <h2>Personal Information</h2>
                <p>Enter and Update all your Personal Information here</p>
                <div class="info-form mb-4">            
                    <div class="form-group row">  
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.first_name">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['first_name'])" :custom-messages="getErrorMessages('first_name')">
                                <label for="first_name">
                                    First Name
                                    <span v-if="formFields['first_name']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['first_name']"  id="first_name">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.middle_name">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['middle_name'])" :custom-messages="getErrorMessages('middle_name')">
                                <label for="middle_name">
                                    Middle Name
                                    <span v-if="formFields['middle_name']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['middle_name']"  id="middle_name">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.last_name">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['last_name'])" :custom-messages="getErrorMessages('last_name')">
                                <label for="last_name">
                                    Last Name
                                    <span v-if="formFields['last_name']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['last_name']"  id="last_name">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.gender">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['gender'])" :custom-messages="getErrorMessages('gender')">
                                <label for="gender">
                                    Gender
                                    <span v-if="formFields.gender.required" class="required">*</span>
                                </label>
                                <select class="info-field" id="gender" v-model="postData['gender']">
                                    <option v-for="(option, index1) in formFields['gender']['options']" :key="index1" :id="option.index1" :value="option.value">
                                        {{ option.name }}
                                    </option>
                                </select>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.birthdate">
                            <label for="birthdate">
                                Birthdate
                                <span v-if="formFields['birthdate']['required']" class="required">*</span>
                            </label>
                            <date-picker input-class="info-field" 
                            placeholder="" 
                            type="date"  
                            id="birthdate"
                            v-model="postData['birthdate']"
                            value-type="format"
                            format="MM/DD/YYYY"
                            disabled></date-picker>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.marital_status">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['marital_status'])" :custom-messages="getErrorMessages('marital_status')">
                                <label for="marital_status">
                                    Marital Status
                                    <span v-if="formFields['marital_status']['required']" class="required">*</span>
                                </label>
                                <select class="info-field" id="marital_status" v-model="postData['marital_status']">
                                    <option v-for="(option, index1) in formFields['marital_status']['options']" :key="index1" :id="option.index1" :value="option.value">
                                        {{ option.name }}
                                    </option>
                                </select>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.ssn">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['ssn'])" :custom-messages="getErrorMessages('ssn')">
                                <label for="ssn">
                                    SSN
                                    <span v-if="formFields['ssn']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['ssn']"  id="ssn" maxlength="9">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.height_feet">
                            <ValidationProvider v-slot="{ errors }" v-if="formFields['height_feet'] != null" :rules="getRules(formFields['height_feet'])" :custom-messages="getErrorMessages('height_feet')">
                                <label for="height_feet">
                                    Height(feet)
                                    <span v-if="formFields['height_feet']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['height_feet']"  id="height_feet">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.height_inch">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(formFields['height_inch'])" :custom-messages="getErrorMessages('height_inch')">
                                <label for="height_inch">
                                    Height(inch)
                                    <span v-if="formFields['height_inch']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['height_inch']"  id="height_inch">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.weight">
                            <ValidationProvider v-slot="{ errors }" v-if="formFields['weight'] != null" :rules="getRules(formFields['weight'])" :custom-messages="getErrorMessages('weight')">
                                <label for="weight">
                                    Weight(lbs)
                                    <span v-if="formFields['weight']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['weight']"  id="weight">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-12 col-lg-12 col-md-12 mb-3" v-if="formFields.prior_coverage_flag">
                            <ValidationProvider v-slot="{ errors }">
                                Within the last 12 months I have had
                                <div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" :value=false v-model="postData['prior_coverage_flag']">
                                        <label class="form-check-label" for="inlineRadio1">No Prior Health Coverage</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" :value=true v-model="postData['prior_coverage_flag']">
                                        <label class="form-check-label" for="inlineRadio2">Have has insurance carrier</label>
                                    </div>
                                </div>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="postData['prior_coverage_flag']">
                            <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="{ required: 'Prior carrier name is required' }">
                                <label for="prior_carrier_name">
                                    Pre Health Carrier
                                    <span class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['prior_carrier_name']"  id="prior_carrier_name">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="postData['prior_coverage_flag']">
                            <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="{ required: 'Pre effective date is required' }">
                                <label for="prior_effective_date">
                                    Pre Effective Date
                                    <span class="required">*</span>
                                </label>
                                <date-picker input-class="info-field" 
                                placeholder="MM/DD/YYYY" 
                                type="date"  
                                id="prior_effective_date"
                                v-model="postData['prior_effective_date']"
                                value-type="format"
                                format="MM/DD/YYYY"></date-picker>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="postData['prior_coverage_flag']">
                            <ValidationProvider ref="termDate" v-slot="{ errors }" rules="required" :custom-messages="{ required: 'Pre term date is required' }">
                                <label for="prior_term_date">
                                    Pre Term Date
                                    <span class="required">*</span>
                                </label>
                                <date-picker input-class="info-field" 
                                placeholder="MM/DD/YYYY" 
                                type="date"  
                                id="prior_term_date"
                                v-model="postData['prior_term_date']"
                                value-type="format"
                                format="MM/DD/YYYY"></date-picker>
                                <span class="text-danger">{{ errors[0] }}</span>
                             </ValidationProvider>
                        </div>

                    </div>
                    <div class="row justify-content-end mt-3">
                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                            <button type="submit" class="univ-btn" style=" max-width: 100% !important;">{{ isProcessing ? 'Processing' : 'Update' }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
import Api from '../../resource/Api';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
/*eslint-disable*/

export default {
    name: 'InfoDetails',
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    data: () => ({
        postData: [],
        version: process.env.VUE_APP_API_VERSION,
        formFields: [],
        isProcessing: false,
    }),
    mounted(){
        this.getPrimaryInfo();
    },
    methods: {
        getLabelName(fieldName){
            if(fieldName === 'weight')
                return fieldName.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + ' (lbs)'; 
            return fieldName.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        },
        getRules(item){
            if(item == null){
                return {};
            }
            //Personal info field rules
            if(item.field_name === 'first_name' || item.field_name === 'last_name'){
                return {
                    required: item.required,
                    alpha_spaces: true
                }
            }
            if(item.field_name === 'middle_name'){
                return {
                    required: item.required,
                    length: 1, 
                    alpha: true
                }
            }
            if(item.field_name === 'height_feet' || item.field_name === 'weight'){
                return {
                    required: item.required,
                    numeric: true,
                    min_value: 1
                }
            }
            if(item.field_name === 'height_inch'){
                return {
                    required: item.required,
                    numeric: true,
                    min_value: 0,
                    max_value: 12
                }
            }
            return {required: item.required}
        },
        getErrorMessages(field_name){
            if(field_name === 'height_feet' || field_name === 'weight'){
               return {
                'required': this.getLabelName(field_name) + " is required",
                'numeric': this.getLabelName(field_name) + " may only contain positive numbers",
                'min_value': this.getLabelName(field_name) + " must be more than 1"
               }
            }
            if(field_name === 'middle_name'){
                return {
                'required': this.getLabelName(field_name) + " is required",
                'alpha_spaces': this.getLabelName(field_name) + " may only contain alphabetic characters",
                'length': this.getLabelName(field_name) + " cannot be more than 1 character"
                }    
            }
            if(field_name === 'height_inch'){
                return {
                'required': this.getLabelName(field_name) + " is required",
                'alpha': this.getLabelName(field_name) + " may only contain alphabetic characters",
                'numeric': this.getLabelName(field_name) + " may only contain positive numbers",
                'min_value': 'Height(inch) must be between 0 and 12',
                'max_value': 'Height(inch) must be between 0 and 12'
               }
            }
            return {
                'required': this.getLabelName(field_name) + " is required",
                'alpha': this.getLabelName(field_name) + " may only contain alphabetic characters",
            }
        },

        async getPrimaryInfo(){
            let url ="v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/primary-info";
            let loader = this.$loading.show();
            Api.get(url)
                .then((res) => {
                    let primaryInfo = res.data.data;
                    primaryInfo.map(info => {
                        this.postData = {...this.postData, [info.field_name]: info.value}
                        this.formFields = {...this.formFields, [info.field_name]: info}
                    })
                }).catch((err) => {
                    Api.notification(err.status, err.message)
                })
                .finally(() => {
                    loader.hide();
                });
        },

        async updatePrimaryInfo(){
            this.isProcessing = true;
            Api.patch(`${this.$endpoint.REP_24HRS_EDIT}/${atob(this.$route.params.enrollmentId)}/primary-info`, this.postData)
                .then((response) => {
                    Api.notification(response.data.status, response.data.message);
                })
                .catch((err) => {
                    Api.notification(err.response.data.status, err.response.data.message);
                    Api.notification(err.response.data.success, err.response.data.message);
                })
                .finally(()=>{
                    this.isProcessing = false;
                })
        },
    },
}
</script>

<style scoped>
input[disabled], select[disabled] {
     background-color:#dce1e7;
}
</style>