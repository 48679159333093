var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrap"
  }, [_c('h2', [_vm._v("Dependent Information")]), _vm.tiers.includes('IC') ? _c('div', [_c('h6', [_vm._v("Child information required ")])]) : _vm._e(), _vm.tiers.includes('IS') ? _c('div', [_c('h6', [_vm._v("Spouse information required ")])]) : _vm._e(), _vm.tiers.includes('IF') ? _c('div', [_c('h6', [_vm._v("Spouse & Child information required ")])]) : _vm._e(), _vm._l(_vm.postData, function (dep, index) {
    return _c('div', {
      key: index + 'A',
      attrs: {
        "id": index
      }
    }, [_c('h3', [_vm._v("Dependent Details #" + _vm._s(parseInt(index) + 1))]), _c('div', {
      staticClass: "row mb-3"
    }, [_c('b-input-group', {
      attrs: {
        "id": 'dependent' + index
      }
    }, [_c('b-input-group-append', {
      staticClass: "split-group"
    }, [_c('b-form-input', {
      staticClass: "info-field",
      attrs: {
        "value": _vm.getFullName(dep.primary_info),
        "disabled": ""
      }
    }), _c('b-button', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal",
        value: 'edit-dependent' + index,
        expression: "'edit-dependent' + index"
      }],
      attrs: {
        "variant": "primary edit"
      },
      on: {
        "click": function click($event) {
          return _vm.editDependent(dep, index);
        }
      }
    }, [_vm._v("Edit")]), _vm.showDelete && dep.primary_info.relationship !== 's' ? _c('b-button', {
      attrs: {
        "variant": "danger delete"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteDependent(index);
        }
      }
    }, [_vm._v("Delete")]) : _vm._e()], 1)], 1)], 1), _c('ValidationObserver', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var handleSubmit = _ref.handleSubmit;
          return [_c('form', {
            ref: 'edit_dependent_form' + index,
            refInFor: true,
            on: {
              "submit": function submit($event) {
                $event.preventDefault();
                return handleSubmit(_vm.saveDependent);
              }
            }
          }, [_c('b-modal', {
            ref: 'edit-dependent' + index,
            refInFor: true,
            attrs: {
              "id": 'edit-dependent' + index,
              "size": "xl",
              "title": 'Dependent #' + (parseInt(index) + 1)
            },
            on: {
              "hidden": _vm.resetModal
            }
          }, [_vm.form.primary_info ? _c('div', {
            staticClass: "info-form mb-4"
          }, [_c('div', {
            staticClass: "form-group row"
          }, [_vm.form.primary_info.first_name ? _c('div', {
            staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.form.primary_info.first_name),
              "custom-messages": _vm.getErrorMessages('first_name')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref2) {
                var errors = _ref2.errors;
                return [_c('label', {
                  attrs: {
                    "for": "first_name"
                  }
                }, [_vm._v(" First Name "), _vm.form.primary_info.first_name.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.primary_info.first_name,
                    expression: "data.primary_info.first_name"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "first_name"
                  },
                  domProps: {
                    "value": _vm.data.primary_info.first_name
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data.primary_info, "first_name", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.form.primary_info.middle_name ? _c('div', {
            staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.form.primary_info.middle_name),
              "custom-messages": _vm.getErrorMessages('middle_name')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref3) {
                var errors = _ref3.errors;
                return [_c('label', {
                  attrs: {
                    "for": "middle_name"
                  }
                }, [_vm._v(" Middle Name "), _vm.form.primary_info.middle_name.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.primary_info.middle_name,
                    expression: "data.primary_info.middle_name"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "middle_name"
                  },
                  domProps: {
                    "value": _vm.data.primary_info.middle_name
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data.primary_info, "middle_name", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.form.primary_info.last_name ? _c('div', {
            staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.form.primary_info.last_name),
              "custom-messages": _vm.getErrorMessages('last_name')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref4) {
                var errors = _ref4.errors;
                return [_c('label', {
                  attrs: {
                    "for": "last_name"
                  }
                }, [_vm._v(" Last Name "), _vm.form.primary_info.last_name.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.primary_info.last_name,
                    expression: "data.primary_info.last_name"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "last_name"
                  },
                  domProps: {
                    "value": _vm.data.primary_info.last_name
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data.primary_info, "last_name", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.form.primary_info.gender ? _c('div', {
            staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.form.primary_info.gender),
              "custom-messages": _vm.getErrorMessages('gender')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref5) {
                var errors = _ref5.errors;
                return [_c('label', {
                  attrs: {
                    "for": "gender"
                  }
                }, [_vm._v(" Gender "), _vm.form.primary_info.gender.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.primary_info.gender,
                    expression: "data.primary_info.gender"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "gender"
                  },
                  on: {
                    "change": function change($event) {
                      var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                        return o.selected;
                      }).map(function (o) {
                        var val = "_value" in o ? o._value : o.value;
                        return val;
                      });

                      _vm.$set(_vm.data.primary_info, "gender", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                    }
                  }
                }, [_c('option', {
                  attrs: {
                    "value": "0"
                  }
                }, [_vm._v("Male")]), _c('option', {
                  attrs: {
                    "value": "1"
                  }
                }, [_vm._v("Female")])]), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.form.primary_info.birthdate ? _c('div', {
            staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.form.primary_info.birthdate),
              "custom-messages": _vm.getErrorMessages('birthdate')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref6) {
                var errors = _ref6.errors;
                return [_c('label', {
                  attrs: {
                    "for": "birthdate"
                  }
                }, [_vm._v(" Birthdate "), _vm.form.primary_info.birthdate.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('date-picker', {
                  attrs: {
                    "input-class": "info-field",
                    "placeholder": "",
                    "type": "date",
                    "id": "birthdate",
                    "value-type": "format",
                    "format": "MM/DD/YYYY"
                  },
                  model: {
                    value: _vm.data.primary_info.birthdate,
                    callback: function callback($$v) {
                      _vm.$set(_vm.data.primary_info, "birthdate", $$v);
                    },
                    expression: "data.primary_info.birthdate"
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.form.primary_info.ssn ? _c('div', {
            staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.form.primary_info.ssn),
              "custom-messages": _vm.getErrorMessages('ssn')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref7) {
                var errors = _ref7.errors;
                return [_c('label', {
                  attrs: {
                    "for": "ssn"
                  }
                }, [_vm._v(" SSN "), _vm.form.primary_info.ssn.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.primary_info.ssn,
                    expression: "data.primary_info.ssn"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "ssn",
                    "maxlength": "9"
                  },
                  domProps: {
                    "value": _vm.data.primary_info.ssn
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data.primary_info, "ssn", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.form.primary_info.relationship ? _c('div', {
            staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.form.primary_info.relationship),
              "custom-messages": _vm.getErrorMessages('relationship')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref8) {
                var errors = _ref8.errors;
                return [_c('label', {
                  attrs: {
                    "for": "relation"
                  }
                }, [_vm._v(" Relationship "), _vm.form.primary_info.relationship.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.primary_info.relationship,
                    expression: "data.primary_info.relationship"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "relation",
                    "disabled": ""
                  },
                  on: {
                    "change": function change($event) {
                      var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                        return o.selected;
                      }).map(function (o) {
                        var val = "_value" in o ? o._value : o.value;
                        return val;
                      });

                      _vm.$set(_vm.data.primary_info, "relationship", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                    }
                  }
                }, _vm._l(_vm.form.primary_info.relationship.options, function (option, index1) {
                  return _c('option', {
                    key: index1,
                    attrs: {
                      "id": option.index1,
                      "disabled": _vm.disableRelationshipOption(option)
                    },
                    domProps: {
                      "value": option.value
                    }
                  }, [_vm._v(" " + _vm._s(option.name) + " ")]);
                }), 0), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.form.primary_info.height_feet ? _c('div', {
            staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.form.primary_info.height_feet),
              "custom-messages": _vm.getErrorMessages('height_feet')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref9) {
                var errors = _ref9.errors;
                return [_c('label', {
                  attrs: {
                    "for": "height_feet"
                  }
                }, [_vm._v(" Height(feet) "), _vm.form.primary_info.height_feet.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.primary_info.height_feet,
                    expression: "data.primary_info.height_feet"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "height_feet"
                  },
                  domProps: {
                    "value": _vm.data.primary_info.height_feet
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data.primary_info, "height_feet", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.form.primary_info.height_inch ? _c('div', {
            staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.form.primary_info.height_inch),
              "custom-messages": _vm.getErrorMessages('height_inch')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref10) {
                var errors = _ref10.errors;
                return [_c('label', {
                  attrs: {
                    "for": "height_inch"
                  }
                }, [_vm._v(" Height(inch) "), _vm.form.primary_info.height_inch.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.primary_info.height_inch,
                    expression: "data.primary_info.height_inch"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "height_inch"
                  },
                  domProps: {
                    "value": _vm.data.primary_info.height_inch
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data.primary_info, "height_inch", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.form.primary_info.weight ? _c('div', {
            staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.form.primary_info.weight),
              "custom-messages": _vm.getErrorMessages('weight')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref11) {
                var errors = _ref11.errors;
                return [_c('label', {
                  attrs: {
                    "for": "weight"
                  }
                }, [_vm._v(" Weight(lbs) "), _vm.form.primary_info.weight.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.primary_info.weight,
                    expression: "data.primary_info.weight"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "weight"
                  },
                  domProps: {
                    "value": _vm.data.primary_info.weight
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data.primary_info, "weight", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e()])]) : _vm._e(), _vm.data.health_info ? _c('div', {
            staticClass: "info-form mt-4"
          }, [_c('div', {
            staticClass: "question-accordion row"
          }, [_c('div', {
            staticClass: "col-md-12"
          }, [_c('figure', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle.collapse-1",
              modifiers: {
                "collapse-1": true
              }
            }]
          }, [_vm._v("Health Questionaire "), _c('i', {
            staticClass: "accordion-icon",
            class: _vm.visible ? 'fa fa-angle-up' : 'collapsed fa fa-angle-down',
            attrs: {
              "aria-expanded": _vm.visible ? 'true' : 'false',
              "aria-controls": "collapse-4"
            },
            on: {
              "click": function click($event) {
                _vm.visible = !_vm.visible;
              }
            }
          })]), _c('b-collapse', {
            attrs: {
              "id": "collapse-1"
            },
            model: {
              value: _vm.visible,
              callback: function callback($$v) {
                _vm.visible = $$v;
              },
              expression: "visible"
            }
          }, [_c('b-card', [_vm._l(_vm.data.health_info.condition_questions, function (question, index1) {
            return _c('div', {
              key: index1 + 'B',
              staticClass: "info-wrap mx-2"
            }, [_c('div', {
              staticClass: "split-list"
            }, [_c('p', [_vm._v(_vm._s(question.question_text))]), _c('div', {
              staticClass: "split-yn-div"
            }, [_c('div', {
              staticClass: "form-check form-check-inline"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.data.health_info.condition_questions[index1].selected_answer,
                expression: "data.health_info.condition_questions[index1].selected_answer"
              }],
              staticClass: "form-check-input",
              attrs: {
                "type": "radio",
                "name": 'condition-question' + question.question_id,
                "id": index1,
                "value": "1"
              },
              domProps: {
                "checked": _vm._q(_vm.data.health_info.condition_questions[index1].selected_answer, "1")
              },
              on: {
                "click": function click($event) {
                  return _vm.addCondition(question);
                },
                "change": function change($event) {
                  return _vm.$set(_vm.data.health_info.condition_questions[index1], "selected_answer", "1");
                }
              }
            }), _c('label', {
              staticClass: "form-check-label",
              attrs: {
                "for": "inlineRadio1"
              }
            }, [_vm._v("Yes")])]), _c('div', {
              staticClass: "form-check form-check-inline"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.data.health_info.condition_questions[index1].selected_answer,
                expression: "data.health_info.condition_questions[index1].selected_answer"
              }],
              staticClass: "form-check-input",
              attrs: {
                "type": "radio",
                "name": 'condition-question' + question.question_id,
                "id": index1,
                "value": "0"
              },
              domProps: {
                "checked": _vm._q(_vm.data.health_info.condition_questions[index1].selected_answer, "0")
              },
              on: {
                "click": function click($event) {
                  return _vm.removeAllConditions(question);
                },
                "change": function change($event) {
                  return _vm.$set(_vm.data.health_info.condition_questions[index1], "selected_answer", "0");
                }
              }
            }), _c('label', {
              staticClass: "form-check-label",
              attrs: {
                "for": "inlineRadio2"
              }
            }, [_vm._v("No")])])])]), question.selected_answer == 1 ? _c('div', [_c('h6', [_vm._v("Conditions")]), _vm._l(question.conditions, function (condition, index2) {
              return _c('div', {
                key: index2 + 'DASd',
                staticClass: "row"
              }, [_c('div', {
                staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-12 mb-3"
              }, [_c('ValidationProvider', {
                attrs: {
                  "rules": "required",
                  "custom-messages": _vm.getErrorMessages('health_condition')
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref12) {
                    var errors = _ref12.errors;
                    return [_c('label', {
                      attrs: {
                        "for": "health_condition"
                      }
                    }, [_vm._v(" Health Condition "), _c('span', {
                      staticClass: "required"
                    }, [_vm._v("*")])]), _c('input', {
                      directives: [{
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.health_info.condition_questions[index1].conditions[index2].health_condition,
                        expression: "data.health_info.condition_questions[index1].conditions[index2].health_condition"
                      }],
                      staticClass: "info-field",
                      attrs: {
                        "id": "health_condition"
                      },
                      domProps: {
                        "value": _vm.data.health_info.condition_questions[index1].conditions[index2].health_condition
                      },
                      on: {
                        "input": function input($event) {
                          if ($event.target.composing) { return; }

                          _vm.$set(_vm.data.health_info.condition_questions[index1].conditions[index2], "health_condition", $event.target.value);
                        }
                      }
                    }), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v(_vm._s(errors[0]))])];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-xxl-2 col-xl-2 col-lg-6 col-md-6 mb-3"
              }, [_c('ValidationProvider', {
                attrs: {
                  "rules": "required",
                  "custom-messages": _vm.getErrorMessages('doctor_last_seen')
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref13) {
                    var errors = _ref13.errors;
                    return [_c('label', {
                      attrs: {
                        "for": "d_last_seen"
                      }
                    }, [_vm._v(" Last seen by Doctor "), _c('span', {
                      staticClass: "required"
                    }, [_vm._v("*")])]), _c('date-picker', {
                      attrs: {
                        "input-class": "info-field",
                        "placeholder": "",
                        "type": "date",
                        "id": "d_last_seen",
                        "value-type": "format",
                        "format": "MM/DD/YYYY"
                      },
                      model: {
                        value: _vm.data.health_info.condition_questions[index1].conditions[index2].d_last_seen,
                        callback: function callback($$v) {
                          _vm.$set(_vm.data.health_info.condition_questions[index1].conditions[index2], "d_last_seen", $$v);
                        },
                        expression: "data.health_info.condition_questions[index1].conditions[index2].d_last_seen"
                      }
                    }), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v(_vm._s(errors[0]))])];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3"
              }, [_c('ValidationProvider', {
                attrs: {
                  "rules": "required",
                  "custom-messages": _vm.getErrorMessages('date_of_onset')
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref14) {
                    var errors = _ref14.errors;
                    return [_c('label', {
                      attrs: {
                        "for": "date_of_onset"
                      }
                    }, [_vm._v(" Date of Onset "), _c('span', {
                      staticClass: "required"
                    }, [_vm._v("*")])]), _c('date-picker', {
                      attrs: {
                        "input-class": "info-field",
                        "placeholder": "",
                        "type": "date",
                        "id": "date_of_onset",
                        "value-type": "format",
                        "format": "MM/DD/YYYY"
                      },
                      model: {
                        value: _vm.data.health_info.condition_questions[index1].conditions[index2].date_of_onset,
                        callback: function callback($$v) {
                          _vm.$set(_vm.data.health_info.condition_questions[index1].conditions[index2], "date_of_onset", $$v);
                        },
                        expression: "data.health_info.condition_questions[index1].conditions[index2].date_of_onset"
                      }
                    }), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v(_vm._s(errors[0]))])];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3"
              }, [_c('ValidationProvider', {
                attrs: {
                  "rules": "required",
                  "custom-messages": _vm.getErrorMessages('date_of_recovery')
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref15) {
                    var errors = _ref15.errors;
                    return [_c('label', {
                      attrs: {
                        "for": "date_of_recovery"
                      }
                    }, [_vm._v(" Date of recovery "), _c('span', {
                      staticClass: "required"
                    }, [_vm._v("*")])]), _c('date-picker', {
                      attrs: {
                        "input-class": "info-field",
                        "placeholder": "",
                        "type": "date",
                        "id": "date_of_recovery",
                        "value-type": "format",
                        "format": "MM/DD/YYYY"
                      },
                      model: {
                        value: _vm.data.health_info.condition_questions[index1].conditions[index2].date_of_recovery,
                        callback: function callback($$v) {
                          _vm.$set(_vm.data.health_info.condition_questions[index1].conditions[index2], "date_of_recovery", $$v);
                        },
                        expression: "data.health_info.condition_questions[index1].conditions[index2].date_of_recovery"
                      }
                    }), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v(_vm._s(errors[0]))])];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3"
              }, [_c('ValidationProvider', {
                attrs: {
                  "rules": "required",
                  "custom-messages": _vm.getErrorMessages('symptoms')
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref16) {
                    var errors = _ref16.errors;
                    return [_c('label', {
                      attrs: {
                        "for": "symptoms"
                      }
                    }, [_vm._v(" Symptoms "), _c('span', {
                      staticClass: "required"
                    }, [_vm._v("*")])]), _c('input', {
                      directives: [{
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.health_info.condition_questions[index1].conditions[index2].symptoms,
                        expression: "data.health_info.condition_questions[index1].conditions[index2].symptoms"
                      }],
                      staticClass: "info-field",
                      attrs: {
                        "id": "symptoms"
                      },
                      domProps: {
                        "value": _vm.data.health_info.condition_questions[index1].conditions[index2].symptoms
                      },
                      on: {
                        "input": function input($event) {
                          if ($event.target.composing) { return; }

                          _vm.$set(_vm.data.health_info.condition_questions[index1].conditions[index2], "symptoms", $event.target.value);
                        }
                      }
                    }), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v(_vm._s(errors[0]))])];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "form-check ques-check col-xxl-2 col-xl-2 col-lg-3 col-md-6 mb-3"
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.health_info.condition_questions[index1].conditions[index2].is_medicate,
                  expression: "data.health_info.condition_questions[index1].conditions[index2].is_medicate"
                }],
                staticClass: "form-check-input",
                attrs: {
                  "type": "checkbox",
                  "id": "is_medicate",
                  "true-value": "1",
                  "false-value": "0"
                },
                domProps: {
                  "checked": Array.isArray(_vm.data.health_info.condition_questions[index1].conditions[index2].is_medicate) ? _vm._i(_vm.data.health_info.condition_questions[index1].conditions[index2].is_medicate, null) > -1 : _vm._q(_vm.data.health_info.condition_questions[index1].conditions[index2].is_medicate, "1")
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.data.health_info.condition_questions[index1].conditions[index2].is_medicate,
                        $$el = $event.target,
                        $$c = $$el.checked ? "1" : "0";

                    if (Array.isArray($$a)) {
                      var $$v = null,
                          $$i = _vm._i($$a, $$v);

                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.data.health_info.condition_questions[index1].conditions[index2], "is_medicate", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.data.health_info.condition_questions[index1].conditions[index2], "is_medicate", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.data.health_info.condition_questions[index1].conditions[index2], "is_medicate", $$c);
                    }
                  }
                }
              }), _c('label', {
                staticClass: "form-check-label",
                attrs: {
                  "for": "is_medicate"
                }
              }, [_vm._v(" Taking Medication ")])]), _c('div', {
                staticClass: "form-check ques-check col-xxl-2 col-xl-2 col-lg-3 col-md-6 mb-3"
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.health_info.condition_questions[index1].conditions[index2].is_treatment,
                  expression: "data.health_info.condition_questions[index1].conditions[index2].is_treatment"
                }],
                staticClass: "form-check-input",
                attrs: {
                  "type": "checkbox",
                  "id": "is_treatment",
                  "true-value": "1",
                  "false-value": "0"
                },
                domProps: {
                  "checked": Array.isArray(_vm.data.health_info.condition_questions[index1].conditions[index2].is_treatment) ? _vm._i(_vm.data.health_info.condition_questions[index1].conditions[index2].is_treatment, null) > -1 : _vm._q(_vm.data.health_info.condition_questions[index1].conditions[index2].is_treatment, "1")
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.data.health_info.condition_questions[index1].conditions[index2].is_treatment,
                        $$el = $event.target,
                        $$c = $$el.checked ? "1" : "0";

                    if (Array.isArray($$a)) {
                      var $$v = null,
                          $$i = _vm._i($$a, $$v);

                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.data.health_info.condition_questions[index1].conditions[index2], "is_treatment", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.data.health_info.condition_questions[index1].conditions[index2], "is_treatment", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.data.health_info.condition_questions[index1].conditions[index2], "is_treatment", $$c);
                    }
                  }
                }
              }), _c('label', {
                staticClass: "form-check-label",
                attrs: {
                  "for": "is_treatment"
                }
              }, [_vm._v(" Current Treatment ")])])]);
            })], 2) : _vm._e()]);
          }), _c('div', {
            staticClass: "split-list info-wrap mx-2"
          }, [_c('p', [_vm._v(_vm._s(_vm.data.health_info.medication_question.question_text))]), _c('div', {
            staticClass: "split-yn-div"
          }, [_c('div', {
            staticClass: "form-check form-check-inline"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.data.health_info.medication_question.selected_answer,
              expression: "data.health_info.medication_question.selected_answer"
            }],
            staticClass: "form-check-input",
            attrs: {
              "type": "radio",
              "name": 'medication-question' + _vm.data.health_info.medication_question.question_id,
              "id": 'medication-question' + _vm.data.health_info.medication_question.question_id,
              "value": "1"
            },
            domProps: {
              "checked": _vm._q(_vm.data.health_info.medication_question.selected_answer, "1")
            },
            on: {
              "click": function click($event) {
                return _vm.addMedications(_vm.data.health_info.medication_question);
              },
              "change": function change($event) {
                return _vm.$set(_vm.data.health_info.medication_question, "selected_answer", "1");
              }
            }
          }), _c('label', {
            staticClass: "form-check-label",
            attrs: {
              "for": "inlineRadio1"
            }
          }, [_vm._v("Yes")])]), _c('div', {
            staticClass: "form-check form-check-inline"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.data.health_info.medication_question.selected_answer,
              expression: "data.health_info.medication_question.selected_answer"
            }],
            staticClass: "form-check-input",
            attrs: {
              "type": "radio",
              "name": 'medication-question' + _vm.data.health_info.medication_question.question_id,
              "id": 'medication-question' + _vm.data.health_info.medication_question.question_id,
              "value": "0"
            },
            domProps: {
              "checked": _vm._q(_vm.data.health_info.medication_question.selected_answer, "0")
            },
            on: {
              "click": function click($event) {
                return _vm.removeAllMedications(_vm.data.health_info.medication_question);
              },
              "change": function change($event) {
                return _vm.$set(_vm.data.health_info.medication_question, "selected_answer", "0");
              }
            }
          }), _c('label', {
            staticClass: "form-check-label",
            attrs: {
              "for": "inlineRadio2"
            }
          }, [_vm._v("No")])])]), _vm.data.health_info.medication_question.selected_answer == 1 ? _c('div', [_c('h6', [_vm._v("Medications")]), _vm._l(_vm.data.health_info.medication_question.medications, function (medication, index4) {
            return _c('div', {
              key: index4 + 'sdcasc',
              staticClass: "row mb-3"
            }, [_c('div', {
              staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12"
            }, [_c('label', [_vm._v("Medication/Rx injection"), _c('span', {
              staticClass: "required"
            }, [_vm._v("*")])]), _c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('medication')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref17) {
                  var errors = _ref17.errors;
                  return [_c('input', {
                    directives: [{
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.health_info.medication_question.medications[index4].medication,
                      expression: "data.health_info.medication_question.medications[index4].medication"
                    }],
                    staticClass: "info-field",
                    attrs: {
                      "id": 'medication' + index4
                    },
                    domProps: {
                      "value": _vm.data.health_info.medication_question.medications[index4].medication
                    },
                    on: {
                      "input": function input($event) {
                        if ($event.target.composing) { return; }

                        _vm.$set(_vm.data.health_info.medication_question.medications[index4], "medication", $event.target.value);
                      }
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1), _c('div', {
              staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12"
            }, [_c('label', [_vm._v("Dosage"), _c('span', {
              staticClass: "required"
            }, [_vm._v("*")])]), _c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('dosage')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref18) {
                  var errors = _ref18.errors;
                  return [_c('input', {
                    directives: [{
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.health_info.medication_question.medications[index4].dosage,
                      expression: "data.health_info.medication_question.medications[index4].dosage"
                    }],
                    staticClass: "info-field",
                    attrs: {
                      "id": 'dosage' + index4
                    },
                    domProps: {
                      "value": _vm.data.health_info.medication_question.medications[index4].dosage
                    },
                    on: {
                      "input": function input($event) {
                        if ($event.target.composing) { return; }

                        _vm.$set(_vm.data.health_info.medication_question.medications[index4], "dosage", $event.target.value);
                      }
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1), _c('div', {
              staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12"
            }, [_c('label', [_vm._v("Medical Condition"), _c('span', {
              staticClass: "required"
            }, [_vm._v("*")])]), _c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('medical_condition')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref19) {
                  var errors = _ref19.errors;
                  return [_c('input', {
                    directives: [{
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.health_info.medication_question.medications[index4].medical_condition,
                      expression: "data.health_info.medication_question.medications[index4].medical_condition"
                    }],
                    staticClass: "info-field",
                    attrs: {
                      "id": 'medical_condition' + index4
                    },
                    domProps: {
                      "value": _vm.data.health_info.medication_question.medications[index4].medical_condition
                    },
                    on: {
                      "input": function input($event) {
                        if ($event.target.composing) { return; }

                        _vm.$set(_vm.data.health_info.medication_question.medications[index4], "medical_condition", $event.target.value);
                      }
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1), _c('div', {
              staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 align-center"
            }, [_vm.data.health_info.medication_question.medications.length > 1 ? _c('span', {
              staticClass: "remove-medication",
              on: {
                "click": function click($event) {
                  return _vm.removeMedication(_vm.data.health_info.medication_question, index4);
                }
              }
            }, [_c('i', {
              staticClass: "far fa-times-circle"
            }), _vm._v(" Remove ")]) : _vm._e()])]);
          }), _vm.data.health_info.medication_question.medications.length < 3 ? _c('div', {
            staticClass: "row mt-2"
          }, [_c('div', {
            staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
          }, [_c('b-button', {
            staticClass: "univ-btn",
            attrs: {
              "variant": "success"
            },
            on: {
              "click": function click($event) {
                return _vm.addMedications(_vm.data.health_info.medication_question);
              }
            }
          }, [_vm._v("+ Add Medications")])], 1)]) : _vm._e()], 2) : _vm._e()]), _c('div', {
            staticClass: "form-group mx-2"
          }, [_c('label', {
            attrs: {
              "for": ""
            }
          }, [_vm._v("Additional Notes")]), _c('textarea', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.data.health_info.notes,
              expression: "data.health_info.notes"
            }],
            staticClass: "info-field",
            attrs: {
              "name": "",
              "id": "",
              "rows": "4",
              "placeholder": "Enter additional notes"
            },
            domProps: {
              "value": _vm.data.health_info.notes
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) { return; }

                _vm.$set(_vm.data.health_info, "notes", $event.target.value);
              }
            }
          }), _c('span', {
            staticClass: "note-text"
          }, [_vm._v("*Please note, any false information, or information not given may result in termination of eligibility for coverage under the Plan or denial of benefits under the Plan.*")])])], 2)], 1)], 1)])]) : _vm._e(), _c('div', {
            staticClass: "form-group text-center"
          }, [_c('b-button', {
            staticClass: "mt-3 univ-btn",
            attrs: {
              "variant": "info",
              "type": "button"
            },
            on: {
              "click": function click($event) {
                handleSubmit(_vm.saveDependent(index));
              }
            }
          }, [_vm._v(_vm._s(_vm.isValidating ? 'Validating' : 'Submit'))])], 1)])], 1)];
        }
      }], null, true)
    })], 1);
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_vm.showAddDependent && _vm.isAddMoreDepValidForIHA ? _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.add-dependent",
      modifiers: {
        "add-dependent": true
      }
    }],
    attrs: {
      "variant": "success univ-btn"
    },
    on: {
      "click": _vm.createDependent
    }
  }, [_vm._v("+ Add Dependent")]) : _vm._e()], 1)]), _c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var handleSubmit = _ref20.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.addDependent);
            }
          }
        }, [_c('b-modal', {
          ref: "add-dependent",
          attrs: {
            "id": "add-dependent",
            "size": "xl",
            "title": 'Dependent #' + (parseInt(_vm.index) + 1),
            "ok-disabled": true
          },
          on: {
            "hidden": _vm.resetModal
          }
        }, [_vm.form.primary_info ? _c('div', {
          staticClass: "info-form mb-4"
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_vm.form.primary_info.first_name ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.primary_info.first_name),
            "custom-messages": _vm.getErrorMessages('first_name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref21) {
              var errors = _ref21.errors;
              return [_c('label', {
                attrs: {
                  "for": "first_name"
                }
              }, [_vm._v(" First Name "), _vm.form.primary_info.first_name.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.primary_info.first_name,
                  expression: "data.primary_info.first_name"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "first_name"
                },
                domProps: {
                  "value": _vm.data.primary_info.first_name
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data.primary_info, "first_name", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.primary_info.middle_name ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.primary_info.middle_name),
            "custom-messages": _vm.getErrorMessages('middle_name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref22) {
              var errors = _ref22.errors;
              return [_c('label', {
                attrs: {
                  "for": "middle_name"
                }
              }, [_vm._v(" Middle Name "), _vm.form.primary_info.middle_name.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.primary_info.middle_name,
                  expression: "data.primary_info.middle_name"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "middle_name"
                },
                domProps: {
                  "value": _vm.data.primary_info.middle_name
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data.primary_info, "middle_name", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.primary_info.last_name ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.primary_info.last_name),
            "custom-messages": _vm.getErrorMessages('last_name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref23) {
              var errors = _ref23.errors;
              return [_c('label', {
                attrs: {
                  "for": "last_name"
                }
              }, [_vm._v(" Last Name "), _vm.form.primary_info.last_name.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.primary_info.last_name,
                  expression: "data.primary_info.last_name"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "last_name"
                },
                domProps: {
                  "value": _vm.data.primary_info.last_name
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data.primary_info, "last_name", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.primary_info.gender ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.primary_info.gender),
            "custom-messages": _vm.getErrorMessages('gender')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref24) {
              var errors = _ref24.errors;
              return [_c('label', {
                attrs: {
                  "for": "gender"
                }
              }, [_vm._v(" Gender "), _vm.form.primary_info.gender.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.primary_info.gender,
                  expression: "data.primary_info.gender"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "gender"
                },
                on: {
                  "change": function change($event) {
                    var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                      return o.selected;
                    }).map(function (o) {
                      var val = "_value" in o ? o._value : o.value;
                      return val;
                    });

                    _vm.$set(_vm.data.primary_info, "gender", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                  }
                }
              }, [_c('option', {
                attrs: {
                  "value": "0"
                }
              }, [_vm._v("Male")]), _c('option', {
                attrs: {
                  "value": "1"
                }
              }, [_vm._v("Female")])]), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.primary_info.birthdate ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.primary_info.birthdate),
            "custom-messages": _vm.getErrorMessages('birthdate')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref25) {
              var errors = _ref25.errors;
              return [_c('label', {
                attrs: {
                  "for": "birthdate"
                }
              }, [_vm._v(" Birthdate "), _vm.form.primary_info.birthdate.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('date-picker', {
                attrs: {
                  "input-class": "info-field",
                  "placeholder": "",
                  "type": "date",
                  "id": "birthdate",
                  "value-type": "format",
                  "format": "MM/DD/YYYY"
                },
                model: {
                  value: _vm.data.primary_info.birthdate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.data.primary_info, "birthdate", $$v);
                  },
                  expression: "data.primary_info.birthdate"
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.primary_info.ssn ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.primary_info.ssn),
            "custom-messages": _vm.getErrorMessages('ssn')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref26) {
              var errors = _ref26.errors;
              return [_c('label', {
                attrs: {
                  "for": "ssn"
                }
              }, [_vm._v(" SSN "), _vm.form.primary_info.ssn.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.primary_info.ssn,
                  expression: "data.primary_info.ssn"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "ssn",
                  "maxlength": "9"
                },
                domProps: {
                  "value": _vm.data.primary_info.ssn
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data.primary_info, "ssn", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.primary_info.relationship ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.primary_info.relationship),
            "custom-messages": _vm.getErrorMessages('relationship')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref27) {
              var errors = _ref27.errors;
              return [_c('label', {
                attrs: {
                  "for": "relation"
                }
              }, [_vm._v(" Relationship "), _vm.form.primary_info.relationship.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.primary_info.relationship,
                  expression: "data.primary_info.relationship"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "relation"
                },
                on: {
                  "change": function change($event) {
                    var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                      return o.selected;
                    }).map(function (o) {
                      var val = "_value" in o ? o._value : o.value;
                      return val;
                    });

                    _vm.$set(_vm.data.primary_info, "relationship", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                  }
                }
              }, _vm._l(_vm.form.primary_info.relationship.options, function (option, index1) {
                return _c('option', {
                  key: index1,
                  attrs: {
                    "id": option.index1,
                    "disabled": _vm.disableRelationshipOption(option)
                  },
                  domProps: {
                    "value": option.value
                  }
                }, [_vm._v(" " + _vm._s(option.name) + " ")]);
              }), 0), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.primary_info.height_feet ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.primary_info.height_feet),
            "custom-messages": _vm.getErrorMessages('height_feet')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref28) {
              var errors = _ref28.errors;
              return [_c('label', {
                attrs: {
                  "for": "height_feet"
                }
              }, [_vm._v(" Height(feet) "), _vm.form.primary_info.height_feet.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.primary_info.height_feet,
                  expression: "data.primary_info.height_feet"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "height_feet"
                },
                domProps: {
                  "value": _vm.data.primary_info.height_feet
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data.primary_info, "height_feet", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.primary_info.height_inch ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.primary_info.height_inch),
            "custom-messages": _vm.getErrorMessages('height_inch')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref29) {
              var errors = _ref29.errors;
              return [_c('label', {
                attrs: {
                  "for": "height_inch"
                }
              }, [_vm._v(" Height(inch) "), _vm.form.primary_info.height_inch.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.primary_info.height_inch,
                  expression: "data.primary_info.height_inch"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "height_inch"
                },
                domProps: {
                  "value": _vm.data.primary_info.height_inch
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data.primary_info, "height_inch", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.primary_info.weight ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.primary_info.weight),
            "custom-messages": _vm.getErrorMessages('weight')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref30) {
              var errors = _ref30.errors;
              return [_c('label', {
                attrs: {
                  "for": "weight"
                }
              }, [_vm._v(" Weight(lbs) "), _vm.form.primary_info.weight.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.primary_info.weight,
                  expression: "data.primary_info.weight"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "weight"
                },
                domProps: {
                  "value": _vm.data.primary_info.weight
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data.primary_info, "weight", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e()])]) : _vm._e(), _vm.data.health_info ? _c('div', {
          staticClass: "info-form mt-4 row"
        }, [_c('div', {
          staticClass: "question-accordion row"
        }, [_c('div', {
          staticClass: "col-md-12"
        }, [_c('figure', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }]
        }, [_vm._v("Health Questionaire "), _c('i', {
          staticClass: "accordion-icon",
          class: _vm.visible ? 'fa fa-angle-up' : 'collapsed fa fa-angle-down',
          attrs: {
            "aria-expanded": _vm.visible ? 'true' : 'false',
            "aria-controls": "collapse-4"
          },
          on: {
            "click": function click($event) {
              _vm.visible = !_vm.visible;
            }
          }
        })]), _c('b-collapse', {
          attrs: {
            "id": "collapse-1"
          },
          model: {
            value: _vm.visible,
            callback: function callback($$v) {
              _vm.visible = $$v;
            },
            expression: "visible"
          }
        }, [_c('b-card', [_vm._l(_vm.data.health_info.condition_questions, function (question, index5) {
          return _c('div', {
            key: index5 + 'ascsac',
            staticClass: "mx-2"
          }, [_c('div', {
            staticClass: "split-list"
          }, [_c('p', [_vm._v(_vm._s(question.question_text))]), _c('div', {
            staticClass: "split-yn-div"
          }, [_c('div', {
            staticClass: "form-check form-check-inline"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.data.health_info.condition_questions[index5].selected_answer,
              expression: "data.health_info.condition_questions[index5].selected_answer"
            }],
            staticClass: "form-check-input",
            attrs: {
              "type": "radio",
              "name": 'condition-question' + question.question_id,
              "id": index5,
              "value": "1"
            },
            domProps: {
              "checked": _vm._q(_vm.data.health_info.condition_questions[index5].selected_answer, "1")
            },
            on: {
              "click": function click($event) {
                return _vm.addCondition(question);
              },
              "change": function change($event) {
                return _vm.$set(_vm.data.health_info.condition_questions[index5], "selected_answer", "1");
              }
            }
          }), _c('label', {
            staticClass: "form-check-label",
            attrs: {
              "for": "inlineRadio1"
            }
          }, [_vm._v("Yes")])]), _c('div', {
            staticClass: "form-check form-check-inline"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.data.health_info.condition_questions[index5].selected_answer,
              expression: "data.health_info.condition_questions[index5].selected_answer"
            }],
            staticClass: "form-check-input",
            attrs: {
              "type": "radio",
              "name": 'condition-question' + question.question_id,
              "id": index5,
              "value": "0"
            },
            domProps: {
              "checked": _vm._q(_vm.data.health_info.condition_questions[index5].selected_answer, "0")
            },
            on: {
              "click": function click($event) {
                return _vm.removeAllConditions(question);
              },
              "change": function change($event) {
                return _vm.$set(_vm.data.health_info.condition_questions[index5], "selected_answer", "0");
              }
            }
          }), _c('label', {
            staticClass: "form-check-label",
            attrs: {
              "for": "inlineRadio2"
            }
          }, [_vm._v("No")])])])]), question.selected_answer == 1 ? _c('div', [_c('h6', [_vm._v("Conditions")]), _vm._l(question.conditions, function (condition, index6) {
            return _c('div', {
              key: index6 + 'dsacd',
              staticClass: "row mx-2"
            }, [_c('div', {
              staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
            }, [_c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('health_condition')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref31) {
                  var errors = _ref31.errors;
                  return [_c('label', {
                    attrs: {
                      "for": "health_condition"
                    }
                  }, [_vm._v(" Health Condition "), _c('span', {
                    staticClass: "required"
                  }, [_vm._v("*")])]), _c('input', {
                    directives: [{
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.health_info.condition_questions[index5].conditions[index6].health_condition,
                      expression: "data.health_info.condition_questions[index5].conditions[index6].health_condition"
                    }],
                    staticClass: "info-field",
                    attrs: {
                      "id": "health_condition"
                    },
                    domProps: {
                      "value": _vm.data.health_info.condition_questions[index5].conditions[index6].health_condition
                    },
                    on: {
                      "input": function input($event) {
                        if ($event.target.composing) { return; }

                        _vm.$set(_vm.data.health_info.condition_questions[index5].conditions[index6], "health_condition", $event.target.value);
                      }
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1), _c('div', {
              staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
            }, [_c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('doctor_last_seen')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref32) {
                  var errors = _ref32.errors;
                  return [_c('label', {
                    attrs: {
                      "for": "d_last_seen"
                    }
                  }, [_vm._v(" Last seen by Doctor "), _c('span', {
                    staticClass: "required"
                  }, [_vm._v("*")])]), _c('date-picker', {
                    attrs: {
                      "input-class": "info-field",
                      "placeholder": "",
                      "type": "date",
                      "id": "d_last_seen",
                      "value-type": "format",
                      "format": "MM/DD/YYYY"
                    },
                    model: {
                      value: _vm.data.health_info.condition_questions[index5].conditions[index6].d_last_seen,
                      callback: function callback($$v) {
                        _vm.$set(_vm.data.health_info.condition_questions[index5].conditions[index6], "d_last_seen", $$v);
                      },
                      expression: "data.health_info.condition_questions[index5].conditions[index6].d_last_seen"
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1), _c('div', {
              staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
            }, [_c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('date_of_onset')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref33) {
                  var errors = _ref33.errors;
                  return [_c('label', {
                    attrs: {
                      "for": "date_of_onset"
                    }
                  }, [_vm._v(" Date of Onset "), _c('span', {
                    staticClass: "required"
                  }, [_vm._v("*")])]), _c('date-picker', {
                    attrs: {
                      "input-class": "info-field",
                      "placeholder": "",
                      "type": "date",
                      "id": "date_of_onset",
                      "value-type": "format",
                      "format": "MM/DD/YYYY"
                    },
                    model: {
                      value: _vm.data.health_info.condition_questions[index5].conditions[index6].date_of_onset,
                      callback: function callback($$v) {
                        _vm.$set(_vm.data.health_info.condition_questions[index5].conditions[index6], "date_of_onset", $$v);
                      },
                      expression: "data.health_info.condition_questions[index5].conditions[index6].date_of_onset"
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1), _c('div', {
              staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
            }, [_c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('date_of_recovery')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref34) {
                  var errors = _ref34.errors;
                  return [_c('label', {
                    attrs: {
                      "for": "date_of_recovery"
                    }
                  }, [_vm._v(" Date of recovery "), _c('span', {
                    staticClass: "required"
                  }, [_vm._v("*")])]), _c('date-picker', {
                    attrs: {
                      "input-class": "info-field",
                      "placeholder": "",
                      "type": "date",
                      "id": "date_of_recovery",
                      "value-type": "format",
                      "format": "MM/DD/YYYY"
                    },
                    model: {
                      value: _vm.data.health_info.condition_questions[index5].conditions[index6].date_of_recovery,
                      callback: function callback($$v) {
                        _vm.$set(_vm.data.health_info.condition_questions[index5].conditions[index6], "date_of_recovery", $$v);
                      },
                      expression: "data.health_info.condition_questions[index5].conditions[index6].date_of_recovery"
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1), _c('div', {
              staticClass: "form-check col-xxl-6 col-lg-6 col-md-6 mb-3"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.data.health_info.condition_questions[index5].conditions[index6].is_medicate,
                expression: "data.health_info.condition_questions[index5].conditions[index6].is_medicate"
              }],
              staticClass: "form-check-input",
              attrs: {
                "type": "checkbox",
                "id": "is_medicate",
                "true-value": "1",
                "false-value": "0"
              },
              domProps: {
                "checked": Array.isArray(_vm.data.health_info.condition_questions[index5].conditions[index6].is_medicate) ? _vm._i(_vm.data.health_info.condition_questions[index5].conditions[index6].is_medicate, null) > -1 : _vm._q(_vm.data.health_info.condition_questions[index5].conditions[index6].is_medicate, "1")
              },
              on: {
                "change": function change($event) {
                  var $$a = _vm.data.health_info.condition_questions[index5].conditions[index6].is_medicate,
                      $$el = $event.target,
                      $$c = $$el.checked ? "1" : "0";

                  if (Array.isArray($$a)) {
                    var $$v = null,
                        $$i = _vm._i($$a, $$v);

                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.data.health_info.condition_questions[index5].conditions[index6], "is_medicate", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(_vm.data.health_info.condition_questions[index5].conditions[index6], "is_medicate", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(_vm.data.health_info.condition_questions[index5].conditions[index6], "is_medicate", $$c);
                  }
                }
              }
            }), _c('label', {
              staticClass: "form-check-label",
              attrs: {
                "for": "flexCheckDefault"
              }
            }, [_vm._v(" Taking Medication ")])]), _c('div', {
              staticClass: "form-check col-xxl-6 col-lg-6 col-md-6 mb-3"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.data.health_info.condition_questions[index5].conditions[index6].is_treatment,
                expression: "data.health_info.condition_questions[index5].conditions[index6].is_treatment"
              }],
              staticClass: "form-check-input",
              attrs: {
                "type": "checkbox",
                "id": "is_treatment",
                "true-value": "1",
                "false-value": "0"
              },
              domProps: {
                "checked": Array.isArray(_vm.data.health_info.condition_questions[index5].conditions[index6].is_treatment) ? _vm._i(_vm.data.health_info.condition_questions[index5].conditions[index6].is_treatment, null) > -1 : _vm._q(_vm.data.health_info.condition_questions[index5].conditions[index6].is_treatment, "1")
              },
              on: {
                "change": function change($event) {
                  var $$a = _vm.data.health_info.condition_questions[index5].conditions[index6].is_treatment,
                      $$el = $event.target,
                      $$c = $$el.checked ? "1" : "0";

                  if (Array.isArray($$a)) {
                    var $$v = null,
                        $$i = _vm._i($$a, $$v);

                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.data.health_info.condition_questions[index5].conditions[index6], "is_treatment", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(_vm.data.health_info.condition_questions[index5].conditions[index6], "is_treatment", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(_vm.data.health_info.condition_questions[index5].conditions[index6], "is_treatment", $$c);
                  }
                }
              }
            }), _c('label', {
              staticClass: "form-check-label",
              attrs: {
                "for": "flexCheckDefault"
              }
            }, [_vm._v(" Current Treatment ")])]), _c('div', {
              staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
            }, [_c('ValidationProvider', {
              attrs: {
                "rules": "required",
                "custom-messages": _vm.getErrorMessages('symptoms')
              },
              scopedSlots: _vm._u([{
                key: "default",
                fn: function fn(_ref35) {
                  var errors = _ref35.errors;
                  return [_c('label', {
                    attrs: {
                      "for": "symptoms"
                    }
                  }, [_vm._v(" Symptoms "), _c('span', {
                    staticClass: "required"
                  }, [_vm._v("*")])]), _c('input', {
                    directives: [{
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.health_info.condition_questions[index5].conditions[index6].symptoms,
                      expression: "data.health_info.condition_questions[index5].conditions[index6].symptoms"
                    }],
                    staticClass: "info-field",
                    attrs: {
                      "id": "symptoms"
                    },
                    domProps: {
                      "value": _vm.data.health_info.condition_questions[index5].conditions[index6].symptoms
                    },
                    on: {
                      "input": function input($event) {
                        if ($event.target.composing) { return; }

                        _vm.$set(_vm.data.health_info.condition_questions[index5].conditions[index6], "symptoms", $event.target.value);
                      }
                    }
                  }), _c('span', {
                    staticClass: "text-danger"
                  }, [_vm._v(_vm._s(errors[0]))])];
                }
              }], null, true)
            })], 1)]);
          })], 2) : _vm._e()]);
        }), _c('div', {
          staticClass: "split-list"
        }, [_c('p', [_vm._v(_vm._s(_vm.data.health_info.medication_question.question_text))]), _c('div', {
          staticClass: "split-yn-div"
        }, [_c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.data.health_info.medication_question.selected_answer,
            expression: "data.health_info.medication_question.selected_answer"
          }],
          staticClass: "form-check-input",
          attrs: {
            "type": "radio",
            "name": 'medication-question' + _vm.data.health_info.medication_question.question_id,
            "id": 'medication-question' + _vm.data.health_info.medication_question.question_id,
            "value": "1"
          },
          domProps: {
            "checked": _vm._q(_vm.data.health_info.medication_question.selected_answer, "1")
          },
          on: {
            "click": function click($event) {
              return _vm.addMedications(_vm.data.health_info.medication_question);
            },
            "change": function change($event) {
              return _vm.$set(_vm.data.health_info.medication_question, "selected_answer", "1");
            }
          }
        }), _c('label', {
          staticClass: "form-check-label",
          attrs: {
            "for": "inlineRadio1"
          }
        }, [_vm._v("Yes")])]), _c('div', {
          staticClass: "form-check form-check-inline"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.data.health_info.medication_question.selected_answer,
            expression: "data.health_info.medication_question.selected_answer"
          }],
          staticClass: "form-check-input",
          attrs: {
            "type": "radio",
            "name": 'medicaiton-question' + _vm.data.health_info.medication_question.question_id,
            "id": 'medication-question' + _vm.data.health_info.medication_question.question_id,
            "value": "0"
          },
          domProps: {
            "checked": _vm._q(_vm.data.health_info.medication_question.selected_answer, "0")
          },
          on: {
            "click": function click($event) {
              return _vm.removeAllMedications(_vm.data.health_info.medication_question);
            },
            "change": function change($event) {
              return _vm.$set(_vm.data.health_info.medication_question, "selected_answer", "0");
            }
          }
        }), _c('label', {
          staticClass: "form-check-label",
          attrs: {
            "for": "inlineRadio2"
          }
        }, [_vm._v("No")])])]), _vm.data.health_info.medication_question.selected_answer == 1 ? _c('div', [_c('h6', [_vm._v("Medications")]), _c('table', [_c('thead', [_c('tr', [_c('th', [_vm._v("Medication/Rx injection"), _c('span', {
          staticClass: "required"
        }, [_vm._v("*")])]), _c('th', [_vm._v("Dosage"), _c('span', {
          staticClass: "required"
        }, [_vm._v("*")])]), _c('th', [_vm._v("Medical Condition"), _c('span', {
          staticClass: "required"
        }, [_vm._v("*")])])])]), _c('tbody', _vm._l(_vm.data.health_info.medication_question.medications, function (medication, index8) {
          return _c('tr', {
            key: index8 + 'retrty'
          }, [_c('td', [_c('ValidationProvider', {
            attrs: {
              "rules": "required",
              "custom-messages": _vm.getErrorMessages('medication')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref36) {
                var errors = _ref36.errors;
                return [_c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.health_info.medication_question.medications[index8].medication,
                    expression: "data.health_info.medication_question.medications[index8].medication"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": 'medication' + index8
                  },
                  domProps: {
                    "value": _vm.data.health_info.medication_question.medications[index8].medication
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data.health_info.medication_question.medications[index8], "medication", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1), _c('td', [_c('ValidationProvider', {
            attrs: {
              "rules": "required",
              "custom-messages": _vm.getErrorMessages('dosage')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref37) {
                var errors = _ref37.errors;
                return [_c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.health_info.medication_question.medications[index8].dosage,
                    expression: "data.health_info.medication_question.medications[index8].dosage"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": 'dosage' + index8
                  },
                  domProps: {
                    "value": _vm.data.health_info.medication_question.medications[index8].dosage
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data.health_info.medication_question.medications[index8], "dosage", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1), _c('td', [_c('ValidationProvider', {
            attrs: {
              "rules": "required",
              "custom-messages": _vm.getErrorMessages('medical_condition')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref38) {
                var errors = _ref38.errors;
                return [_c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.health_info.medication_question.medications[index8].medical_condition,
                    expression: "data.health_info.medication_question.medications[index8].medical_condition"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": 'medical_condition' + index8
                  },
                  domProps: {
                    "value": _vm.data.health_info.medication_question.medications[index8].medical_condition
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data.health_info.medication_question.medications[index8], "medical_condition", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1), _c('td', [_vm.data.health_info.medication_question.medications.length > 1 ? _c('span', {
            on: {
              "click": function click($event) {
                return _vm.removeMedication(_vm.data.health_info.medication_question, index8);
              }
            }
          }, [_c('b-button', {
            attrs: {
              "pill": "",
              "variant": "danger"
            }
          }, [_vm._v("- Remove")])], 1) : _vm._e()])]);
        }), 0)]), _vm.data.health_info.medication_question.medications.length < 3 ? _c('div', {
          staticClass: "row mt-2"
        }, [_c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('b-button', {
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.addMedications(_vm.data.health_info.medication_question);
            }
          }
        }, [_vm._v("+ Add Medications")])], 1)]) : _vm._e()]) : _vm._e()]), _c('div', {
          staticClass: "form-group mx-2"
        }, [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Additional Notes")]), _c('textarea', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.data.health_info.notes,
            expression: "data.health_info.notes"
          }],
          staticClass: "info-field",
          attrs: {
            "name": "",
            "id": "",
            "rows": "4",
            "placeholder": "Enter additional notes"
          },
          domProps: {
            "value": _vm.data.health_info.notes
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(_vm.data.health_info, "notes", $event.target.value);
            }
          }
        }), _c('span', {
          staticClass: "note-text"
        }, [_vm._v("*Please note, any false information, or information not given may result in termination of eligibility for coverage under the Plan or denial of benefits under the Plan.*")])])], 2)], 1)], 1)])]) : _vm._e(), _c('div', {
          staticClass: "form-group text-center"
        }, [_c('b-button', {
          staticClass: "mt-3 univ-btn",
          attrs: {
            "variant": "info",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.addDependent);
            }
          }
        }, [_vm._v(_vm._s(_vm.isValidating ? 'Validating' : 'Submit'))])], 1)])], 1)];
      }
    }])
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, _vm._l(_vm.validateDependents, function (error, index) {
    return _c('span', {
      key: index + 'sacsc',
      staticClass: "text-danger"
    }, [_vm._v(" " + _vm._s(error) + " ")]);
  }), 0)]), _c('div', {
    staticClass: "row justify-content-end mt-3"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6"
  }, [_c('button', {
    staticClass: "univ-btn",
    staticStyle: {
      "max-width": "100% !important"
    },
    attrs: {
      "type": "button",
      "disabled": _vm.disableUpdate
    },
    on: {
      "click": _vm.updateDependentInfo
    }
  }, [_vm._v(_vm._s(_vm.isProcessing ? 'Processing' : 'Update'))])])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }