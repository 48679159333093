<template>
    <div class="info-wrap">
        <h2>Dependent Information</h2>
        <!-- <p>Enter and Update all your Dependent Information here</p> -->
        <div v-if="tiers.includes('IC')"><h6>Child information required </h6></div>
        <div v-if="tiers.includes('IS')"><h6>Spouse information required </h6></div>
        <div v-if="tiers.includes('IF')"><h6>Spouse & Child information required </h6></div>
        <div v-for="(dep, index) in postData" :key="index+'A'" :id="index">
            <h3>Dependent Details #{{ parseInt(index) + 1 }}</h3>
            <div class="row mb-3">
                <b-input-group :id="'dependent' + index">
                    <b-input-group-append class="split-group">
                        <b-form-input class="info-field" :value="getFullName(dep.primary_info)" disabled></b-form-input>
                        <b-button variant="primary edit" @click="editDependent(dep, index)" v-b-modal="'edit-dependent' + index">Edit</b-button>
                        <b-button variant="danger delete" @click="deleteDependent(index)" v-if="showDelete && dep.primary_info.relationship !== 's'">Delete</b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>

            <!-- edit dependent modal -->
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(saveDependent)" :ref="'edit_dependent_form' + index">
                    <b-modal :id="'edit-dependent' + index" :ref="'edit-dependent' + index" size="xl" :title="'Dependent #' + (parseInt(index) + 1)" @hidden="resetModal">
                        <!-- personal info -->
                        <div class="info-form mb-4" v-if="form.primary_info">
                            <div class="form-group row">
                                <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.first_name">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.first_name)" :custom-messages="getErrorMessages('first_name')">
                                        <label for="first_name">
                                            First Name
                                            <span v-if="form.primary_info.first_name.required" class="required">*</span>
                                        </label>
                                    <input class="info-field" v-model="data.primary_info.first_name"  id="first_name">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.middle_name">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.middle_name)" :custom-messages="getErrorMessages('middle_name')">
                                        <label for="middle_name">
                                            Middle Name
                                            <span v-if="form.primary_info.middle_name.required" class="required">*</span>
                                        </label>
                                    <input class="info-field" v-model="data.primary_info.middle_name"  id="middle_name">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.last_name">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.last_name)" :custom-messages="getErrorMessages('last_name')">
                                        <label for="last_name">
                                            Last Name
                                            <span v-if="form.primary_info.last_name.required" class="required">*</span>
                                        </label>
                                    <input class="info-field" v-model="data.primary_info.last_name"  id="last_name">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.gender">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.gender)" :custom-messages="getErrorMessages('gender')">
                                        <label for="gender">
                                            Gender
                                            <span v-if="form.primary_info.gender.required" class="required">*</span>
                                        </label>
                                        <select class="info-field" id="gender" v-model="data.primary_info.gender">
                                            <option value="0">Male</option>
                                            <option value="1">Female</option>
                                            <!-- <option v-for="(option, index1) in form.primary_info.gender.options" :key="index1" :id="option.index1" :value="option.value">
                                                {{ option.name }}
                                            </option> -->
                                        </select>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.birthdate">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.birthdate)" :custom-messages="getErrorMessages('birthdate')">
                                        <label for="birthdate">
                                            Birthdate
                                            <span v-if="form.primary_info.birthdate.required" class="required">*</span>
                                        </label>
                                        <date-picker input-class="info-field"
                                        placeholder=""
                                        type="date"
                                        id="birthdate"
                                        v-model="data.primary_info.birthdate"
                                        value-type="format"
                                        format="MM/DD/YYYY"></date-picker>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.ssn">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.ssn)" :custom-messages="getErrorMessages('ssn')">
                                        <label for="ssn">
                                            SSN
                                            <span v-if="form.primary_info.ssn.required" class="required">*</span>
                                        </label>
                                    <input class="info-field" v-model="data.primary_info.ssn" id="ssn" maxlength="9">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.relationship">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.relationship)" :custom-messages="getErrorMessages('relationship')">
                                        <label for="relation">
                                            Relationship
                                            <span v-if="form.primary_info.relationship.required" class="required">*</span>
                                        </label>
                                        <select class="info-field" id="relation" v-model="data.primary_info.relationship" disabled>
                                            <option
                                            v-for="(option, index1) in form.primary_info.relationship.options"
                                            :key="index1"
                                            :id="option.index1"
                                            :value="option.value"
                                            :disabled="disableRelationshipOption(option)"
                                            >
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.height_feet">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.height_feet)" :custom-messages="getErrorMessages('height_feet')">
                                        <label for="height_feet">
                                            Height(feet)
                                            <span v-if="form.primary_info.height_feet.required" class="required">*</span>
                                        </label>
                                    <input class="info-field" v-model="data.primary_info.height_feet"  id="height_feet">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.height_inch">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.height_inch)" :custom-messages="getErrorMessages('height_inch')">
                                        <label for="height_inch">
                                            Height(inch)
                                            <span v-if="form.primary_info.height_inch.required" class="required">*</span>
                                        </label>
                                    <input class="info-field" v-model="data.primary_info.height_inch"  id="height_inch">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.weight">
                                    <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.weight)" :custom-messages="getErrorMessages('weight')">
                                        <label for="weight">
                                            Weight(lbs)
                                            <span v-if="form.primary_info.weight.required" class="required">*</span>
                                        </label>
                                    <input class="info-field" v-model="data.primary_info.weight"  id="weight">
                                    <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <!-- health questions info -->
                        <div class="info-form mt-4" v-if="data.health_info">
                            <div class="question-accordion row">
                                <div class="col-md-12">
                                    <figure v-b-toggle.collapse-1>Health Questionaire
                                        <i
                                        class="accordion-icon"
                                        :class="visible ? 'fa fa-angle-up' : 'collapsed fa fa-angle-down'"
                                        :aria-expanded="visible ? 'true' : 'false'"
                                        aria-controls="collapse-4"
                                        @click="visible = !visible"
                                        />
                                    </figure>
                                    <b-collapse id="collapse-1" v-model="visible" class="">
                                        <b-card>
                                            <!-- condition questions -->
                                            <div v-for="(question, index1) in data.health_info.condition_questions" :key="index1+'B'" class="info-wrap mx-2">
                                                <div class="split-list">
                                                    <p>{{ question.question_text }}</p>
                                                    <div class="split-yn-div">
                                                        <div class="form-check form-check-inline">
                                                            <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            :name="'condition-question'+question.question_id"
                                                            :id="index1"
                                                            value=1
                                                            v-model="data.health_info.condition_questions[index1].selected_answer"
                                                            @click="addCondition(question)"
                                                            >
                                                            <label class="form-check-label" for="inlineRadio1">Yes</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            :name="'condition-question'+question.question_id"
                                                            :id="index1"
                                                            value=0
                                                            v-model="data.health_info.condition_questions[index1].selected_answer"
                                                            @click="removeAllConditions(question)">
                                                            <label class="form-check-label" for="inlineRadio2">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="question.selected_answer == 1">
                                                    <h6>Conditions</h6>
                                                    <div v-for="(condition, index2) in question.conditions" :key="index2+'DASd'" class="row">
                                                        <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-12 mb-3">
                                                            <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('health_condition')">
                                                                <label for="health_condition">
                                                                    Health Condition
                                                                    <span class="required">*</span>
                                                                </label>
                                                                <input class="info-field" v-model="data.health_info.condition_questions[index1].conditions[index2].health_condition"  id="health_condition">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-6 mb-3">
                                                            <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('doctor_last_seen')">
                                                                <label for="d_last_seen">
                                                                    Last seen by Doctor
                                                                    <span class="required">*</span>
                                                                </label>
                                                                <date-picker input-class="info-field"
                                                                placeholder=""
                                                                type="date"
                                                                id="d_last_seen"
                                                                v-model="data.health_info.condition_questions[index1].conditions[index2].d_last_seen"
                                                                value-type="format"
                                                                format="MM/DD/YYYY"></date-picker>
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3">
                                                            <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('date_of_onset')">
                                                                <label for="date_of_onset">
                                                                    Date of Onset
                                                                    <span class="required">*</span>
                                                                </label>
                                                                <date-picker input-class="info-field"
                                                                placeholder=""
                                                                type="date"
                                                                id="date_of_onset"
                                                                v-model="data.health_info.condition_questions[index1].conditions[index2].date_of_onset"
                                                                value-type="format"
                                                                format="MM/DD/YYYY"></date-picker>
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xxl-2 col-xl-2 col-lg-4 col-md-6 mb-3">
                                                            <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('date_of_recovery')">
                                                                <label for="date_of_recovery">
                                                                    Date of recovery
                                                                    <span class="required">*</span>
                                                                </label>
                                                                <date-picker input-class="info-field"
                                                                placeholder=""
                                                                type="date"
                                                                id="date_of_recovery"
                                                                v-model="data.health_info.condition_questions[index1].conditions[index2].date_of_recovery"
                                                                value-type="format"
                                                                format="MM/DD/YYYY"></date-picker>
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-3">
                                                            <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('symptoms')">
                                                                <label for="symptoms">
                                                                    Symptoms
                                                                    <span class="required">*</span>
                                                                </label>
                                                                <input class="info-field" v-model="data.health_info.condition_questions[index1].conditions[index2].symptoms"  id="symptoms">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="form-check ques-check col-xxl-2 col-xl-2 col-lg-3 col-md-6 mb-3">
                                                            <input
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            v-model="data.health_info.condition_questions[index1].conditions[index2].is_medicate"
                                                            id="is_medicate"
                                                            true-value=1
                                                            false-value=0
                                                            >
                                                            <label class="form-check-label" for="is_medicate">
                                                                Taking Medication
                                                            </label>
                                                        </div>
                                                        <div class="form-check ques-check col-xxl-2 col-xl-2 col-lg-3 col-md-6 mb-3">
                                                            <input
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            v-model="data.health_info.condition_questions[index1].conditions[index2].is_treatment"
                                                            id="is_treatment"
                                                            true-value=1
                                                            false-value=0
                                                            >
                                                            <label class="form-check-label" for="is_treatment">
                                                                Current Treatment
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <!-- medication question -->
                                            <div class="split-list info-wrap mx-2">
                                                <p>{{ data.health_info.medication_question.question_text }}</p>
                                                <div class="split-yn-div">
                                                    <div class="form-check form-check-inline">
                                                        <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        :name="'medication-question'+data.health_info.medication_question.question_id"
                                                        :id="'medication-question'+data.health_info.medication_question.question_id"
                                                        value=1
                                                        v-model="data.health_info.medication_question.selected_answer"
                                                        @click="addMedications(data.health_info.medication_question)"
                                                        >
                                                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        :name="'medication-question'+data.health_info.medication_question.question_id"
                                                        :id="'medication-question'+data.health_info.medication_question.question_id"
                                                        value=0
                                                        v-model="data.health_info.medication_question.selected_answer"
                                                        @click="removeAllMedications(data.health_info.medication_question)"
                                                        >
                                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                                    </div>
                                                </div>
                                                <!-- medications -->
                                                <div v-if="data.health_info.medication_question.selected_answer == 1">
                                                    <h6>Medications</h6>
                                                    <div class="row mb-3" v-for="(medication, index4) in data.health_info.medication_question.medications" :key="index4+'sdcasc'">
                                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
                                                            <label>Medication/Rx injection<span class="required">*</span></label>
                                                            <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('medication')">
                                                                <input class="info-field" v-model="data.health_info.medication_question.medications[index4].medication"  :id="'medication'+index4">
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
                                                            <label>Dosage<span class="required">*</span></label>
                                                            <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('dosage')">
                                                                <input class="info-field" v-model="data.health_info.medication_question.medications[index4].dosage"  :id="'dosage'+index4">
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
                                                            <label>Medical Condition<span class="required">*</span></label>
                                                            <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('medical_condition')">
                                                                <input class="info-field" v-model="data.health_info.medication_question.medications[index4].medical_condition"  :id="'medical_condition'+index4">
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 align-center">
                                                            <span class="remove-medication"
                                                            @click="removeMedication(data.health_info.medication_question, index4)"
                                                            v-if="data.health_info.medication_question.medications.length > 1"
                                                            >
                                                                <i class="far fa-times-circle"></i> Remove

                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-2" v-if="data.health_info.medication_question.medications.length < 3">
                                                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                                                            <b-button class="univ-btn" variant="success" @click="addMedications(data.health_info.medication_question)">+ Add Medications</b-button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group mx-2">
                                                <label for="">Additional Notes</label>
                                                <textarea
                                                name=""
                                                id=""
                                                class="info-field"
                                                rows="4"
                                                placeholder="Enter additional notes"
                                                v-model="data.health_info.notes"></textarea>
                                                <span class="note-text">*Please note, any false information, or information not given may result in termination of eligibility for coverage under the Plan or denial of benefits under the Plan.*</span>
                                            </div>
                                        </b-card>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <div class="form-group text-center">
                            <b-button class="mt-3 univ-btn" variant="info" type="button" @click="handleSubmit(saveDependent(index))">{{ isValidating ? 'Validating' : 'Submit'}}</b-button>
                        </div>
                    </b-modal>
                </form>
            </ValidationObserver>
        </div>

        <div class="row">
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                <b-button variant="success univ-btn" @click="createDependent" v-b-modal.add-dependent v-if="showAddDependent && isAddMoreDepValidForIHA">+ Add Dependent</b-button>
            </div>
        </div>

        <!-- add dependent modal -->
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(addDependent)">
                <b-modal id="add-dependent" ref="add-dependent" size="xl" :title="'Dependent #' + (parseInt(index) + 1)" :ok-disabled="true" @hidden="resetModal">
                    <!-- personal info -->
                    <div class="info-form mb-4" v-if="form.primary_info">
                        <div class="form-group row">
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.first_name">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.first_name)" :custom-messages="getErrorMessages('first_name')">
                                    <label for="first_name">
                                        First Name
                                        <span v-if="form.primary_info.first_name.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.primary_info.first_name"  id="first_name">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.middle_name">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.middle_name)" :custom-messages="getErrorMessages('middle_name')">
                                    <label for="middle_name">
                                        Middle Name
                                        <span v-if="form.primary_info.middle_name.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.primary_info.middle_name"  id="middle_name">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.last_name">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.last_name)" :custom-messages="getErrorMessages('last_name')">
                                    <label for="last_name">
                                        Last Name
                                        <span v-if="form.primary_info.last_name.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.primary_info.last_name"  id="last_name">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.gender">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.gender)" :custom-messages="getErrorMessages('gender')">
                                    <label for="gender">
                                        Gender
                                        <span v-if="form.primary_info.gender.required" class="required">*</span>
                                    </label>
                                    <select class="info-field" id="gender" v-model="data.primary_info.gender">
                                        <option value="0">Male</option>
                                        <option value="1">Female</option>
                                        <!-- <option v-for="(option, index1) in form.primary_info.gender.options" :key="index1" :id="option.index1" :value="option.value">
                                            {{ option.name }}
                                        </option> -->
                                    </select>
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.birthdate">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.birthdate)" :custom-messages="getErrorMessages('birthdate')">
                                    <label for="birthdate">
                                        Birthdate
                                        <span v-if="form.primary_info.birthdate.required" class="required">*</span>
                                    </label>
                                    <date-picker input-class="info-field"
                                    placeholder=""
                                    type="date"
                                    id="birthdate"
                                    v-model="data.primary_info.birthdate"
                                    value-type="format"
                                    format="MM/DD/YYYY"></date-picker>
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.ssn">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.ssn)" :custom-messages="getErrorMessages('ssn')">
                                    <label for="ssn">
                                        SSN
                                        <span v-if="form.primary_info.ssn.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.primary_info.ssn" id="ssn" maxlength="9">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.relationship">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.relationship)" :custom-messages="getErrorMessages('relationship')">
                                    <label for="relation">
                                        Relationship
                                        <span v-if="form.primary_info.relationship.required" class="required">*</span>
                                    </label>
                                    <select class="info-field" id="relation" v-model="data.primary_info.relationship">
                                        <option
                                        v-for="(option, index1) in form.primary_info.relationship.options"
                                        :key="index1"
                                        :id="option.index1"
                                        :value="option.value"
                                        :disabled="disableRelationshipOption(option)">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.height_feet">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.height_feet)" :custom-messages="getErrorMessages('height_feet')">
                                    <label for="height_feet">
                                        Height(feet)
                                        <span v-if="form.primary_info.height_feet.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.primary_info.height_feet"  id="height_feet">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.height_inch">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.height_inch)" :custom-messages="getErrorMessages('height_inch')">
                                    <label for="height_inch">
                                        Height(inch)
                                        <span v-if="form.primary_info.height_inch.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.primary_info.height_inch"  id="height_inch">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="form.primary_info.weight">
                                <ValidationProvider v-slot="{ errors }" :rules="getRules(form.primary_info.weight)" :custom-messages="getErrorMessages('weight')">
                                    <label for="weight">
                                        Weight(lbs)
                                        <span v-if="form.primary_info.weight.required" class="required">*</span>
                                    </label>
                                <input class="info-field" v-model="data.primary_info.weight"  id="weight">
                                <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <!-- health questions info -->
                    <div class="info-form mt-4 row" v-if="data.health_info">
                        <div class="question-accordion row">
                            <div class="col-md-12">
                                <figure v-b-toggle.collapse-1>Health Questionaire
                                    <i
                                    class="accordion-icon"
                                    :class="visible ? 'fa fa-angle-up' : 'collapsed fa fa-angle-down'"
                                    :aria-expanded="visible ? 'true' : 'false'"
                                    aria-controls="collapse-4"
                                    @click="visible = !visible"
                                    />
                                </figure>
                                <b-collapse id="collapse-1" v-model="visible" class="">
                                    <b-card>
                                        <!-- condition questions -->
                                        <div v-for="(question, index5) in data.health_info.condition_questions" :key="index5+'ascsac'" class="mx-2">
                                            <div class="split-list">
                                                <p>{{ question.question_text }}</p>
                                                <div class="split-yn-div">
                                                    <div class="form-check form-check-inline">
                                                        <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        :name="'condition-question'+question.question_id"
                                                        :id="index5"
                                                        value=1
                                                        v-model="data.health_info.condition_questions[index5].selected_answer"
                                                        @click="addCondition(question)"
                                                        >
                                                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        :name="'condition-question'+question.question_id"
                                                        :id="index5"
                                                        value=0
                                                        v-model="data.health_info.condition_questions[index5].selected_answer"
                                                        @click="removeAllConditions(question)"
                                                        >
                                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- conditions -->
                                            <div v-if="question.selected_answer == 1">
                                                <h6>Conditions</h6>
                                                <div v-for="(condition, index6) in question.conditions" :key="index6 + 'dsacd'" class="row mx-2">
                                                    <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                                                        <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('health_condition')">
                                                            <label for="health_condition">
                                                                Health Condition
                                                                <span class="required">*</span>
                                                            </label>
                                                            <input class="info-field" v-model="data.health_info.condition_questions[index5].conditions[index6].health_condition"  id="health_condition">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                                                        <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('doctor_last_seen')">
                                                            <label for="d_last_seen">
                                                                Last seen by Doctor
                                                                <span class="required">*</span>
                                                            </label>
                                                            <date-picker input-class="info-field"
                                                            placeholder=""
                                                            type="date"
                                                            id="d_last_seen"
                                                            v-model="data.health_info.condition_questions[index5].conditions[index6].d_last_seen"
                                                            value-type="format"
                                                            format="MM/DD/YYYY"></date-picker>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                                                        <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('date_of_onset')">
                                                            <label for="date_of_onset">
                                                                Date of Onset
                                                                <span class="required">*</span>
                                                            </label>
                                                            <date-picker input-class="info-field"
                                                            placeholder=""
                                                            type="date"
                                                            id="date_of_onset"
                                                            v-model="data.health_info.condition_questions[index5].conditions[index6].date_of_onset"
                                                            value-type="format"
                                                            format="MM/DD/YYYY"></date-picker>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                                                        <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('date_of_recovery')">
                                                            <label for="date_of_recovery">
                                                                Date of recovery
                                                                <span class="required">*</span>
                                                            </label>
                                                            <date-picker input-class="info-field"
                                                            placeholder=""
                                                            type="date"
                                                            id="date_of_recovery"
                                                            v-model="data.health_info.condition_questions[index5].conditions[index6].date_of_recovery"
                                                            value-type="format"
                                                            format="MM/DD/YYYY"></date-picker>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="form-check col-xxl-6 col-lg-6 col-md-6 mb-3">
                                                        <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        v-model="data.health_info.condition_questions[index5].conditions[index6].is_medicate"
                                                        id="is_medicate"
                                                        true-value=1
                                                        false-value=0
                                                        >
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Taking Medication
                                                        </label>
                                                    </div>
                                                    <div class="form-check col-xxl-6 col-lg-6 col-md-6 mb-3">
                                                        <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        v-model="data.health_info.condition_questions[index5].conditions[index6].is_treatment"
                                                        id="is_treatment"
                                                        true-value=1
                                                        false-value=0
                                                        >
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Current Treatment
                                                        </label>
                                                    </div>
                                                    <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                                                        <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('symptoms')">
                                                            <label for="symptoms">
                                                                Symptoms
                                                                <span class="required">*</span>
                                                            </label>
                                                            <input class="info-field" v-model="data.health_info.condition_questions[index5].conditions[index6].symptoms"  id="symptoms">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- medication question -->
                                        <div class="split-list">
                                            <p>{{ data.health_info.medication_question.question_text }}</p>
                                            <div class="split-yn-div">
                                                <div class="form-check form-check-inline">
                                                    <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    :name="'medication-question'+data.health_info.medication_question.question_id"
                                                    :id="'medication-question'+data.health_info.medication_question.question_id"
                                                    value=1
                                                    v-model="data.health_info.medication_question.selected_answer"
                                                    @click="addMedications(data.health_info.medication_question)"
                                                    >
                                                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input"
                                                    type="radio"
                                                    :name="'medicaiton-question'+data.health_info.medication_question.question_id"
                                                    :id="'medication-question'+data.health_info.medication_question.question_id"
                                                    value=0
                                                    v-model="data.health_info.medication_question.selected_answer"
                                                    @click="removeAllMedications(data.health_info.medication_question)"
                                                    >
                                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                                </div>
                                            </div>
                                            <!-- medications -->
                                            <div v-if="data.health_info.medication_question.selected_answer == 1">
                                                <h6>Medications</h6>
                                                <table>
                                                        <thead>
                                                        <tr>
                                                            <th>Medication/Rx injection<span class="required">*</span></th>
                                                            <th>Dosage<span class="required">*</span></th>
                                                            <th>Medical Condition<span class="required">*</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(medication, index8) in data.health_info.medication_question.medications" :key="index8 + 'retrty'">
                                                            <td>
                                                                <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('medication')">
                                                                    <input class="info-field" v-model="data.health_info.medication_question.medications[index8].medication"  :id="'medication'+index8">
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </td>
                                                            <td>
                                                                <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('dosage')">
                                                                    <input class="info-field" v-model="data.health_info.medication_question.medications[index8].dosage"  :id="'dosage'+index8">
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </td>
                                                            <td>
                                                                <ValidationProvider v-slot="{ errors }" rules="required" :custom-messages="getErrorMessages('medical_condition')">
                                                                    <input class="info-field" v-model="data.health_info.medication_question.medications[index8].medical_condition"  :id="'medical_condition'+index8">
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </td>
                                                            <td>
                                                                <span
                                                                @click="removeMedication(data.health_info.medication_question, index8)"
                                                                v-if="data.health_info.medication_question.medications.length > 1"
                                                                >
                                                                    <b-button pill variant="danger">- Remove</b-button>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="row mt-2" v-if="data.health_info.medication_question.medications.length < 3">
                                                    <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                                                        <b-button variant="success" @click="addMedications(data.health_info.medication_question)">+ Add Medications</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group mx-2">
                                            <label for="">Additional Notes</label>
                                            <textarea
                                            name=""
                                            id=""
                                            class="info-field"
                                            rows="4"
                                            placeholder="Enter additional notes"
                                            v-model="data.health_info.notes"></textarea>
                                            <span class="note-text">*Please note, any false information, or information not given may result in termination of eligibility for coverage under the Plan or denial of benefits under the Plan.*</span>
                                        </div>
                                    </b-card>
                                </b-collapse>
                            </div>
                        </div>
                    </div>

                    <div class="form-group text-center">
                        <b-button class="mt-3 univ-btn" variant="info" type="button" @click="handleSubmit(addDependent)">{{ isValidating ? 'Validating' : 'Submit'}}</b-button>
                    </div>
                </b-modal>
            </form>
        </ValidationObserver>

        <div class="row">
            <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
                <span class="text-danger" v-for="(error, index) in validateDependents" :key="index + 'sacsc'">
                    {{ error }}
                </span>
            </div>
        </div>

        <div class="row justify-content-end mt-3">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                <button type="button" class="univ-btn" style=" max-width: 100% !important;" @click="updateDependentInfo" :disabled="disableUpdate">{{ isProcessing ? 'Processing' :'Update'}}</button>
            </div>
        </div>
    </div>
</template>
<script>
import Api from '../../resource/Api';
export default {
    name: 'DependentInfo',
    data: () => ({
        visible: false,
        version: process.env.VUE_APP_API_VERSION,
        isProcessing: false,
        isValidating: false,
        postData: [],
        formFields: [],
        form: [],
        index: 0,
        tiers: [],
        errors: [],
        data:{},
        isAddMoreDepValidForIHA: true,
        isIHAPlan: false,
        IHATier: '',
        numOfChildren: 0
    }),
    methods: {
        getDependentInfo(){
            let url = "v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/dependent-info";
            let loader = this.$loading.show();
            Api.get(url)
                .then((res) => {
                    let dependents = res.data.data.dependents;
                    this.isIHAPlan = res.data.data.is_iha
                    this.IHATier = res.data.data.tier
                    for(let index in dependents){
                        let primaryInfoData, primaryInfoFormFields;
                        let conditionQuestionsData = [], conditionsQuestionsForms = [], conditionsData = {}, conditionsFormFields = {};
                        let medicationsFormFields = {}, medicationQuestionData = [], medicationQuestionForm = [], medicationsAnsList = [];
                        dependents[index].primary_info.map(info => {
                            primaryInfoData = {...primaryInfoData, [info.field_name]: info.value};
                            primaryInfoFormFields = {...primaryInfoFormFields, [info.field_name]: info}
                        })
                        this.$set(this.formFields, index, {
                                ['primary_info'] : {...primaryInfoFormFields},
                        });
                        this.$set(this.postData, index, {
                            ['primary_info'] : {...primaryInfoData},
                        });


                        if(Object.keys(dependents[index]).includes('health_info')){
                            let note = dependents[index].health_info.notes;
                            dependents[index].health_info.condition_questions.forEach((conditionQuestion) => {
                                if(conditionQuestion.selected_answer == 1){
                                    conditionQuestion.conditions.forEach((condition) => {
                                        condition.forEach(data => {
                                            conditionsData = {...conditionsData, [data.field_name]: data.value};
                                            conditionsFormFields = {...conditionsFormFields, [data.field_name]: data};
                                        })
                                    })
                                }
                                conditionQuestionsData.push({
                                    ['question_id']: conditionQuestion.question_id,
                                    ['question_text']: conditionQuestion.question_text,
                                    ['selected_answer']: conditionQuestion.selected_answer,
                                    ['conditions']: conditionQuestion.selected_answer ==1 ? [{...conditionsData}] : []
                                })
                                conditionsQuestionsForms.push({
                                    ['question_id']: conditionQuestion.question_id,
                                    ['question_text']: conditionQuestion.question_text,
                                    ['selected_answer']: conditionQuestion.selected_answer,
                                    ['conditions']: conditionQuestion.selected_answer == 1 ? [{...conditionsFormFields}] : []
                                })
                            })
                            let medicationQuestion;
                            if(dependents[index].health_info.medication_question){
                                medicationQuestion = dependents[index].health_info.medication_question;
                                if(medicationQuestion.selected_answer == 1){
                                    medicationQuestion.medications.forEach((medication) => {
                                        medication.forEach(medicationData => {
                                           // medicationsData = {...medicationsData, [medicationData.field_name]: medicationData.value}
                                            medicationsFormFields = {...medicationsFormFields, [medicationData.field_name]: medicationData}
                                        })
                                      let finalData = this.mapMedicationsList(medication)
                                      medicationsAnsList.push(finalData)
                                    })
                                }
                                medicationQuestionData = {
                                    ['question_id']: medicationQuestion.question_id,
                                    ['question_text']: medicationQuestion.question_text,
                                    ['selected_answer']: medicationQuestion.selected_answer,
                                    ['medications']: medicationQuestion.selected_answer == 1 ?  medicationsAnsList : []
                                }
                                medicationQuestionForm = {
                                    ['question_id']: medicationQuestion.question_id,
                                    ['question_text']: medicationQuestion.question_text,
                                    ['selected_answer']: medicationQuestion.selected_answer,
                                    ['medications']: medicationQuestion.selected_answer == 1 ? [{...medicationsFormFields}] : []
                                }
                            }
                           this.$set(this.formFields, index, {
                                ['primary_info'] : {...primaryInfoFormFields},
                                ['health_info']: {
                                    ['notes']: note,
                                    ['condition_questions']: conditionsQuestionsForms,
                                    ['medication_question']: medicationQuestionForm
                                }
                            });
                            this.$set(this.postData, index, {
                                ['primary_info'] : {...primaryInfoData},
                                ['health_info']: {
                                    ['notes']: note,
                                    ['condition_questions']: conditionQuestionsData,
                                    ['medication_question']: medicationQuestionData
                                }
                            });
                        }
                    }
                    this.form = this.formFields[0];
                    this.index = dependents.length;
                })
                .catch((err) => {
                    Api.notification(err.status, err.message)
                })
                .finally(() => {
                  loader.hide();
                });
        },
        mapMedicationsList(data) {
          let medicationsData = {}
          data.forEach((data) => {
            medicationsData = {...medicationsData, [data.field_name]: data.value}
          })
          return medicationsData
        },
        updateDependentInfo(){
            let url = "v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/dependents-info";
            this.isProcessing = true;
            if(!this.validateDependents.length){
                let dependents = {['dependents'] : {...this.postData}};
                Api.patch(url, dependents)
                    .then((response) => {
                        Api.notification(response.data.status, response.data.message);
                    })
                    .catch((err) => {
                        Api.notification(err.response.data.status, err.response.data.message);
                        Api.notification(err.response.data.success, err.response.data.message);
                    })
                    .finally(()=>{
                        this.isProcessing = false;
                    })
            }else{
                this.isProcessing = false;
                // Api.notification(false, 'Total beneficiary percentage should be 100%');
            }
        },
        getTiers(){
            let url = "v2/get-policy-tiers/" + atob(this.$route.params.enrollmentId);
            Api.get(url)
                .then(res => {
                    this.tiers = res.data.data;
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getLabelName(fieldName){
            return fieldName.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        },
        getRules(item){
            if(item == null){
                return {};
            }
            //Personal info field rules
            if(item.field_name === 'first_name' || item.field_name === 'last_name'){
                return {
                    required: item.required,
                    alpha_spaces: true
                }
            }
            if(item.field_name === 'middle_name'){
                return {
                    required: item.required,
                    length: 1,
                    alpha: true
                }
            }
            if(item.field_name === 'height_feet' || item.field_name === 'weight'){
                return {
                    required: item.required,
                    numeric: true,
                    min_value: 1
                }
            }
            if(item.field_name === 'height_inch'){
                return {
                    required: item.required,
                    numeric: true,
                    min_value: 0,
                    max_value: 12
                }
            }
            if(item.field_name === 'ssn'){
                return {
                    required: item.required,
                    digits: 9
                }
            }
            return {required: item.required}
        },
        getErrorMessages(field_name){
            if(field_name === 'height_feet' || field_name === 'weight'){
               return {
                'required': this.getLabelName(field_name) + " is required",
                'numeric': this.getLabelName(field_name) + " may only contain positive numbers",
                'min_value': this.getLabelName(field_name) + " must be more than 1"
               }
            }
            if(field_name === 'middle_name'){
                return {
                'required': this.getLabelName(field_name) + " is required",
                'alpha_spaces': this.getLabelName(field_name) + " may only contain alphabetic characters",
                'length': this.getLabelName(field_name) + " cannot be more than 1 character"
                }
            }
            if(field_name === 'height_inch'){
                return {
                'required': this.getLabelName(field_name) + " is required",
                'numeric': this.getLabelName(field_name) + " may only contain positive numbers",
                'min_value': 'Height(inch) must be between 0 and 12',
                'max_value': 'Height(inch) must be between 0 and 12'
               }
            }
            return {
                'required': this.getLabelName(field_name) + " is required",
                'alpha_spaces': this.getLabelName(field_name) + " may only contain alphabetic characters",
                'max': this.getLabelName(field_name) + ' cannot be more than 100%',
                'numeric': this.getLabelName(field_name) + ' may only contain numeric characters',
            }
        },
        getFullName(dependent){
            let middle_name = dependent.middle_name ?? '';
            return dependent.first_name + ' ' + middle_name + ' ' + dependent.last_name
        },
        editDependent(dep){
            this.data = JSON.parse(JSON.stringify(dep))
            // this.index = index;
        },
        saveDependent(index){
            let url = "v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/validate-single-dependent";
            this.isValidating = true;
            Api.post(url, this.data)
                .then(res => {
                    if(res.data.status === "success"){
                        let tempData = JSON.parse(JSON.stringify(this.data));
                        this.$set(this.postData, index, {...tempData});
                        this.$refs["edit-dependent" + index][0].hide();
                    }
                })
                .catch(err => {
                    Api.notification(err.response.data.status, err.response.data.message);
                    Api.notification(err.response.data.success, err.response.data.message);
                    return false;
                })
                .finally(()=>{
                    this.isValidating = false;
                })
        },
        createDependent(){
            this.data = JSON.parse(JSON.stringify(this.postData[0]));
            this.resetModal();
        },
        addDependent(){
            let url = "v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/validate-single-dependent";
            this.isValidating = true;
            Api.post(url, this.data)
                .then(res => {
                    if(res.data.status === "success"){
                        this.postData.push(JSON.parse(JSON.stringify(this.data)));
                        this.$refs["add-dependent"].hide();
                    }
                })
                .catch(err => {
                    Api.notification(err.response.data.status, err.response.data.message);
                    Api.notification(err.response.data.success, err.response.data.message);
                    return false;
                })
                .finally(()=>{
                    this.isValidating = false;
                })
        },
        deleteDependent(index){
            this.$delete(this.formFields, index);
            this.$delete(this.postData, index);
            index = index - 1;
        },
        resetModal(){
            // this.data = {};
            // this.$refs['edit_dependent_form' + index][0].reset();

            this.data.primary_info.birthdate = "";
            this.data.primary_info.first_name = "";
            this.data.primary_info.last_name = "";
            this.data.primary_info.middle_name = "";
            this.data.primary_info.relationship = "";
            this.data.primary_info.ssn = "";
            this.data.primary_info.gender = "";
            this.data.primary_info.height_feet = "";
            this.data.primary_info.height_inch = "";
            this.data.primary_info.weight = "";
            if(this.data.health_info){
                this.data.health_info.notes = "";
                this.data.health_info.condition_questions.forEach(conditionQuestion => {
                    conditionQuestion.selected_answer = 0;
                    if(conditionQuestion.conditions){
                        conditionQuestion.conditions = []
                    }
                });
                this.data.health_info.medication_question.selected_answer = 0;
                if(this.data.health_info.medication_question.medications){
                    this.data.health_info.medication_question.medications = [];
                }
            }
        },
        addMedications(question){
            question.medications.push({
                'dosage': '',
                'medical_condition': '',
                'medication': ''
            });
        },
        removeMedication(question, index){
            this.$delete(question.medications, index);
        },
        removeAllMedications(question){
            question.medications = [];
        },
        addCondition(question){
            question.conditions.push({
                d_last_seen:"",
                date_of_onset:"",
                date_of_recovery:"",
                health_condition:"",
                is_medicate:"0",
                is_treatment:"0",
                symptoms:""
            });
        },
        removeAllConditions(question){
            question.conditions = [];
        },
        disableRelationshipOption(option){
            if(this.tiers.includes("IS") && option.value == 'c') return true;
            if(this.tiers.includes("IC") && option.value == 's') return true;
            let dependents = this.postData.filter(dependent => {
                   return dependent.primary_info.relationship === 's';
                });
            if(this.tiers.includes("IF") && option.value == 's'){
                if(dependents.length == 1) return true;
            }
        }
    },
    computed: {
        showDelete: function(){
            if(this.tiers.includes("IF") && this.postData.length > 2) return true;
            // if(this.tiers.includes("IS") && this.postData.length == 1) return true;
            if(this.tiers.includes("IC") && this.postData.length > 1) return true;
            return false;
        },
        showAddDependent: function(){
            if(this.tiers.includes("IS") && this.postData.length == 0) return true;
            if((this.tiers.includes("IC") || this.tiers.includes("IF")) && this.postData.length < 9) return true;
            return false;
        },
        disableUpdate: function(){
            if(this.errors.length) return true;
            if(this.tiers.includes("IF") && this.postData.length <= 1) return true;
            if((this.tiers.includes("IC") || this.tiers.includes("IS")) && this.postData.length <= 0) return true;
            return false;
        },
        validateDependents: function(){
            let errors = [];
            if(this.tiers.includes('IS')){
                if(this.postData.length <= 0) {
                    errors.push("Atleast one dependent information is required.");
                    // return false;
                }
                if(this.postData.length > 1) {
                    errors.push("Cannot allow more than one dependent.");
                    // return false;
                }
                this.postData.forEach(dependent => {
                    if(dependent.primary_info.relationship == 'c') {
                        errors.push("Cannot have child as a dependent. Only spouse is allowed.");
                        // return false;
                    }
                })
                return errors;
                // return true;
            }
            if(this.tiers.includes('IF')){
                if(this.postData.length <= 1) {
                    errors.push("Atleast one spouse and one child dependent information is required.");
                    // return false;
                }
                let dependents = this.postData.filter(dependent => {
                   return dependent.primary_info.relationship === 's';
                });
                if(dependents.length > 1){
                    errors.push("Cannot have two spouse dependent information.");
                    // return false;
                }
                if(dependents.length < 1){
                    errors.push("One spouse dependent information is required.");
                    // return false;
                }
                return errors;
                // return true;
            }
            return errors;
            // return true;

        },
    },
    mounted(){
        this.getDependentInfo();
        this.getTiers();
        // this.getQuestions();
    },
    watch: {
      postData: {
        handler(newPostData) {
          if (this.isIHAPlan && ["IC", "IF"].includes(this.IHATier)) {
            this.numOfChildren = newPostData.filter(
                (depData) => depData.primary_info.relationship === "c"
            ).length;
            this.isAddMoreDepValidForIHA =
                (this.IHATier === "IC" && this.numOfChildren < 5) ||
                (this.IHATier === "IF" && this.numOfChildren < 9);
          }
        },
        deep: true,
      },
    },

}
</script>
