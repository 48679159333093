var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrap"
  }, [_c('h2', [_vm._v("Beneficiary Information")]), _c('p', [_vm._v("Enter and Update all your Beneficiary Information here")]), _vm._l(_vm.postData, function (ben, index) {
    return _c('div', {
      key: index,
      attrs: {
        "id": index
      }
    }, [_c('h3', [_vm._v(_vm._s(ben.is_contigent ? 'Contingent Beneficiary Details' : 'Beneficiary Details'))]), _c('div', {
      staticClass: "row info-form mb-3"
    }, [_c('b-input-group', {
      attrs: {
        "id": 'beneficiary' + index
      }
    }, [_c('b-input-group-append', {
      staticClass: "split-group"
    }, [_c('b-form-input', {
      staticClass: "info-field",
      attrs: {
        "value": _vm.getFullName(ben),
        "disabled": ""
      }
    }), _c('b-button', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal",
        value: 'edit-beneficiary' + index,
        expression: "'edit-beneficiary' + index"
      }],
      attrs: {
        "variant": "primary edit"
      },
      on: {
        "click": function click($event) {
          return _vm.editBeneficiary(ben, index);
        }
      }
    }, [_vm._v("Edit")]), _c('b-button', {
      attrs: {
        "variant": "danger delete"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteBeneficiary(index);
        }
      }
    }, [_vm._v("Delete")])], 1)], 1)], 1), _c('ValidationObserver', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var handleSubmit = _ref.handleSubmit;
          return [_c('form', {
            on: {
              "submit": function submit($event) {
                $event.preventDefault();
                return handleSubmit(_vm.saveBenefeciary);
              }
            }
          }, [_c('b-modal', {
            ref: 'edit-beneficiary' + index,
            refInFor: true,
            attrs: {
              "id": 'edit-beneficiary' + index,
              "size": "lg",
              "title": _vm.data.is_contigent ? 'Contingent Beneficiary' : 'Beneficiary',
              "centered": ""
            },
            on: {
              "hidden": _vm.resetModal
            }
          }, [_c('div', {
            staticClass: "info-form mb-4"
          }, [_c('div', {
            staticClass: "form-group row"
          }, [_vm.formFields[index].first_name ? _c('div', {
            staticClass: "col-xxl-4 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.formFields[index].first_name),
              "custom-messages": _vm.getErrorMessages('first_name')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref2) {
                var errors = _ref2.errors;
                return [_c('label', {
                  attrs: {
                    "for": "first_name"
                  }
                }, [_vm._v(" First Name "), _vm.formFields[index].first_name.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.first_name,
                    expression: "data.first_name"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "first_name"
                  },
                  domProps: {
                    "value": _vm.data.first_name
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data, "first_name", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.formFields[index].middle_name ? _c('div', {
            staticClass: "col-xxl-4 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.formFields[index].middle_name),
              "custom-messages": _vm.getErrorMessages('middle_name')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref3) {
                var errors = _ref3.errors;
                return [_c('label', {
                  attrs: {
                    "for": "middle_name"
                  }
                }, [_vm._v(" Middle Name "), _vm.formFields[index].middle_name.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.middle_name,
                    expression: "data.middle_name"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "middle_name"
                  },
                  domProps: {
                    "value": _vm.data.middle_name
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data, "middle_name", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.formFields[index].last_name ? _c('div', {
            staticClass: "col-xxl-4 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.formFields[index].last_name),
              "custom-messages": _vm.getErrorMessages('last_name')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref4) {
                var errors = _ref4.errors;
                return [_c('label', {
                  attrs: {
                    "for": "last_name"
                  }
                }, [_vm._v(" Last Name "), _vm.formFields[index].last_name.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.last_name,
                    expression: "data.last_name"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "last_name"
                  },
                  domProps: {
                    "value": _vm.data.last_name
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data, "last_name", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.formFields[index].birthdate ? _c('div', {
            staticClass: "col-xxl-4 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.formFields[index].birthdate),
              "custom-messages": _vm.getErrorMessages('birthdate')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref5) {
                var errors = _ref5.errors;
                return [_c('label', {
                  attrs: {
                    "for": "birthdate"
                  }
                }, [_vm._v(" Birthdate "), _vm.formFields[index].birthdate.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('date-picker', {
                  attrs: {
                    "input-class": "info-field",
                    "placeholder": "",
                    "type": "date",
                    "id": "birthdate",
                    "value-type": "format",
                    "format": "MM/DD/YYYY"
                  },
                  model: {
                    value: _vm.data.birthdate,
                    callback: function callback($$v) {
                      _vm.$set(_vm.data, "birthdate", $$v);
                    },
                    expression: "data.birthdate"
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.formFields[index].ssn ? _c('div', {
            staticClass: "col-xxl-4 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.formFields[index].ssn),
              "custom-messages": _vm.getErrorMessages('ssn')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref6) {
                var errors = _ref6.errors;
                return [_c('label', {
                  attrs: {
                    "for": "ssn"
                  }
                }, [_vm._v(" SSN "), _vm.formFields[index].ssn.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.ssn,
                    expression: "data.ssn"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "ssn",
                    "maxlength": "9"
                  },
                  domProps: {
                    "value": _vm.data.ssn
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data, "ssn", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.formFields[index].relation ? _c('div', {
            staticClass: "col-xxl-4 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.formFields[index].relation),
              "custom-messages": _vm.getErrorMessages('relation')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref7) {
                var errors = _ref7.errors;
                return [_c('label', {
                  attrs: {
                    "for": "relation"
                  }
                }, [_vm._v(" Relationship "), _vm.formFields[index].relation.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.relation,
                    expression: "data.relation"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "relation"
                  },
                  on: {
                    "change": function change($event) {
                      var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                        return o.selected;
                      }).map(function (o) {
                        var val = "_value" in o ? o._value : o.value;
                        return val;
                      });

                      _vm.$set(_vm.data, "relation", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                    }
                  }
                }, _vm._l(_vm.formFields[index].relation.options, function (option, index1) {
                  return _c('option', {
                    key: index1,
                    attrs: {
                      "id": option.index1
                    },
                    domProps: {
                      "value": option.value
                    }
                  }, [_vm._v(" " + _vm._s(option.name) + " ")]);
                }), 0), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.formFields[index].beneficiary_percentage && !_vm.data.is_contigent ? _c('div', {
            staticClass: "col-xxl-6 col-lg-3 col-md-6 mb-3"
          }, [_c('ValidationProvider', {
            attrs: {
              "rules": _vm.getRules(_vm.formFields[index].beneficiary_percentage),
              "custom-messages": _vm.getErrorMessages('beneficiary_percentage')
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref8) {
                var errors = _ref8.errors;
                return [_c('label', {
                  attrs: {
                    "for": "beneficiary_percentage"
                  }
                }, [_vm._v(" Percentage (upto 100%) "), _vm.formFields[index].beneficiary_percentage.required ? _c('span', {
                  staticClass: "required"
                }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.beneficiary_percentage,
                    expression: "data.beneficiary_percentage"
                  }],
                  staticClass: "info-field",
                  attrs: {
                    "id": "beneficiary_percentage"
                  },
                  domProps: {
                    "value": _vm.data.beneficiary_percentage
                  },
                  on: {
                    "input": function input($event) {
                      if ($event.target.composing) { return; }

                      _vm.$set(_vm.data, "beneficiary_percentage", $event.target.value);
                    }
                  }
                }), _c('span', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1) : _vm._e()]), _c('div', {
            staticClass: "form-group text-center"
          }, [_c('b-button', {
            staticClass: "mt-3 univ-btn",
            attrs: {
              "variant": "info",
              "type": "button"
            },
            on: {
              "click": function click($event) {
                handleSubmit(_vm.saveBeneficiary(index));
              }
            }
          }, [_vm._v("submit")])], 1)])])], 1)];
        }
      }], null, true)
    })], 1);
  }), _vm.totalBenPercentage != 100 ? _c('div', {
    staticClass: "row"
  }, [_vm._m(0)]) : _vm._e(), _vm.totalBenPercentage < 100 ? _c('div', {
    staticClass: "row"
  }, [_vm.postData.filter(function (item) {
    return item.is_contigent != 1;
  }).length < 3 ? _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal",
      value: 'add-beneficiary',
      expression: "'add-beneficiary'"
    }],
    attrs: {
      "variant": ""
    }
  }, [_vm._v("Add Beneficiary")])], 1) : _vm._e()]) : _vm._e(), !_vm.checkContigentFlag() ? _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal",
      value: 'add-contingent-beneficiary',
      expression: "'add-contingent-beneficiary'"
    }],
    attrs: {
      "variant": ""
    }
  }, [_vm._v("Add Contingent Beneficiary")])], 1) : _vm._e(), _c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var handleSubmit = _ref9.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.addBeneficiary);
            }
          }
        }, [_c('b-modal', {
          ref: 'add-beneficiary',
          attrs: {
            "id": 'add-beneficiary',
            "size": "lg",
            "title": "Beneficiary ",
            "ok-disabled": true
          },
          on: {
            "hidden": _vm.resetModal
          }
        }, [_c('div', {
          staticClass: "info-form mb-4"
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.first_name),
            "custom-messages": _vm.getErrorMessages('first_name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('label', {
                attrs: {
                  "for": "first_name"
                }
              }, [_vm._v(" First Name "), _c('span', {
                staticClass: "required"
              }, [_vm._v("*")])]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.first_name,
                  expression: "data.first_name"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "first_name"
                },
                domProps: {
                  "value": _vm.data.first_name
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data, "first_name", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.middle_name),
            "custom-messages": _vm.getErrorMessages('middle_name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var errors = _ref11.errors;
              return [_c('label', {
                attrs: {
                  "for": "middle_name"
                }
              }, [_vm._v(" Middle Name "), _vm.form.middle_name.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.middle_name,
                  expression: "data.middle_name"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "middle_name"
                },
                domProps: {
                  "value": _vm.data.middle_name
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data, "middle_name", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _vm.form.last_name ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.last_name),
            "custom-messages": _vm.getErrorMessages('last_name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var errors = _ref12.errors;
              return [_c('label', {
                attrs: {
                  "for": "last_name"
                }
              }, [_vm._v(" Last Name "), _vm.form.last_name.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.last_name,
                  expression: "data.last_name"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "last_name"
                },
                domProps: {
                  "value": _vm.data.last_name
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data, "last_name", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.birthdate ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.birthdate),
            "custom-messages": _vm.getErrorMessages('birthdate')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref13) {
              var errors = _ref13.errors;
              return [_c('label', {
                attrs: {
                  "for": "birthdate"
                }
              }, [_vm._v(" Birthdate "), _vm.form.birthdate.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('date-picker', {
                attrs: {
                  "input-class": "info-field",
                  "placeholder": "",
                  "type": "date",
                  "id": "birthdate",
                  "value-type": "format",
                  "format": "MM/DD/YYYY"
                },
                model: {
                  value: _vm.data.birthdate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.data, "birthdate", $$v);
                  },
                  expression: "data.birthdate"
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.ssn ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.ssn),
            "custom-messages": _vm.getErrorMessages('ssn')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref14) {
              var errors = _ref14.errors;
              return [_c('label', {
                attrs: {
                  "for": "ssn"
                }
              }, [_vm._v(" SSN "), _vm.form.ssn.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.ssn,
                  expression: "data.ssn"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "ssn",
                  "maxlength": "9"
                },
                domProps: {
                  "value": _vm.data.ssn
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data, "ssn", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.relation ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.relation),
            "custom-messages": _vm.getErrorMessages('relation')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref15) {
              var errors = _ref15.errors;
              return [_c('label', {
                attrs: {
                  "for": "relation"
                }
              }, [_vm._v(" Relationship "), _vm.form.relation.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.relation,
                  expression: "data.relation"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "relation"
                },
                on: {
                  "change": function change($event) {
                    var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                      return o.selected;
                    }).map(function (o) {
                      var val = "_value" in o ? o._value : o.value;
                      return val;
                    });

                    _vm.$set(_vm.data, "relation", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                  }
                }
              }, _vm._l(_vm.form.relation.options, function (option, index1) {
                return _c('option', {
                  key: index1,
                  attrs: {
                    "id": option.index1
                  },
                  domProps: {
                    "value": option.value
                  }
                }, [_vm._v(" " + _vm._s(option.name) + " ")]);
              }), 0), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.beneficiary_percentage ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.beneficiary_percentage),
            "custom-messages": _vm.getErrorMessages('beneficiary_percentage')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref16) {
              var errors = _ref16.errors;
              return [_c('label', {
                attrs: {
                  "for": "beneficiary_percentage"
                }
              }, [_vm._v(" Percentage (upto 100%) "), _vm.form.beneficiary_percentage.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.beneficiary_percentage,
                  expression: "data.beneficiary_percentage"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "beneficiary_percentage"
                },
                domProps: {
                  "value": _vm.data.beneficiary_percentage
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data, "beneficiary_percentage", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e()])]), _c('div', {
          staticClass: "form-group text-center"
        }, [_c('b-button', {
          staticClass: "mt-3 univ-btn",
          attrs: {
            "variant": "info",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.addBeneficiary);
            }
          }
        }, [_vm._v("submit")])], 1)])], 1)];
      }
    }])
  }), _c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var handleSubmit = _ref17.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.addContingentBeneficiary);
            }
          }
        }, [_c('b-modal', {
          ref: 'add-beneficiary',
          attrs: {
            "id": 'add-contingent-beneficiary',
            "size": "lg",
            "title": "Contingent Beneficiary",
            "ok-disabled": true
          },
          on: {
            "hidden": _vm.resetModal
          }
        }, [_c('div', {
          staticClass: "info-form mb-4"
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.first_name),
            "custom-messages": _vm.getErrorMessages('first_name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref18) {
              var errors = _ref18.errors;
              return [_c('label', {
                attrs: {
                  "for": "first_name"
                }
              }, [_vm._v(" First Name "), _c('span', {
                staticClass: "required"
              }, [_vm._v("*")])]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.first_name,
                  expression: "data.first_name"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "first_name"
                },
                domProps: {
                  "value": _vm.data.first_name
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data, "first_name", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.middle_name),
            "custom-messages": _vm.getErrorMessages('middle_name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref19) {
              var errors = _ref19.errors;
              return [_c('label', {
                attrs: {
                  "for": "middle_name"
                }
              }, [_vm._v(" Middle Name "), _vm.form.middle_name.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.middle_name,
                  expression: "data.middle_name"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "middle_name"
                },
                domProps: {
                  "value": _vm.data.middle_name
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data, "middle_name", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _vm.form.last_name ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.last_name),
            "custom-messages": _vm.getErrorMessages('last_name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref20) {
              var errors = _ref20.errors;
              return [_c('label', {
                attrs: {
                  "for": "last_name"
                }
              }, [_vm._v(" Last Name "), _vm.form.last_name.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.last_name,
                  expression: "data.last_name"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "last_name"
                },
                domProps: {
                  "value": _vm.data.last_name
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data, "last_name", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.birthdate ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.birthdate),
            "custom-messages": _vm.getErrorMessages('birthdate')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref21) {
              var errors = _ref21.errors;
              return [_c('label', {
                attrs: {
                  "for": "birthdate"
                }
              }, [_vm._v(" Birthdate "), _vm.form.birthdate.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('date-picker', {
                attrs: {
                  "input-class": "info-field",
                  "placeholder": "",
                  "type": "date",
                  "id": "birthdate",
                  "value-type": "format",
                  "format": "MM/DD/YYYY"
                },
                model: {
                  value: _vm.data.birthdate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.data, "birthdate", $$v);
                  },
                  expression: "data.birthdate"
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.ssn ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.ssn),
            "custom-messages": _vm.getErrorMessages('ssn')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref22) {
              var errors = _ref22.errors;
              return [_c('label', {
                attrs: {
                  "for": "ssn"
                }
              }, [_vm._v(" SSN "), _vm.form.ssn.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.ssn,
                  expression: "data.ssn"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "ssn",
                  "maxlength": "9"
                },
                domProps: {
                  "value": _vm.data.ssn
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.data, "ssn", $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.form.relation ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.form.relation),
            "custom-messages": _vm.getErrorMessages('relation')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref23) {
              var errors = _ref23.errors;
              return [_c('label', {
                attrs: {
                  "for": "relation"
                }
              }, [_vm._v(" Relationship "), _vm.form.relation.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.relation,
                  expression: "data.relation"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "relation"
                },
                on: {
                  "change": function change($event) {
                    var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                      return o.selected;
                    }).map(function (o) {
                      var val = "_value" in o ? o._value : o.value;
                      return val;
                    });

                    _vm.$set(_vm.data, "relation", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                  }
                }
              }, _vm._l(_vm.form.relation.options, function (option, index1) {
                return _c('option', {
                  key: index1,
                  attrs: {
                    "id": option.index1
                  },
                  domProps: {
                    "value": option.value
                  }
                }, [_vm._v(" " + _vm._s(option.name) + " ")]);
              }), 0), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e()])]), _c('div', {
          staticClass: "form-group text-center"
        }, [_c('b-button', {
          staticClass: "mt-3 univ-btn",
          attrs: {
            "variant": "info",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.addContingentBeneficiary);
            }
          }
        }, [_vm._v("submit")])], 1)])], 1)];
      }
    }])
  }), _c('div', {
    staticClass: "row justify-content-end mt-3"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6"
  }, [_c('button', {
    staticClass: "univ-btn",
    staticStyle: {
      "max-width": "100% !important"
    },
    attrs: {
      "type": "button",
      "disabled": _vm.totalBenPercentage != 100
    },
    on: {
      "click": _vm.updateBeneficiaryInfo
    }
  }, [_vm._v(_vm._s(_vm.isProcessing ? 'Processing' : 'Update'))])])])], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("Total beneficiary percentage should be 100%")])]);
}]

export { render, staticRenderFns }