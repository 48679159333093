var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrap"
  }, [_c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.updatePrimaryInfo);
            }
          }
        }, [_c('h2', [_vm._v("Personal Information")]), _c('p', [_vm._v("Enter and Update all your Personal Information here")]), _c('div', {
          staticClass: "info-form mb-4"
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_vm.formFields.first_name ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['first_name']),
            "custom-messages": _vm.getErrorMessages('first_name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('label', {
                attrs: {
                  "for": "first_name"
                }
              }, [_vm._v(" First Name "), _vm.formFields['first_name']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['first_name'],
                  expression: "postData['first_name']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "first_name"
                },
                domProps: {
                  "value": _vm.postData['first_name']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'first_name', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.middle_name ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['middle_name']),
            "custom-messages": _vm.getErrorMessages('middle_name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('label', {
                attrs: {
                  "for": "middle_name"
                }
              }, [_vm._v(" Middle Name "), _vm.formFields['middle_name']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['middle_name'],
                  expression: "postData['middle_name']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "middle_name"
                },
                domProps: {
                  "value": _vm.postData['middle_name']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'middle_name', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.last_name ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['last_name']),
            "custom-messages": _vm.getErrorMessages('last_name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('label', {
                attrs: {
                  "for": "last_name"
                }
              }, [_vm._v(" Last Name "), _vm.formFields['last_name']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['last_name'],
                  expression: "postData['last_name']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "last_name"
                },
                domProps: {
                  "value": _vm.postData['last_name']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'last_name', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.gender ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['gender']),
            "custom-messages": _vm.getErrorMessages('gender')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('label', {
                attrs: {
                  "for": "gender"
                }
              }, [_vm._v(" Gender "), _vm.formFields.gender.required ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['gender'],
                  expression: "postData['gender']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "gender"
                },
                on: {
                  "change": function change($event) {
                    var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                      return o.selected;
                    }).map(function (o) {
                      var val = "_value" in o ? o._value : o.value;
                      return val;
                    });

                    _vm.$set(_vm.postData, 'gender', $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                  }
                }
              }, _vm._l(_vm.formFields['gender']['options'], function (option, index1) {
                return _c('option', {
                  key: index1,
                  attrs: {
                    "id": option.index1
                  },
                  domProps: {
                    "value": option.value
                  }
                }, [_vm._v(" " + _vm._s(option.name) + " ")]);
              }), 0), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.birthdate ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "birthdate"
          }
        }, [_vm._v(" Birthdate "), _vm.formFields['birthdate']['required'] ? _c('span', {
          staticClass: "required"
        }, [_vm._v("*")]) : _vm._e()]), _c('date-picker', {
          attrs: {
            "input-class": "info-field",
            "placeholder": "",
            "type": "date",
            "id": "birthdate",
            "value-type": "format",
            "format": "MM/DD/YYYY",
            "disabled": ""
          },
          model: {
            value: _vm.postData['birthdate'],
            callback: function callback($$v) {
              _vm.$set(_vm.postData, 'birthdate', $$v);
            },
            expression: "postData['birthdate']"
          }
        })], 1) : _vm._e(), _vm.formFields.marital_status ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['marital_status']),
            "custom-messages": _vm.getErrorMessages('marital_status')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('label', {
                attrs: {
                  "for": "marital_status"
                }
              }, [_vm._v(" Marital Status "), _vm.formFields['marital_status']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['marital_status'],
                  expression: "postData['marital_status']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "marital_status"
                },
                on: {
                  "change": function change($event) {
                    var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                      return o.selected;
                    }).map(function (o) {
                      var val = "_value" in o ? o._value : o.value;
                      return val;
                    });

                    _vm.$set(_vm.postData, 'marital_status', $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                  }
                }
              }, _vm._l(_vm.formFields['marital_status']['options'], function (option, index1) {
                return _c('option', {
                  key: index1,
                  attrs: {
                    "id": option.index1
                  },
                  domProps: {
                    "value": option.value
                  }
                }, [_vm._v(" " + _vm._s(option.name) + " ")]);
              }), 0), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.ssn ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['ssn']),
            "custom-messages": _vm.getErrorMessages('ssn')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('label', {
                attrs: {
                  "for": "ssn"
                }
              }, [_vm._v(" SSN "), _vm.formFields['ssn']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['ssn'],
                  expression: "postData['ssn']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "ssn",
                  "maxlength": "9"
                },
                domProps: {
                  "value": _vm.postData['ssn']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'ssn', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.height_feet ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_vm.formFields['height_feet'] != null ? _c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['height_feet']),
            "custom-messages": _vm.getErrorMessages('height_feet')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('label', {
                attrs: {
                  "for": "height_feet"
                }
              }, [_vm._v(" Height(feet) "), _vm.formFields['height_feet']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['height_feet'],
                  expression: "postData['height_feet']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "height_feet"
                },
                domProps: {
                  "value": _vm.postData['height_feet']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'height_feet', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        }) : _vm._e()], 1) : _vm._e(), _vm.formFields.height_inch ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['height_inch']),
            "custom-messages": _vm.getErrorMessages('height_inch')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var errors = _ref9.errors;
              return [_c('label', {
                attrs: {
                  "for": "height_inch"
                }
              }, [_vm._v(" Height(inch) "), _vm.formFields['height_inch']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['height_inch'],
                  expression: "postData['height_inch']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "height_inch"
                },
                domProps: {
                  "value": _vm.postData['height_inch']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'height_inch', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.weight ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_vm.formFields['weight'] != null ? _c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['weight']),
            "custom-messages": _vm.getErrorMessages('weight')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('label', {
                attrs: {
                  "for": "weight"
                }
              }, [_vm._v(" Weight(lbs) "), _vm.formFields['weight']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['weight'],
                  expression: "postData['weight']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "weight"
                },
                domProps: {
                  "value": _vm.postData['weight']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'weight', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        }) : _vm._e()], 1) : _vm._e(), _vm.formFields.prior_coverage_flag ? _c('div', {
          staticClass: "col-xxl-12 col-lg-12 col-md-12 mb-3"
        }, [_c('ValidationProvider', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var errors = _ref11.errors;
              return [_vm._v(" Within the last 12 months I have had "), _c('div', [_c('div', {
                staticClass: "form-check form-check-inline"
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['prior_coverage_flag'],
                  expression: "postData['prior_coverage_flag']"
                }],
                staticClass: "form-check-input",
                attrs: {
                  "type": "radio",
                  "name": "inlineRadioOptions",
                  "id": "inlineRadio1"
                },
                domProps: {
                  "value": false,
                  "checked": _vm._q(_vm.postData['prior_coverage_flag'], false)
                },
                on: {
                  "change": function change($event) {
                    return _vm.$set(_vm.postData, 'prior_coverage_flag', false);
                  }
                }
              }), _c('label', {
                staticClass: "form-check-label",
                attrs: {
                  "for": "inlineRadio1"
                }
              }, [_vm._v("No Prior Health Coverage")])]), _c('div', {
                staticClass: "form-check form-check-inline"
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['prior_coverage_flag'],
                  expression: "postData['prior_coverage_flag']"
                }],
                staticClass: "form-check-input",
                attrs: {
                  "type": "radio",
                  "name": "inlineRadioOptions",
                  "id": "inlineRadio2"
                },
                domProps: {
                  "value": true,
                  "checked": _vm._q(_vm.postData['prior_coverage_flag'], true)
                },
                on: {
                  "change": function change($event) {
                    return _vm.$set(_vm.postData, 'prior_coverage_flag', true);
                  }
                }
              }), _c('label', {
                staticClass: "form-check-label",
                attrs: {
                  "for": "inlineRadio2"
                }
              }, [_vm._v("Have has insurance carrier")])])]), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.postData['prior_coverage_flag'] ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "custom-messages": {
              required: 'Prior carrier name is required'
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var errors = _ref12.errors;
              return [_c('label', {
                attrs: {
                  "for": "prior_carrier_name"
                }
              }, [_vm._v(" Pre Health Carrier "), _c('span', {
                staticClass: "required"
              }, [_vm._v("*")])]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['prior_carrier_name'],
                  expression: "postData['prior_carrier_name']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "prior_carrier_name"
                },
                domProps: {
                  "value": _vm.postData['prior_carrier_name']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'prior_carrier_name', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.postData['prior_coverage_flag'] ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "custom-messages": {
              required: 'Pre effective date is required'
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref13) {
              var errors = _ref13.errors;
              return [_c('label', {
                attrs: {
                  "for": "prior_effective_date"
                }
              }, [_vm._v(" Pre Effective Date "), _c('span', {
                staticClass: "required"
              }, [_vm._v("*")])]), _c('date-picker', {
                attrs: {
                  "input-class": "info-field",
                  "placeholder": "MM/DD/YYYY",
                  "type": "date",
                  "id": "prior_effective_date",
                  "value-type": "format",
                  "format": "MM/DD/YYYY"
                },
                model: {
                  value: _vm.postData['prior_effective_date'],
                  callback: function callback($$v) {
                    _vm.$set(_vm.postData, 'prior_effective_date', $$v);
                  },
                  expression: "postData['prior_effective_date']"
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.postData['prior_coverage_flag'] ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          ref: "termDate",
          attrs: {
            "rules": "required",
            "custom-messages": {
              required: 'Pre term date is required'
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref14) {
              var errors = _ref14.errors;
              return [_c('label', {
                attrs: {
                  "for": "prior_term_date"
                }
              }, [_vm._v(" Pre Term Date "), _c('span', {
                staticClass: "required"
              }, [_vm._v("*")])]), _c('date-picker', {
                attrs: {
                  "input-class": "info-field",
                  "placeholder": "MM/DD/YYYY",
                  "type": "date",
                  "id": "prior_term_date",
                  "value-type": "format",
                  "format": "MM/DD/YYYY"
                },
                model: {
                  value: _vm.postData['prior_term_date'],
                  callback: function callback($$v) {
                    _vm.$set(_vm.postData, 'prior_term_date', $$v);
                  },
                  expression: "postData['prior_term_date']"
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e()]), _c('div', {
          staticClass: "row justify-content-end mt-3"
        }, [_c('div', {
          staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6"
        }, [_c('button', {
          staticClass: "univ-btn",
          staticStyle: {
            "max-width": "100% !important"
          },
          attrs: {
            "type": "submit"
          }
        }, [_vm._v(_vm._s(_vm.isProcessing ? 'Processing' : 'Update'))])])])])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }