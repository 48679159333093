<template>
    <div class="info-wrap">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(updateContactInfo)">
                <h2>Contact Information</h2>
                <p>Enter and Update all your Contact Information here</p>
                <div class="info-form mb-4">            
                    <div class="form-group row">  
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="contactFormFields.email_address">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(contactFormFields['email_address'])" :custom-messages="getErrorMessages('email_address')">
                                <label for="email_address">
                                    Email (Must be client's email)
                                    <span v-if="contactFormFields['email_address']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="contactPostData['email_address']"  id="first_name">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="contactFormFields.phone_number">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(contactFormFields['phone_number'])" :custom-messages="getErrorMessages('phone_number')">
                                <label for="phone_number">
                                    Contact Number
                                    <span v-if="contactFormFields['phone_number']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="contactPostData['phone_number']"  id="phone_number">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="contactFormFields.address1">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(contactFormFields['address1'])" :custom-messages="getErrorMessages('address1')">
                                <label for="address1">
                                    Street Address 1
                                    <span v-if="contactFormFields['address1']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="contactPostData['address1']"  id="address1">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="contactFormFields.address2">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(contactFormFields['address2'])" :custom-messages="getErrorMessages('address2')">
                                <label for="address2">
                                    Street Address 2
                                    <span v-if="contactFormFields['address2']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="contactPostData['address2']"  id="address2">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="contactFormFields.city">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(contactFormFields['city'])" :custom-messages="getErrorMessages('city')">
                                <label for="city">
                                    City-Country
                                    <span v-if="contactFormFields['city']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="contactPostData['city']"  id="city">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="contactFormFields.state">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(contactFormFields['state'])" :custom-messages="getErrorMessages('state')">
                                <label for="state">
                                    State
                                    <span v-if="contactFormFields['state']['required']" class="required">*</span>
                                </label>
                                <select class="info-field" id="state" v-model="contactPostData['state']" disabled>
                                    <option v-for="(option, index1) in contactFormFields['state']['options']" :key="index1" :id="option.index1" :value="option.value">
                                        {{ option.name }}
                                    </option>
                                </select>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="contactFormFields.zip">
                            <ValidationProvider v-slot="{ errors }" :rules="getRules(contactFormFields['zip'])" :custom-messages="getErrorMessages('zip')">
                                <label for="zip">
                                    Zip/Pin Code
                                    <span v-if="contactFormFields['zip']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="contactPostData['zip']"  id="zip" disabled>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="form-check col-xxl-6 col-lg-6 col-md-6 mb-3" v-if="contactFormFields.with_validation">
                            <input class="form-check-input" type="checkbox" v-model="contactPostData['with_validation']"  id="with_validation">
                            <label class="form-check-label" for="flexCheckDefault">
                                USPS Address Validation
                            </label>
                        </div>
                    </div>
                    <div class="row justify-content-end mt-3">
                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                            <button type="submit" class="univ-btn" style=" max-width: 100% !important;">{{ isProcessing ? 'Processing' : 'Update' }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
import Api from '../../resource/Api';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
/*eslint-disable*/

export default {
    name: 'ContactDetails',
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    data: () => ({
        version: process.env.VUE_APP_API_VERSION,
        isProcessing: false,
        contactPostData: [],
        contactFormFields: []
    }),
    mounted(){
        this.getContactInfo();
    },
    methods: {
        getLabelName(fieldName){
            if(fieldName === 'weight')
                return fieldName.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) + ' (lbs)'; 
            return fieldName.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        },
        getRules(item){
            if(item == null){
                return {};
            }
            if(item.field_name === 'email_address'){
                return {
                    required: item.required,
                    email: true
                }
            }
            if(item.field_name === 'phone_number'){
                return {
                    required: item.required,
                    digits: 10
                }
            }
            if(item.field_name === 'zip'){
                return {
                    required: item.required,
                    digits: 5
                }
            }
            return {required: item.required}
        },
        getErrorMessages(field_name){
            if(field_name === 'zip'){
                return {
                    'required': this.getLabelName(field_name) + " is required",
                    'digits': this.getLabelName(field_name) + " must be 5 digits"    
                }
            }
            if(field_name === 'phone_number'){
                return {
                    'required': "Contact number is required",
                    'alpha': "Contact number may only contain alphabetic characters",
                    'email': "Contact number should be a valid email",
                    'digits': "Contact number must be 10 digits"
                }    
            }
            return {
                'required': this.getLabelName(field_name) + " is required",
                'alpha': this.getLabelName(field_name) + " may only contain alphabetic characters",
                'email': this.getLabelName(field_name) + " should be a valid email",
                'digits': this.getLabelName(field_name) + " must be 10 digits"
            }
        },

        getContactInfo(){
            let url = "v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/contact-info";
            Api.get(url)
                .then((res) => {
                    let contactInfo = res.data.data;
                    contactInfo.map(info => {
                        this.contactPostData = {...this.contactPostData, [info.field_name]: info.value}
                        this.contactFormFields = {...this.contactFormFields, [info.field_name]: info}
                    })
                    this.contactPostData['with_validation'] = 1
                })
                .catch((err) => {
                    Api.notification(err.response.data.success, err.response.data.message)
                })
        },
        updateContactInfo(){
            this.isProcessing = true;
            this.contactPostData.with_validation = this.contactPostData.with_validation ? 1 : 0
            Api.patch(`${this.$endpoint.REP_24HRS_EDIT}/${atob(this.$route.params.enrollmentId)}/contact-info`, this.contactPostData)
                .then((response) => {
                    Api.notification(response.data.status, response.data.message);
                })
                .catch((err) => {
                    Api.notification(err.response.data.status, err.response.data.message);
                    Api.notification(err.response.data.success, err.response.data.message);
                })
                .finally(()=>{
                    this.isProcessing = false;
                })
        }

    },
}
</script>


<style scoped>
input[disabled], select[disabled] {
     background-color:#dce1e7;
}
</style>