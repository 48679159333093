var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrap"
  }, [_c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.updateEmployerInfo);
            }
          }
        }, [_c('h2', [_vm._v("Employer Information")]), _c('p', [_vm._v("Enter and Update all your Employer Information here")]), _c('div', {
          staticClass: "info-form mb-4"
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_vm.formFields.name ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['name']),
            "custom-messages": _vm.getErrorMessages('name')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('label', {
                attrs: {
                  "for": "name"
                }
              }, [_vm._v(" Employer Information "), _vm.formFields['name']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['name'],
                  expression: "postData['name']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "name"
                },
                domProps: {
                  "value": _vm.postData['name']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'name', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.phone ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['phone']),
            "custom-messages": _vm.getErrorMessages('phone')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('label', {
                attrs: {
                  "for": "phone"
                }
              }, [_vm._v(" Contact Number "), _vm.formFields['phone']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['phone'],
                  expression: "postData['phone']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "phone"
                },
                domProps: {
                  "value": _vm.postData['phone']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'phone', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e()]), _c('div', {
          staticClass: "form-group row"
        }, [_vm.formFields.address1 ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['address1']),
            "custom-messages": _vm.getErrorMessages('address1')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('label', {
                attrs: {
                  "for": "address1"
                }
              }, [_vm._v(" Street Address 1 "), _vm.formFields['address1']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['address1'],
                  expression: "postData['address1']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "address1"
                },
                domProps: {
                  "value": _vm.postData['address1']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'address1', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.address2 ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['address2']),
            "custom-messages": _vm.getErrorMessages('address2')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('label', {
                attrs: {
                  "for": "address2"
                }
              }, [_vm._v(" Street Address 2 "), _vm.formFields['address2']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['address2'],
                  expression: "postData['address2']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "address2"
                },
                domProps: {
                  "value": _vm.postData['address2']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'address2', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.city ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['city']),
            "custom-messages": _vm.getErrorMessages('city')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('label', {
                attrs: {
                  "for": "city"
                }
              }, [_vm._v(" City-Country "), _vm.formFields['city']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['city'],
                  expression: "postData['city']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "city"
                },
                domProps: {
                  "value": _vm.postData['city']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'city', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.state ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['state']),
            "custom-messages": _vm.getErrorMessages('state')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('label', {
                attrs: {
                  "for": "state"
                }
              }, [_vm._v(" State "), _vm.formFields['state']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['state'],
                  expression: "postData['state']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "state"
                },
                on: {
                  "change": function change($event) {
                    var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                      return o.selected;
                    }).map(function (o) {
                      var val = "_value" in o ? o._value : o.value;
                      return val;
                    });

                    _vm.$set(_vm.postData, 'state', $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                  }
                }
              }, _vm._l(_vm.formFields['state']['options'], function (option, index1) {
                return _c('option', {
                  key: index1,
                  attrs: {
                    "id": option.index1
                  },
                  domProps: {
                    "value": option.value
                  }
                }, [_vm._v(" " + _vm._s(option.name) + " ")]);
              }), 0), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.zip ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['zip']),
            "custom-messages": _vm.getErrorMessages('zip')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('label', {
                attrs: {
                  "for": "zip"
                }
              }, [_vm._v(" Zip/Pin Code "), _vm.formFields['zip']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['zip'],
                  expression: "postData['zip']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "zip"
                },
                domProps: {
                  "value": _vm.postData['zip']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'zip', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e()]), _c('div', {
          staticClass: "form-group row"
        }, [_vm.formFields.with_validation ? _c('div', {
          staticClass: "form-check col-xxl-6 col-lg-6 col-md-6 mb-3"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.postData['with_validation'],
            expression: "postData['with_validation']"
          }],
          staticClass: "form-check-input",
          attrs: {
            "type": "checkbox",
            "id": "with_validation"
          },
          domProps: {
            "checked": Array.isArray(_vm.postData['with_validation']) ? _vm._i(_vm.postData['with_validation'], null) > -1 : _vm.postData['with_validation']
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.postData['with_validation'],
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;

              if (Array.isArray($$a)) {
                var $$v = null,
                    $$i = _vm._i($$a, $$v);

                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.postData, 'with_validation', $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.postData, 'with_validation', $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.postData, 'with_validation', $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "form-check-label",
          attrs: {
            "for": "flexCheckDefault"
          }
        }, [_vm._v(" USPS Address Validation ")])]) : _vm._e()]), _c('div', {
          staticClass: "form-group row"
        }, [_vm.formFields.average_work_week ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['average_work_week']),
            "custom-messages": _vm.getErrorMessages('average_work_week')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var errors = _ref9.errors;
              return [_c('label', {
                attrs: {
                  "for": "average_work_week"
                }
              }, [_vm._v(" Average Work Week "), _vm.formFields['average_work_week']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['average_work_week'],
                  expression: "postData['average_work_week']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "average_work_week"
                },
                on: {
                  "change": function change($event) {
                    var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                      return o.selected;
                    }).map(function (o) {
                      var val = "_value" in o ? o._value : o.value;
                      return val;
                    });

                    _vm.$set(_vm.postData, 'average_work_week', $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                  }
                }
              }, _vm._l(_vm.formFields['average_work_week']['options'], function (option, index1) {
                return _c('option', {
                  key: index1,
                  attrs: {
                    "id": option.index1
                  },
                  domProps: {
                    "value": option.value
                  }
                }, [_vm._v(" " + _vm._s(option.name) + " ")]);
              }), 0), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.start_date ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['start_date']),
            "custom-messages": _vm.getErrorMessages('start_date')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('label', {
                attrs: {
                  "for": "start_date"
                }
              }, [_vm._v(" Employement Start Date "), _vm.formFields['start_date']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('date-picker', {
                attrs: {
                  "input-class": "info-field",
                  "placeholder": "",
                  "type": "date",
                  "id": "start_date",
                  "value-type": "format",
                  "format": "MM/DD/YYYY"
                },
                model: {
                  value: _vm.postData['start_date'],
                  callback: function callback($$v) {
                    _vm.$set(_vm.postData, 'start_date', $$v);
                  },
                  expression: "postData['start_date']"
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.occupation ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['occupation']),
            "custom-messages": _vm.getErrorMessages('occupation')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var errors = _ref11.errors;
              return [_c('label', {
                attrs: {
                  "for": "occupation"
                }
              }, [_vm._v(" Occupation "), _vm.formFields['occupation']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['occupation'],
                  expression: "postData['occupation']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "occupation"
                },
                domProps: {
                  "value": _vm.postData['occupation']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'occupation', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.compensation_type ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['compensation_type']),
            "custom-messages": _vm.getErrorMessages('compensation_type')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var errors = _ref12.errors;
              return [_c('label', {
                attrs: {
                  "for": "compensation_type"
                }
              }, [_vm._v(" Compensation type "), _vm.formFields['compensation_type']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['compensation_type'],
                  expression: "postData['compensation_type']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "compensation_type"
                },
                on: {
                  "change": function change($event) {
                    var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                      return o.selected;
                    }).map(function (o) {
                      var val = "_value" in o ? o._value : o.value;
                      return val;
                    });

                    _vm.$set(_vm.postData, 'compensation_type', $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                  }
                }
              }, _vm._l(_vm.formFields['compensation_type']['options'], function (option, index1) {
                return _c('option', {
                  key: index1,
                  attrs: {
                    "id": option.index1
                  },
                  domProps: {
                    "value": option.value
                  }
                }, [_vm._v(" " + _vm._s(option.name) + " ")]);
              }), 0), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.status ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['status']),
            "custom-messages": _vm.getErrorMessages('status')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref13) {
              var errors = _ref13.errors;
              return [_c('label', {
                attrs: {
                  "for": "status"
                }
              }, [_vm._v(" Employement Status "), _vm.formFields['status']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('select', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['status'],
                  expression: "postData['status']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "status"
                },
                on: {
                  "change": function change($event) {
                    var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                      return o.selected;
                    }).map(function (o) {
                      var val = "_value" in o ? o._value : o.value;
                      return val;
                    });

                    _vm.$set(_vm.postData, 'status', $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
                  }
                }
              }, _vm._l(_vm.formFields['status']['options'], function (option, index1) {
                return _c('option', {
                  key: index1,
                  attrs: {
                    "id": option.index1,
                    "disabled": _vm.getDisabled(option.value)
                  },
                  domProps: {
                    "value": option.value
                  }
                }, [_vm._v(" " + _vm._s(option.name) + " ")]);
              }), 0), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e()]), _c('div', {
          staticClass: "form-group row"
        }, [_vm.formFields.emp_industry ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['emp_industry']),
            "custom-messages": _vm.getErrorMessages('emp_industry')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref14) {
              var errors = _ref14.errors;
              return [_c('label', {
                attrs: {
                  "for": "emp_industry"
                }
              }, [_vm._v(" Employement industry "), _vm.formFields['emp_industry']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['emp_industry'],
                  expression: "postData['emp_industry']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "emp_industry"
                },
                domProps: {
                  "value": _vm.postData['emp_industry']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'emp_industry', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.emp_num_employed ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['emp_num_employed']),
            "custom-messages": _vm.getErrorMessages('emp_num_employed')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref15) {
              var errors = _ref15.errors;
              return [_c('label', {
                attrs: {
                  "for": "emp_num_employed"
                }
              }, [_vm._v(" Employement Num Employed "), _vm.formFields['emp_num_employed']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['emp_num_employed'],
                  expression: "postData['emp_num_employed']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "emp_num_employed"
                },
                domProps: {
                  "value": _vm.postData['emp_num_employed']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'emp_num_employed', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.emp_vac_policy ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['emp_vac_policy']),
            "custom-messages": _vm.getErrorMessages('emp_vac_policy')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref16) {
              var errors = _ref16.errors;
              return [_c('label', {
                attrs: {
                  "for": "emp_vac_policy"
                }
              }, [_vm._v(" Employement Vac Policy "), _vm.formFields['emp_vac_policy']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['emp_vac_policy'],
                  expression: "postData['emp_vac_policy']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "emp_vac_policy"
                },
                domProps: {
                  "value": _vm.postData['emp_vac_policy']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'emp_vac_policy', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.formFields.emp_barg_unit ? _c('div', {
          staticClass: "col-xxl-3 col-lg-3 col-md-6 mb-3"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": _vm.getRules(_vm.formFields['emp_barg_unit']),
            "custom-messages": _vm.getErrorMessages('emp_barg_unit')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref17) {
              var errors = _ref17.errors;
              return [_c('label', {
                attrs: {
                  "for": "emp_barg_unit"
                }
              }, [_vm._v(" Compensation type "), _vm.formFields['emp_barg_unit']['required'] ? _c('span', {
                staticClass: "required"
              }, [_vm._v("*")]) : _vm._e()]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.postData['emp_barg_unit'],
                  expression: "postData['emp_barg_unit']"
                }],
                staticClass: "info-field",
                attrs: {
                  "id": "emp_barg_unit"
                },
                domProps: {
                  "value": _vm.postData['emp_barg_unit']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(_vm.postData, 'emp_barg_unit', $event.target.value);
                  }
                }
              }), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1) : _vm._e()]), _c('div', {
          staticClass: "row justify-content-end mt-3"
        }, [_c('div', {
          staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6"
        }, [_c('button', {
          staticClass: "univ-btn",
          staticStyle: {
            "max-width": "100% !important"
          },
          attrs: {
            "type": "submit"
          }
        }, [_vm._v(_vm._s(_vm.isProcessing ? 'Processing' : 'Update'))])])])])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }