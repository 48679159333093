<template>
    <div class="info-wrap">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(updateBillingInfo)">
                <h2>Billing Information</h2>
                <p>Enter and Update all your Billing Information here</p>
                <div class="info-form mb-4">   
                    <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.payment_type">
                        <ValidationProvider 
                        v-slot="{ errors }" 
                        :rules="getRules(formFields['payment_type']).rules" 
                        :custom-messages="getRules(formFields['payment_type']).messages">
                            <label for="payment_type">
                                Payment Type
                                <span v-if="formFields['payment_type']['required']" class="required">*</span>
                            </label>
                            <select class="info-field" id="payment_type" v-model="postData['payment_type']" disabled>
                                <option
                                 v-for="(option, index1) in formFields['payment_type']['options']" 
                                 :key="index1" 
                                 :id="option.index1" 
                                 :value="option.value"
                                 :disabled="postData.payment_type != option.value">
                                    {{ option.name }}
                                </option>
                            </select>
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="info-form mb-4" v-if="postData.payment_type === 'eft'">            
                    <div class="form-group row">
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.account_name">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['account_name']).rules" 
                            :custom-messages="getRules(formFields['account_name']).messages"
                            >
                                <label for="account_name">
                                    Account Name
                                    <span v-if="formFields['account_name']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['account_name']"  id="account_name">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.account_type">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['account_type']).rules" 
                            :custom-messages="getRules(formFields['account_type']).messages">
                                <label for="account_type">
                                    Account Type
                                    <span v-if="formFields['account_type']['required']" class="required">*</span>
                                </label>
                                <select class="info-field" id="account_type" v-model="postData['account_type']">
                                    <option v-for="(option, index1) in formFields['account_type']['options']" :key="index1" :id="option.index1" :value="option.value">
                                        {{ option.name }}
                                    </option>
                                </select>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.account_name">
                            <ValidationProvider
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['account_number']).rules" 
                            :custom-messages="getRules(formFields['account_number']).messages">
                                <label for="account_number">
                                    Account Number
                                    <span v-if="formFields['account_number']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['account_number']"  id="account_number">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.routing_number">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            ref="routing_number"
                            :rules="getRules(formFields['routing_number']).rules" 
                            :custom-messages="getRules(formFields['routing_number']).messages">
                                <label for="routing_number">
                                    Routing Number
                                    <span v-if="formFields['routing_number']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['routing_number']"  id="routing_number" v-on:blur="validRouting()">
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.bank_name">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['bank_name']).rules" 
                            :custom-messages="getRules(formFields['bank_name']).messages">
                                <label for="bank_name">
                                    Bank Name
                                    <span v-if="formFields['bank_name']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['bank_name']"  id="bank_name" readonly disabled>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.account_type">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['account_holder_type']).rules" 
                            :custom-messages="getRules(formFields['account_holder_type']).messages"
                            >
                                <label for="account_type">
                                    Account Holder Type
                                    <span v-if="formFields['account_holder_type']['required']" class="required">*</span>
                                </label>
                                <select class="info-field" id="account_holder_type" v-model="postData['account_holder_type']">
                                    <option v-for="(option, index1) in formFields['account_holder_type']['options']" :key="index1" :id="option.index1" :value="option.value">
                                        {{ option.name }}
                                    </option>
                                </select>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="info-form mb-4" v-if="postData.payment_type === 'cc'">
                    <div class="form-group row">
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.first_name">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['first_name']).rules" 
                            :custom-messages="getRules(formFields['first_name']).messages">
                                <label for="first_name">
                                    First Name
                                    <span v-if="formFields['first_name']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['first_name']"  id="first_name" readonly disabled>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.last_name">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['last_name']).rules" 
                            :custom-messages="getRules(formFields['last_name']).messages">
                                <label for="last_name">
                                    Last Name
                                    <span v-if="formFields['last_name']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['last_name']"  id="last_name" readonly disabled>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.card_number">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['card_number']).rules" 
                            :custom-messages="getRules(formFields['card_number']).messages">
                                <label for="card_number">
                                    Card Number
                                    <span v-if="formFields['card_number']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['card_number']"  id="card_number" readonly disabled>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.expiration_month">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['expiration_month']).rules" 
                            :custom-messages="getRules(formFields['expiration_month']).messages">
                                <label for="expiration_month">
                                    Expiration Month
                                    <span v-if="formFields['expiration_month']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['expiration_month']"  id="expiration_month" readonly disabled>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.expiration_year">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['expiration_year']).rules" 
                            :custom-messages="getRules(formFields['expiration_year']).messages">
                                <label for="expiration_year">
                                    Expiration Year
                                    <span v-if="formFields['expiration_year']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['expiration_year']"  id="expiration_year" readonly disabled>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.security_code">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['security_code']).rules" 
                            :custom-messages="getRules(formFields['security_code']).messages">
                                <label for="security_code">
                                    Security Code
                                    <span v-if="formFields['security_code']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['security_code']"  id="security_code" readonly disabled>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.address1">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['address1']).rules" 
                            :custom-messages="getRules(formFields['address1']).messages">
                                <label for="address1">
                                    Street Address 1
                                    <span v-if="formFields['address1']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['address1']"  id="address1" readonly disabled>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.address2">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['address2']).rules" 
                            :custom-messages="getRules(formFields['address2']).messages">
                                <label for="address2">
                                    Street Address 2
                                    <span v-if="formFields['address2']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['address2']"  id="address2" readonly disabled>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.city">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['city']).rules" 
                            :custom-messages="getRules(formFields['city']).messages">
                                <label for="city">
                                    City-Country
                                    <span v-if="formFields['city']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['city']"  id="city" readonly disabled>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.state">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['state']).rules" 
                            :custom-messages="getRules(formFields['state']).messages">
                                <label for="state">
                                    State
                                    <span v-if="formFields['state']['required']" class="required">*</span>
                                </label>
                                <select class="info-field" id="state" v-model="postData['state']" disabled>
                                    <option v-for="(option, index1) in formFields['state']['options']" :key="index1" :id="option.index1" :value="option.value">
                                        {{ option.name }}
                                    </option>
                                </select>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3" v-if="formFields.zip">
                            <ValidationProvider 
                            v-slot="{ errors }" 
                            :rules="getRules(formFields['zip']).rules" 
                            :custom-messages="getRules(formFields['zip']).messages">
                                <label for="zip">
                                    Zip/Pin Code
                                    <span v-if="formFields['zip']['required']" class="required">*</span>
                                </label>
                               <input class="info-field" v-model="postData['zip']"  id="zip" readonly disabled>
                               <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-end mt-3">
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                        <button type="submit" class="univ-btn" style=" max-width: 100% !important;" v-if="postData.payment_type === 'eft'">{{ isProcessing ? 'Processing' : 'Update' }}</button>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
import Api from '../../resource/Api';
import axios from 'axios';
/*eslint-disable*/

export default {
    name: 'BillingInfo',
    data: () => ({
        version: process.env.VUE_APP_API_VERSION,
        isProcessing: false,
        postData: [],
        formFields: []
    }),
    mounted(){
        this.getBillingInfo();
    },
    methods: {
        getBillingInfo(){
            let url = "v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/billing-info";
            Api.get(url)
                .then((res) => {
                    let contactInfo = res.data.data;
                    contactInfo.map(info => {
                        this.postData = {...this.postData, [info.field_name]: info.value}
                        this.formFields = {...this.formFields, [info.field_name]: info}
                    })
                })
                .catch((err) => {
                    Api.notification(err.response.data.success, err.response.data.message)
                })
        },
        updateBillingInfo(){
            this.isProcessing = true;
            let url = "v2/rep-24hrs-edit/" + atob(this.$route.params.enrollmentId) + "/billing-info";
            Api.patch(url, this.postData)
            .then((response) => {
                Api.notification(response.data.status, response.data.message);
            })
            .catch((err) => {
                Api.notification(err.response.data.status, err.response.data.message);
                Api.notification(err.response.data.success, err.response.data.message);
            })
            .finally(()=>{
                this.isProcessing = false;
            })
        },
        validRouting(){
            let path = "v1/validate-routing-number"
            let params = {'routing_number': this.postData.routing_number};
            axios.post(path, params, {
                    headers: {
                        Authorization: 'Basic ' + process.env.VUE_APP_QA_BS_API_AUTH_KEY
                    }
                })
                .then(response => {
                     if (response.data.status == 'success') {
                        this.postData.bank_name = response.data.data.result.bank_name;
                    }
                })
                .catch(error => {
                    this.postData.bank_name = '';
                    this.$refs.routing_number.applyResult({
                        errors: [error.response.data.data[0].error_message],
                        valid: false,
                        failedRules: {}
                    });
                    Api.notification(error.response.status, error.response.data.data[0].error_message)
                })
                
        },
        getLabelName(fieldName){
            return fieldName.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        },
        getRules(item){
            if(item == null){
                return {};
            }
            if(item.field_name === 'routing_number'){
                return {
                    'rules': {
                        required: item.required,
                        digits: 9
                    },
                    'messages': {
                        'required': this.getLabelName(item.field_name) + " is required",
                        'digits': this.getLabelName(item.field_name) + " must be 9 digits"
                    }
                }
            }
            if(item.field_name === 'account_number' || item.field_name === 'card_number' || item.field_name === 'security_code'){
                return {
                    'rules': {
                        required: item.required,
                        numeric: true,
                        min:4,
                        max:17
                    },
                    'messages': {
                        'required': this.getLabelName(item.field_name) + " is required",
                        'numeric': this.getLabelName(item.field_name) + " must be numberic value"
                    }
                }
            }
            if(item.field_name === 'account_name' || item.field_name === 'first_name' || item.field_name === 'last_name'){
                return {
                    'rules': {
                        required: item.required,
                        alpha_spaces: true
                    },
                    'messages': {
                        'required': this.getLabelName(item.field_name) + " is required",
                        'alpha_spaces': this.getLabelName(item.field_name) + " may only contain alphabetic characters",
                    }
                }
            }
            if(item.field_name === 'expiration_month'){
                return {
                    'rules': {
                        required: item.required,
                        min_value: 1,
                        max_value: 12,
                        numeric: true
                    },
                    'messages': {
                        'required': this.getLabelName(item.field_name) + " is required",
                        'min_value': this.getLabelName(item.field_name) + " should be 1 to 12",
                        'max_value': this.getLabelName(item.field_name) + " should be 1 to 12",
                        'numeric': this.getLabelName(item.field_name) + " must be numberic value"
                    }
                }
            }
            if(item.field_name === 'expiration_year'){
                return {
                    'rules': {
                        required: item.required,
                        numeric: true
                    },
                    'messages': {
                        'required': this.getLabelName(item.field_name) + " is required",
                        'numeric': this.getLabelName(item.field_name) + " must be numberic value"
                    }
                }
            }
            if(item.field_name === 'zip'){
                return {
                    'rules': {
                        required: item.required,
                        digits: 5
                    },
                    'messages': {
                        'required': this.getLabelName(item.field_name) + " is required",
                        'digits': this.getLabelName(item.field_name) + " must be 5 digits"
                    }
                }
            }
            return {
                'rules': {
                    required: item.required
                },
                'messages': {
                    'required': this.getLabelName(item.field_name) + " is required",
                }
            }
        },
    },
}
</script>

<style scoped>
input[disabled], select[disabled] {
     background-color:#dce1e7;
}
</style>